import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';

function CreateLinkPopup({ open, handleClose, onCreateLink }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 4,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                    position: 'relative',
                }}
            >
                <Typography fontWeight="bold" fontSize={18}>
                    Create a link
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                <Typography>
                    Your file will then be available to all project
                    participants.
                </Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'center',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        textTransform: 'none',
                        paddingX: 3,
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#FFFFFF'
                                : '#000000',
                        borderColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#FFFFFF'
                                : '#000000',
                        '&:hover': {
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#FFFFFF'
                                    : '#000000',
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#333333'
                                    : '#f5f5f5',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onCreateLink}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        paddingX: 3,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#FFFFFF'
                                : '#000000',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#000000'
                                : '#FFFFFF',
                        '&:hover': {
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#CCCCCC'
                                    : '#333333',
                        },
                    }}
                >
                    Create a link
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateLinkPopup;
