import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { formatAccountTitleRow, formatCurrencyAmount } from 'utils/helpers';
import Animation from '../Animation';
import CustomPagination from '../TablePagination';
import NestedDropDown from './NestedDropDown.jsx';

function TableNew({
    allRows,
    expanded,
    accountId,
    handleAccordionChange,
    accountName,
    smDown,
    checkboxVisible,
    handleCheckboxChange,
    checkedRows,
    attach,
    attachedTransactions,
    handleImport,
    selectedAmounts,
    handleAmountChange,
    fetchDropdownOptions,
    fetchDropdownOptionsCoa,
    loadingOptions,
    loadingOptionsCoa,
    handleChangeRowsPerPage,
    handleChangePage,
    dropdownOptions,
    selectedCoa,
    handleCoaChange,
    dropdownOptionsCoa,
    totalPages,
    rowsPerPage,
    currentPage,
    panelData,
    onListItemClick,
    changeDropDownLength,
    isPanelDataLoading,
    openDropDownIndex,
    handleDropDownToggle,
    handleDropDownClose,
}) {
    return (
        <>
            {allRows ? (
                Object?.entries(allRows)?.map(([account, transactions]) => (
                    <Box
                        key={account}
                        border="1px solid #E5E6EB"
                        borderRadius={2}
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            mb: 2,
                        }}
                    >
                        <Accordion
                            expanded={expanded.includes(accountId(account))}
                            onChange={handleAccordionChange(accountId(account))}
                            aria-controls={`${accountId(account)}-content`}
                            id={`${accountId(account)}-header`}
                            sx={{ backgroundColor: 'transparent' }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <IconButton
                                        style={{
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                            color: '#6C727F',
                                            width: '30px',
                                            height: '30px',
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                }
                                sx={{
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#E5E6EB',
                                    borderTopLeftRadius: '6px',
                                    borderTopRightRadius: '6px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#9EA3AE'
                                                : '#4D5461',
                                    }}
                                >
                                    {accountName(account)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {smDown ? (
                                    <Box>
                                        {transactions?.map((row, index) => (
                                            <Box
                                                key={row.id}
                                                sx={{
                                                    p: 2,
                                                    borderRadius: '10px',
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '2px solid #394150'
                                                            : '1px solid #E5E6EB',
                                                }}
                                                mb={2}
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {row.date}
                                                    </Typography>

                                                    {/* {checkboxVisible && (
                                                        <Checkbox
                                                            checked={
                                                                checkedRows[
                                                                    accountId(
                                                                        account,
                                                                    )
                                                                ]?.[row.id] ||
                                                                false
                                                            }
                                                            onChange={handleCheckboxChange(
                                                                accountId(
                                                                    account,
                                                                ),
                                                                row.id,
                                                            )}
                                                        />
                                                    )} */}
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Typography variant="body2">
                                                        {row.merchant_name}
                                                    </Typography>

                                                    <FormControl
                                                        sx={{
                                                            m: 1,
                                                        }}
                                                        variant="standard"
                                                    >
                                                        <Select
                                                            value={
                                                                selectedAmounts[
                                                                    row.id
                                                                ] ||
                                                                row?.company
                                                                    ?.name
                                                            }
                                                            onChange={(
                                                                event,
                                                                option,
                                                            ) =>
                                                                handleAmountChange(
                                                                    event,
                                                                    row.id,
                                                                    option
                                                                        ?.props
                                                                        ?.children,
                                                                )
                                                            }
                                                            onOpen={() =>
                                                                fetchDropdownOptions(
                                                                    row.id,
                                                                )
                                                            }
                                                            sx={{
                                                                '& .MuiSelect-select':
                                                                    {
                                                                        padding: 0,
                                                                        minHeight:
                                                                            'unset',
                                                                        lineHeight:
                                                                            'inherit',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    },
                                                                '& .MuiOutlinedInput-notchedOutline':
                                                                    {
                                                                        border: 'none',
                                                                    },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                    {
                                                                        border: 'none',
                                                                    },
                                                                cursor: 'pointer',
                                                                borderRadius:
                                                                    '12px', // Adjust the border radius here
                                                            }}
                                                            IconComponent={
                                                                ArrowDropDownIcon
                                                            } // Add the custom icon component here
                                                            renderValue={(
                                                                selected,
                                                            ) => (
                                                                <Box component="span">
                                                                    {selected}
                                                                </Box>
                                                            )}
                                                        >
                                                            {loadingOptions[
                                                                row.id
                                                            ] ? (
                                                                <MenuItem
                                                                    disabled
                                                                >
                                                                    <CircularProgress
                                                                        size={
                                                                            24
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            ) : (
                                                                dropdownOptions[
                                                                    row?.id
                                                                ]?.map(
                                                                    (
                                                                        option,
                                                                        index,
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                option.id
                                                                            }
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </MenuItem>
                                                                    ),
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>

                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    gap={3}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        $
                                                        {formatCurrencyAmount(
                                                            row?.amount || 0,
                                                        )}
                                                    </Typography>

                                                    <NestedDropDown
                                                        item={panelData}
                                                        title={formatAccountTitleRow(
                                                            row?.coa_account,
                                                        )}
                                                        plaidId={row?.id}
                                                        companyId={
                                                            row?.company?.id
                                                        }
                                                        onItemClick={
                                                            onListItemClick
                                                        }
                                                        rowIndex={index}
                                                        transactionId={row?.id}
                                                        changeDropDownLength={
                                                            changeDropDownLength
                                                        }
                                                        isDropDownOpen={
                                                            row?.id ===
                                                            openDropDownIndex
                                                        }
                                                        handleDropDownToggle={
                                                            handleDropDownToggle
                                                        }
                                                        isPanelDataLoading={
                                                            isPanelDataLoading
                                                        }
                                                        handleDropDownClose={
                                                            handleDropDownClose
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Button
                                                        sx={{
                                                            border: (theme) =>
                                                                theme.palette
                                                                    .mode ===
                                                                'dark'
                                                                    ? '2px solid #394150'
                                                                    : '1px solid #E5E6EB',
                                                            color: (theme) =>
                                                                theme.palette
                                                                    .mode ===
                                                                'dark'
                                                                    ? '#9EA3AE'
                                                                    : '#6C727F',
                                                            borderRadius: '8px',
                                                            textTransform:
                                                                'none',
                                                            my: 1,
                                                            flex: 1,
                                                            mr: 0.5,
                                                        }}
                                                        onClick={() =>
                                                            attach(
                                                                row.id,
                                                                accountId(
                                                                    account,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        {attachedTransactions?.find(
                                                            (item) =>
                                                                item?.plaid_transaction ===
                                                                row.id,
                                                        )
                                                            ? 'View Attached'
                                                            : 'Attach'}
                                                    </Button>

                                                    <Button
                                                        sx={{
                                                            border: (theme) =>
                                                                theme.palette
                                                                    .mode ===
                                                                'dark'
                                                                    ? '2px solid #394150'
                                                                    : '1px solid #E5E6EB',
                                                            color: '#0061DB',
                                                            borderRadius: '8px',
                                                            textTransform:
                                                                'none',
                                                            my: 1,
                                                            flex: 1,
                                                            ml: 0.5,
                                                        }}
                                                        onClick={() =>
                                                            handleImport(
                                                                row.id,
                                                                row?.coa_account
                                                                    ?.id,
                                                            )
                                                        }
                                                    >
                                                        Import
                                                    </Button>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                ) : (
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: 'center',
                                                            padding: '16px 0',
                                                        }}
                                                    >
                                                        Date
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: 'left',
                                                            padding:
                                                                '16px 16px',
                                                        }}
                                                    >
                                                        Description
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: 'right',
                                                            padding: '16px 0',
                                                        }}
                                                    >
                                                        Amount
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: 'center',
                                                            padding: '16px 0',
                                                        }}
                                                    >
                                                        Company
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: 'center',
                                                            padding: '16px 0',
                                                        }}
                                                    >
                                                        Category
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: 'center',
                                                            padding: '16px 0',
                                                        }}
                                                    >
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {transactions?.map(
                                                    (row, index) => (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{
                                                                borderBottom:
                                                                    'none',
                                                            }}
                                                        >
                                                            {/* <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    width: '20px',
                                                                }}
                                                            > */}
                                                            {/* {checkboxVisible && (
                                                                    <Checkbox
                                                                        checked={
                                                                            checkedRows[
                                                                                accountId(
                                                                                    account,
                                                                                )
                                                                            ]?.[
                                                                                row
                                                                                    .id
                                                                            ] ||
                                                                            false
                                                                        }
                                                                        onChange={handleCheckboxChange(
                                                                            accountId(
                                                                                account,
                                                                            ),
                                                                            row.id,
                                                                        )}
                                                                    />
                                                                )} */}
                                                            {/* </TableCell> */}
                                                            <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    textAlign:
                                                                        'center',
                                                                    padding:
                                                                        '16px 0',
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    noWrap
                                                                >
                                                                    {row.date}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    textAlign:
                                                                        'left',
                                                                    padding:
                                                                        '16px 16px',
                                                                }}
                                                            >
                                                                <Typography variant="body2">
                                                                    {
                                                                        row.merchant_name
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    fontWeight:
                                                                        'bold',
                                                                    textAlign:
                                                                        'right',
                                                                    padding:
                                                                        '16px 0',
                                                                }}
                                                            >
                                                                $
                                                                {formatCurrencyAmount(
                                                                    row?.amount ||
                                                                        0,
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    fontWeight:
                                                                        'bold',
                                                                    textAlign:
                                                                        'center',
                                                                    padding:
                                                                        '16px 0',
                                                                }}
                                                            >
                                                                <FormControl
                                                                    sx={{
                                                                        m: 1,
                                                                    }}
                                                                    variant="standard"
                                                                >
                                                                    <Select
                                                                        value={
                                                                            selectedAmounts[
                                                                                row
                                                                                    .id
                                                                            ] ||
                                                                            row
                                                                                ?.company
                                                                                ?.name
                                                                        }
                                                                        onChange={(
                                                                            event,
                                                                            option,
                                                                        ) =>
                                                                            handleAmountChange(
                                                                                event,
                                                                                row.id,
                                                                                option
                                                                                    ?.props
                                                                                    ?.children,
                                                                            )
                                                                        }
                                                                        onOpen={() =>
                                                                            fetchDropdownOptions(
                                                                                row.id,
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            '& .MuiSelect-select':
                                                                                {
                                                                                    padding: 0,
                                                                                    minHeight:
                                                                                        'unset',
                                                                                    lineHeight:
                                                                                        'inherit',
                                                                                    display:
                                                                                        'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                },
                                                                            '& .MuiOutlinedInput-notchedOutline':
                                                                                {
                                                                                    border: 'none',
                                                                                },
                                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                                {
                                                                                    border: 'none',
                                                                                },
                                                                            cursor: 'pointer',
                                                                            borderRadius:
                                                                                '12px',
                                                                            '&::before':
                                                                                {
                                                                                    borderBottom:
                                                                                        'none',
                                                                                },
                                                                            '&::after':
                                                                                {
                                                                                    borderBottom:
                                                                                        'none',
                                                                                },
                                                                            '& .MuiSelect-root':
                                                                                {
                                                                                    borderBottom:
                                                                                        'none',
                                                                                },
                                                                            '&:hover:not(.Mui-disabled)::before':
                                                                                {
                                                                                    borderBottom:
                                                                                        'none',
                                                                                },
                                                                        }}
                                                                        IconComponent={
                                                                            ArrowDropDownIcon
                                                                        }
                                                                        renderValue={(
                                                                            selected,
                                                                        ) => (
                                                                            <Box component="span">
                                                                                {
                                                                                    selected
                                                                                }
                                                                            </Box>
                                                                        )}
                                                                    >
                                                                        {loadingOptions[
                                                                            row
                                                                                .id
                                                                        ] ? (
                                                                            <MenuItem
                                                                                disabled
                                                                            >
                                                                                <CircularProgress
                                                                                    size={
                                                                                        24
                                                                                    }
                                                                                />
                                                                            </MenuItem>
                                                                        ) : (
                                                                            dropdownOptions[
                                                                                row
                                                                                    ?.id
                                                                            ]?.map(
                                                                                (
                                                                                    option,
                                                                                    index,
                                                                                ) => (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        value={
                                                                                            option.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.name
                                                                                        }
                                                                                    </MenuItem>
                                                                                ),
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    textAlign:
                                                                        'center',
                                                                    padding:
                                                                        '16px 0',
                                                                }}
                                                            >
                                                                <NestedDropDown
                                                                    item={
                                                                        panelData
                                                                    }
                                                                    title={formatAccountTitleRow(
                                                                        row?.coa_account,
                                                                    )}
                                                                    plaidId={
                                                                        row?.id
                                                                    }
                                                                    companyId={
                                                                        row
                                                                            ?.company
                                                                            ?.id
                                                                    }
                                                                    onItemClick={
                                                                        onListItemClick
                                                                    }
                                                                    rowIndex={
                                                                        index
                                                                    }
                                                                    transactionId={
                                                                        row?.id
                                                                    }
                                                                    changeDropDownLength={
                                                                        changeDropDownLength
                                                                    }
                                                                    isDropDownOpen={
                                                                        row?.id ===
                                                                        openDropDownIndex
                                                                    }
                                                                    handleDropDownToggle={
                                                                        handleDropDownToggle
                                                                    }
                                                                    isPanelDataLoading={
                                                                        isPanelDataLoading
                                                                    }
                                                                    handleDropDownClose={
                                                                        handleDropDownClose
                                                                    }
                                                                />
                                                            </TableCell>
                                                            {/* <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <FormControl
                                                                    sx={{
                                                                        m: 1,
                                                                    }}
                                                                    variant="standard"
                                                                >
                                                                    <Select
                                                                        value={
                                                                            selectedCoa[
                                                                                row
                                                                                    .id
                                                                            ] ||
                                                                            row
                                                                                .coa_account
                                                                                ?.id
                                                                        }
                                                                        onChange={(
                                                                            event,
                                                                        ) =>
                                                                            handleCoaChange(
                                                                                event,
                                                                                row.id,
                                                                            )
                                                                        }
                                                                        onOpen={() =>
                                                                            fetchDropdownOptionsCoa(
                                                                                row.id,
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            '& .MuiSelect-select':
                                                                                {
                                                                                    padding: 0,
                                                                                    minHeight:
                                                                                        'unset',
                                                                                    lineHeight:
                                                                                        'inherit',
                                                                                    display:
                                                                                        'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                },
                                                                            '& .MuiOutlinedInput-notchedOutline':
                                                                                {
                                                                                    border: 'none',
                                                                                },
                                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                                {
                                                                                    border: 'none',
                                                                                },
                                                                            cursor: 'pointer',
                                                                            borderRadius:
                                                                                '12px', // Adjust the border radius here
                                                                        }}
                                                                        IconComponent={
                                                                            ArrowDropDownIcon
                                                                        } // Add the custom icon component here
                                                                        renderValue={(
                                                                            selected,
                                                                        ) => (
                                                                            <Box component="span">
                                                                                {
                                                                                    selected
                                                                                }
                                                                            </Box>
                                                                        )}
                                                                    >
                                                                        {loadingOptionsCoa[
                                                                            row
                                                                                .id
                                                                        ] ? (
                                                                            <MenuItem
                                                                                disabled
                                                                            >
                                                                                <CircularProgress
                                                                                    size={
                                                                                        24
                                                                                    }
                                                                                />
                                                                            </MenuItem>
                                                                        ) : (
                                                                            dropdownOptionsCoa[
                                                                                row
                                                                                    ?.id
                                                                            ]?.map(
                                                                                (
                                                                                    option,
                                                                                    index,
                                                                                ) => (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            option.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.id
                                                                                        }
                                                                                    </MenuItem>
                                                                                ),
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell> */}

                                                            <TableCell
                                                                sx={{
                                                                    borderBottom:
                                                                        'none',
                                                                    textAlign:
                                                                        'center',
                                                                    padding:
                                                                        '16px 0',
                                                                }}
                                                            >
                                                                {/* <Button
                                                                    size="small"
                                                                    sx={{
                                                                        border: (
                                                                            theme,
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .mode ===
                                                                            'dark'
                                                                                ? '1.5px solid #394150'
                                                                                : '1px solid #E5E6EB',
                                                                        color: (
                                                                            theme,
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .mode ===
                                                                            'dark'
                                                                                ? '#9EA3AE'
                                                                                : '#6C727F',
                                                                        borderRadius:
                                                                            '8px',
                                                                        marginRight:
                                                                            '10px',
                                                                        textTransform:
                                                                            'none',
                                                                    }}
                                                                    onClick={() =>
                                                                        attach(
                                                                            row.id,
                                                                            accountId(
                                                                                account,
                                                                            ),
                                                                        )
                                                                    }
                                                                >
                                                                    {attachedTransactions?.find(
                                                                        (
                                                                            item,
                                                                        ) =>
                                                                            item?.plaid_transaction ===
                                                                            row.id,
                                                                    )
                                                                        ? 'View Attached'
                                                                        : 'Attach'}
                                                                </Button> */}

                                                                <Button
                                                                    size="small"
                                                                    sx={{
                                                                        backgroundColor:
                                                                            '#0061DB',
                                                                        color: 'white',
                                                                        borderRadius:
                                                                            '8px',
                                                                        paddingX:
                                                                            '10px',
                                                                        textTransform:
                                                                            'none',
                                                                        '&:hover':
                                                                            {
                                                                                backgroundColor:
                                                                                    '#0056b3',
                                                                                '@media (hover: hover)':
                                                                                    {
                                                                                        backgroundColor:
                                                                                            '#0056b3',
                                                                                    },
                                                                            },
                                                                    }}
                                                                    onClick={() =>
                                                                        handleImport(
                                                                            row.id,
                                                                            row
                                                                                ?.coa_account
                                                                                ?.id,
                                                                        )
                                                                    }
                                                                >
                                                                    Import
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ),
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </AccordionDetails>
                        </Accordion>
                        <CustomPagination
                            count={(totalPages[account] || 1) * rowsPerPage}
                            page={(currentPage[account] || 1) - 1}
                            rowsPerPage={rowsPerPage}
                            onPageChange={(event, newPage) =>
                                handleChangePage(account, event, newPage)
                            }
                            rowsPerPageOptions={[10, 15]}
                            onRowsPerPageChange={(event) =>
                                handleChangeRowsPerPage(account, event)
                            }
                        />
                    </Box>
                ))
            ) : (
                <Animation />
            )}
        </>
    );
}

export default TableNew;
