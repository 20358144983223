import {
    Alert,
    Button,
    IconButton,
    Snackbar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import MyIcon from 'assets/icons/import.svg';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    SET_ATTACHED_ROWS,
    SET_CHECKED_ROWS,
    attachTransactionRequest,
    importTransactionsRequest,
} from 'store/actions';
import {
    COA_ACCOUNT_UPDATE,
    COA_FETCH,
    COA_FETCH_WITH_CATEGORY,
    COMPANY_FETCH,
    SYNC_ALL_FETCH,
    UNIMPORTED_FETCH_NEXT,
    UPDATE_COA,
    UPDATE_COMPANY,
} from 'store/services/bank.Service';
import { useCompanyService } from 'store/services/company.service';
import {
    decrementAndFilterPages,
    filterAccounts,
    filterAccountsSyncInitial,
    formatAccountCategoriesData,
    getSelectedTransactionIds,
    processTransactionData,
    processTransactionPages,
    processTransactionTotalPages,
    removeTransactionsById,
    replaceTransactionAccountWithNewAccount,
} from 'utils/helpers';
import ImportDialog from './Components/ImportDialog';
import TableNew from './Components/ImportTransactionSelect/Table';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ImportTransactionsSelect({
    accounts,
    setIsLoading,
    setSyncStatus,
    accounts2,
}) {
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [allRows, setAllRows] = useState({});
    const [currentPage, setCurrentPage] = useState({});
    const [totalPages, setTotalPages] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const smDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [expanded, setExpanded] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [checkboxVisible, setCheckboxVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [panelData, setPanelData] = useState({
        title: 'Select',
        children: [],
    });
    const [isImportAllLoading, setIsImportAllLoading] = useState(false);
    const [originalPanelData, setOriginalPanelData] = useState([]);
    const [isPanelDataLoading, setIsPanelDataLoading] = useState(false);
    const [completePanelData, setCompletePanelData] = useState({
        title: 'Select',
        children: [],
    });
    const [rowId, setrowId] = useState('');
    const checkedRows = useSelector((state) => state.me.checkedRows);
    const { getUserAssociatedCompany } = useCompanyService();
    const [attachedRow, setAttachedRow] = useState({});
    const transactionType = useSelector((state) => state.me.transactionType);
    const syncTransactionsData = useSelector(
        (state) => state.bank.syncTransactionsData,
    );
    const [attachedAccountId, setAttachedAccountId] = useState('');
    const [viewAttachedData, setViewAttachedData] = useState({});
    const { attachedTransactions, unimportedTransactions, syncInitial } =
        useSelector((state) => state.bank);

    const companyData = useSelector((state) => state.company.data);

    const fetchTransactions = useCallback(
        async (account, page) => {
            const { token } = store.getState().auth;
            try {
                if (
                    transactionType === 'IMPORT' ||
                    transactionType === 'INITIAL'
                ) {
                    const response = await fetch(
                        `${SYNC_ALL_FETCH}/${account}/?page=${page}`,
                        {
                            method: 'GET',
                            headers: {
                                accept: 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    const data = await response.json();
                    return {
                        transactions: data?.plaid_transactions,
                        totalPages: data?.total_pages,
                        currentPage: data?.current_page,
                    };
                } else if (transactionType === 'UN_IMPORT') {
                    const response = await fetch(
                        `${UNIMPORTED_FETCH_NEXT}=${account}&company_id=${companyData?.id}&page=${page}`,
                        {
                            method: 'GET',
                            headers: {
                                accept: 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    const data = await response.json();
                    return {
                        transactions: data?.plaid_transactions,
                        totalPages: data?.total_pages,
                        currentPage: data?.current_page,
                    };
                }
            } catch (error) {
                console.error('Error fetching transactions:', error);
                throw error;
            }
        },
        [companyData?.id, transactionType],
    );

    const fetchTransactionsAfterImportAll = useCallback(
        async (accountIds) => {
            const { token } = store.getState().auth;

            try {
                const fetchPromises = accountIds.map(async (accountId) => {
                    let url = '';
                    const accountIdnew = accountId?.split('_')?.[0];
                    if (
                        transactionType === 'IMPORT' ||
                        transactionType === 'INITIAL'
                    ) {
                        url = `${SYNC_ALL_FETCH}/${accountIdnew}/?page=${1}`;
                    } else if (transactionType === 'UN_IMPORT') {
                        url = `${UNIMPORTED_FETCH_NEXT}=${accountIdnew}&company_id=${
                            companyData?.id
                        }&page=${1}`;
                    }

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    const data = await response.json();
                    return { [accountId]: data.plaid_transactions };
                });

                const results = await Promise.all(fetchPromises);

                setAllRows((prev) => ({
                    ...Object.assign({}, ...results),
                }));
            } catch (error) {
                console.error('Error fetching transactions:', error);
                throw error;
            }
        },
        [companyData?.id, transactionType],
    );

    useEffect(() => {
        const fetchData = () => {
            const accountsFormatted = accounts?.reduce((acc, account) => {
                acc[`${account?.id}_${account?.name}`] = [];
                return acc;
            }, {});
            setAllRows(
                processTransactionData(syncTransactionsData, accountsFormatted),
            );
            setTotalPages(
                processTransactionTotalPages(
                    syncTransactionsData,
                    accountsFormatted,
                ),
            );
            setCurrentPage(
                processTransactionPages(
                    syncTransactionsData,
                    accountsFormatted,
                ),
            );

            const allAccountIds = accounts?.map(({ account }) => account?.id);
            setExpanded(allAccountIds);
            setIsLoading(false);
        };
        const fetchData2 = async () => {
            const promises = accounts?.map(async (account) => {
                const data = await fetchTransactions(account?.id, 1);
                return { account, data };
            });

            const results = await Promise.all(promises);
            const sortedData = results?.sort(
                (a, b) =>
                    b?.data?.transactions?.length -
                    a?.data?.transactions?.length,
            );

            sortedData?.forEach(({ account, data }) => {
                setAllRows((prev) => ({
                    ...prev,
                    [`${account.id}_${account.name}`]: data.transactions,
                }));
                setTotalPages((prev) => ({
                    ...prev,
                    [`${account.id}_${account.name}`]: data.totalPages,
                }));
                setCurrentPage((prev) => ({
                    ...prev,
                    [`${account.id}_${account.name}`]: data.currentPage,
                }));
            });
            const allAccountIds = results?.map(({ account }) =>
                String(account?.id),
            );
            setExpanded(allAccountIds);
            setIsLoading(false);
        };

        if (accounts && transactionType === 'IMPORT') {
            fetchData();
        } else if (transactionType !== 'INITIAL') {
            fetchData2();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (accounts && transactionType === 'IMPORT') {
    //             const promises = accounts.map(async (account) => {
    //                 const data = await fetchTransactions(account.id, 1);
    //                 return { account, data };
    //             });

    //             const results = await Promise.all(promises);
    //             const sortedData = results?.sort(
    //                 (a, b) =>
    //                     b?.data?.transactions?.length -
    //                     a?.data?.transactions?.length,
    //             );

    //             sortedData.forEach(({ account, data }) => {
    //                 setAllRows((prev) => ({
    //                     ...prev,
    //                     [`${account?.id}_${account?.name}`]: data.transactions,
    //                 }));
    //                 setTotalPages((prev) => ({
    //                     ...prev,
    //                     [`${account?.id}_${account?.name}`]: data.totalPages,
    //                 }));
    //                 setCurrentPage((prev) => ({
    //                     ...prev,
    //                     [`${account?.id}_${account?.name}`]: data.currentPage,
    //                 }));
    //             });
    //             const allAccountIds = results?.map(
    //                 ({ account }) => account?.id,
    //             );
    //             setExpanded(allAccountIds);
    //         } else if (transactionType !== 'INITIAL') {
    //             const promises = accounts?.map(async (account) => {
    //                 const data = await fetchTransactions(account?.id, 1);
    //                 return { account, data };
    //             });

    //             const results = await Promise.all(promises);
    //             const sortedData = results?.sort(
    //                 (a, b) =>
    //                     b?.data?.transactions?.length -
    //                     a?.data?.transactions?.length,
    //             );

    //             sortedData?.forEach(({ account, data }) => {
    //                 setAllRows((prev) => ({
    //                     ...prev,
    //                     [`${account.id}_${account.name}`]: data.transactions,
    //                 }));
    //                 setTotalPages((prev) => ({
    //                     ...prev,
    //                     [`${account.id}_${account.name}`]: data.totalPages,
    //                 }));
    //                 setCurrentPage((prev) => ({
    //                     ...prev,
    //                     [`${account.id}_${account.name}`]: data.currentPage,
    //                 }));
    //             });
    //             const allAccountIds = results?.map(({ account }) =>
    //                 String(account?.id),
    //             );
    //             setExpanded(allAccountIds);
    //         }
    //         setIsLoading(false);
    //     };

    //     fetchData();
    // }, []);

    useEffect(() => {
        if (transactionType === 'INITIAL' && syncInitial && accounts2) {
            const newAccounts = filterAccountsSyncInitial(
                accounts2,
                syncInitial,
            );
            newAccounts?.forEach((account) => {
                setAllRows((prev) => ({
                    ...prev,
                    [`${account.account_id}_${account.name}`]:
                        syncInitial?.accounts_with_added_transactions?.[
                            account?.account_id
                        ]?.plaid_transactions,
                }));
                setTotalPages((prev) => ({
                    ...prev,
                    [`${account.account_id}_${account.name}`]:
                        syncInitial?.accounts_with_added_transactions?.[
                            account?.account_id
                        ]?.total_pages,
                }));
                setCurrentPage((prev) => ({
                    ...prev,
                    [`${account.account_id}_${account.name}`]:
                        syncInitial?.accounts_with_added_transactions?.[
                            account?.account_id
                        ]?.current_page,
                }));
            });
            const allAccountIds = newAccounts?.map((account) =>
                String(account?.account_id),
            );
            setExpanded(allAccountIds);
            setIsLoading(false);
        }
    }, [transactionType, accounts2, setIsLoading, syncInitial]);

    const handleAccordionChange = useCallback(
        (panel) => (event, isExpanded) => {
            setExpanded((prevExpanded) =>
                isExpanded
                    ? [...prevExpanded, panel]
                    : prevExpanded.filter((id) => id !== panel),
            );
        },
        [],
    );

    const handleDialogOpen = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const handleChangePage = useCallback(
        async (account, event, newPage) => {
            const page = newPage + 1;
            setCurrentPage((prev) => ({
                ...prev,
                [account]: page,
            }));

            const accountId = account.split('_')[0];
            const data = await fetchTransactions(accountId, page);

            setAllRows((prev) => ({
                ...prev,
                [account]: data.transactions,
            }));
            setTotalPages((prev) => ({
                ...prev,
                [account]: data.totalPages,
            }));
        },
        [fetchTransactions],
    );

    const handleChangePageAfterImportAll = useCallback(async () => {
        setTotalPages((previousState) => {
            const {
                updatedState,
                accountIdsNew,
                accountIdsComplete,
                accountIdsCompleteKey,
            } = decrementAndFilterPages(previousState);
            setCurrentPage(accountIdsCompleteKey);
            fetchTransactionsAfterImportAll(accountIdsComplete);
            return updatedState;
        });
    }, [fetchTransactionsAfterImportAll]);

    const handleChangeRowsPerPage = useCallback(
        async (account, event) => {
            const newRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(newRowsPerPage);

            setCurrentPage((prev) => ({
                ...prev,
                [account]: 1,
            }));

            const accountId = account.split('_')[0];
            const data = await fetchTransactions(accountId, 1);

            setAllRows((prev) => ({
                ...prev,
                [account]: data.transactions,
            }));
            setTotalPages((prev) => ({
                ...prev,
                [account]: data.totalPages,
            }));
        },
        [fetchTransactions],
    );

    const attach = useCallback(
        async (id, account) => {
            const result = attachedTransactions?.find(
                (item) => item?.plaid_transaction === id,
            );
            if (result) {
                setViewAttachedData(result);
            }
            setrowId(id);
            setAttachedAccountId(account);
            handleDialogOpen('bank');
        },
        [attachedTransactions, handleDialogOpen],
    );

    const handleAttachToggle = useCallback(
        async (newAttachedRow) => {
            const updatedAttachedRow = {
                ...attachedRow,
                ...newAttachedRow,
            };

            setAttachedRow(updatedAttachedRow);
            dispatch(SET_ATTACHED_ROWS(updatedAttachedRow));
        },
        [attachedRow, dispatch],
    );

    const handleAttachClick = useCallback(
        async (data) => {
            setLoading(true);
            const { token } = store.getState().auth;
            const payload = {
                plaidTransaction: rowId,
                data,
                token,
            };

            dispatch(attachTransactionRequest(payload));
            setLoading(false);
            handleDialogClose();
            const newAttachedRow = {
                [attachedAccountId]: {
                    ...attachedRow[attachedAccountId],
                    [rowId]: !attachedRow[attachedAccountId]?.[rowId],
                },
            };
            await handleAttachToggle(newAttachedRow);
        },
        [
            attachedAccountId,
            attachedRow,
            dispatch,
            handleAttachToggle,
            handleDialogClose,
            rowId,
        ],
    );

    const handleCheckboxChange = useCallback(
        (account, rowId) => (event) => {
            const updatedCheckedRows = {
                ...checkedRows,
                [account]: {
                    ...checkedRows[account],
                    [rowId]: event.target.checked,
                },
            };
            dispatch(SET_CHECKED_ROWS(updatedCheckedRows));
        },
        [checkedRows, dispatch],
    );

    const accountId = useCallback((accountString) => {
        const parts = accountString.split('_');
        return parts[0];
    }, []);

    const accountName = useCallback((accountString) => {
        const parts = accountString.split('_');
        return parts[1];
    }, []);

    const importAllTransactions = useCallback(async () => {
        setIsImportAllLoading(true);
        const { token } = store.getState().auth;
        const rowsData = getSelectedTransactionIds(allRows);
        if (!rowsData?.length) {
            setSnackbarMessage({
                type: 'error',
                message:
                    'No Transactions Available or No Accounts Category Selected',
            });
            setSnackbarOpen(true);
            return;
        }
        try {
            if (rowsData?.length) {
                dispatch(
                    importTransactionsRequest({
                        token,
                        companyId: companyData?.id,
                        transactionIds: rowsData,
                        setSnackbarOpen,
                        setSnackbarMessage,
                        setIsImportAllLoading,
                        handleChangePageAfterImportAll,
                    }),
                );
            } else {
                setIsImportAllLoading(false);
            }
        } catch (error) {
            console.error('Error making request', error);
        }
    }, [allRows, companyData?.id, dispatch, handleChangePageAfterImportAll]);

    ///// company select

    const [selectedAmounts, setSelectedAmounts] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [loadingOptions, setLoadingOptions] = useState({});

    const handleAmountChange = useCallback((event, rowId, name) => {
        const { value } = event.target;
        setSelectedAmounts((prevSelected) => ({
            ...prevSelected,
            [rowId]: name,
        }));
        updateCompany(rowId, value);
    }, []);

    const updateCompany = useCallback(async (rowId, selectedValue) => {
        try {
            const { token } = store.getState().auth;
            const response = await fetch(
                `${UPDATE_COMPANY}/${rowId}/${selectedValue}`,
                {
                    method: 'PATCH',
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
        } catch (error) {
            console.error('Error updating company:', error);
        }
    }, []);

    const fetchDropdownOptions = useCallback(
        async (rowId) => {
            if (!dropdownOptions[rowId]) {
                setLoadingOptions((prevLoading) => ({
                    ...prevLoading,
                    [rowId]: true,
                }));

                try {
                    const { token } = store.getState().auth;
                    const response = await fetch(COMPANY_FETCH, {
                        method: 'GET',
                        headers: {
                            accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    const data = await response.json();
                    setDropdownOptions((prevOptions) => ({
                        ...prevOptions,
                        [rowId]: data,
                    }));
                } catch (error) {
                    console.error('Error fetching dropdown options:', error);
                } finally {
                    setLoadingOptions((prevLoading) => ({
                        ...prevLoading,
                        [rowId]: false,
                    }));
                }
            }
        },
        [dropdownOptions],
    );

    const fetchCompanyAccountsCategoryData = useCallback(async (companyId) => {
        setIsPanelDataLoading(true);
        setPanelData({
            title: 'Select',
            children: [],
        });
        setOriginalPanelData([]);
        try {
            const { token } = store.getState().auth;
            const response = await fetch(COA_FETCH_WITH_CATEGORY(companyId), {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setPanelData(formatAccountCategoriesData(data, true));
            setOriginalPanelData(data?.active_accounts || []);
            setCompletePanelData(formatAccountCategoriesData(data));
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
            setPanelData({
                title: 'Select',
                children: [],
            });
        } finally {
            setIsPanelDataLoading(false);
        }
    }, []);

    const [openDropDownIndex, setOpenDropDownIndex] = useState(null);

    const importAccountCetegories = useCallback(
        (companyId) => {
            fetchCompanyAccountsCategoryData(companyId);
        },
        [fetchCompanyAccountsCategoryData],
    );

    const handleDropDownToggle = useCallback(
        (index, depth, companyId) => {
            if (index !== openDropDownIndex && depth === 1 && companyId) {
                importAccountCetegories(companyId);
            } else if (index !== openDropDownIndex && depth === 1) {
            }
            setOpenDropDownIndex((prev) => (prev === index ? null : index));
        },
        [importAccountCetegories, openDropDownIndex],
    );
    const handleDropDownClose = useCallback(() => {
        setOpenDropDownIndex(null);
    }, []);

    const updateCoaAccount = useCallback(
        async (plaidId, accountId, transactionId) => {
            try {
                const { token } = store.getState().auth;
                const response = await fetch(
                    COA_ACCOUNT_UPDATE(plaidId, accountId),
                    {
                        method: 'PATCH',
                        headers: {
                            accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                const data = await response.json();
                const inComingAccount = originalPanelData?.find(
                    (item) => item?.id === accountId,
                );
                setAllRows((prevRows) =>
                    replaceTransactionAccountWithNewAccount(
                        inComingAccount,
                        prevRows,
                        transactionId,
                    ),
                );
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            } finally {
            }
        },
        [originalPanelData],
    );

    //// COA
    const [selectedCoa, setSelectedCoa] = useState({});
    const [dropdownOptionsCoa, setDropdownOptionsCoa] = useState({});
    const [loadingOptionsCoa, setLoadingOptionsCoa] = useState({});

    const updateCoa = useCallback(async (rowId, selectedValue) => {
        try {
            const { token } = store.getState().auth;
            const response = await fetch(
                `${UPDATE_COA}/${rowId}/${selectedValue}`,
                {
                    method: 'PATCH',
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
        } catch (error) {
            console.error('Error updating company:', error);
        }
    }, []);

    const handleCoaChange = useCallback(
        (event, rowId) => {
            const { value } = event.target;
            setSelectedCoa((prevSelected) => ({
                ...prevSelected,
                [rowId]: value,
            }));
            updateCoa(rowId, value);
        },
        [updateCoa],
    );

    const fetchDropdownOptionsCoa = useCallback(
        async (rowId) => {
            if (!dropdownOptionsCoa[rowId]) {
                setLoadingOptionsCoa((prevLoading) => ({
                    ...prevLoading,
                    [rowId]: true,
                }));

                try {
                    const { token } = store.getState().auth;
                    const response = await fetch(
                        `${COA_FETCH}/${companyData?.id}/active`,
                        {
                            method: 'GET',
                            headers: {
                                accept: 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    const data = await response.json();
                    setDropdownOptionsCoa((prevOptions) => ({
                        ...prevOptions,
                        [rowId]: data?.results,
                    }));
                } catch (error) {
                    console.error('Error fetching dropdown options:', error);
                } finally {
                    setLoadingOptionsCoa((prevLoading) => ({
                        ...prevLoading,
                        [rowId]: false,
                    }));
                }
            }
        },
        [companyData?.id, dropdownOptionsCoa],
    );
    // import
    const handleImport = useCallback(
        (id, coaAccountId) => {
            if (!coaAccountId) {
                setSnackbarMessage({
                    type: 'error',
                    message: 'No Account Category Selected',
                });
                setSnackbarOpen(true);
                return;
            }
            const { token } = store.getState().auth;
            if (!id || !token || !companyData?.id) {
                setSnackbarOpen(true);
                setSnackbarMessage('Unable to Import');
                return;
            }
            dispatch(
                importTransactionsRequest({
                    token,
                    companyId: companyData?.id,
                    transactionIds: [id],
                    setSnackbarOpen,
                    setSnackbarMessage,
                }),
            );
            setAllRows((prevRows) => removeTransactionsById(prevRows, [id]));
        },
        [companyData?.id, dispatch],
    );

    const handleSnackbarClose = useCallback(() => {
        setSnackbarOpen(false);
    }, []);

    const onPanelListItemClick = useCallback(
        (accountNumber, plaidId, transactionId) => {
            setOpenDropDownIndex(null);
            updateCoaAccount(plaidId, accountNumber, transactionId);
        },
        [updateCoaAccount],
    );

    const changeDropDownLength = useCallback(() => {
        if (panelData?.children?.length === 2) {
            setPanelData({ ...completePanelData });
        } else {
            setPanelData({
                ...completePanelData,
                children: completePanelData?.children?.slice(0, 2),
            });
        }
    }, [completePanelData, panelData?.children?.length]);

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                my={2}
                mb={4}
                sx={{
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    alignItems: {
                        xs: 'center',
                    },
                    textAlign: {
                        xs: 'center',
                    },
                }}
            >
                <Box
                    sx={{
                        flexDirection: {
                            xs: 'row',
                            sm: 'column',
                        },
                        alignItems: {
                            xs: 'center',
                            sm: 'start',
                        },
                        textAlign: {
                            xs: 'center',
                            sm: 'start',
                        },
                    }}
                >
                    <IconButton onClick={() => setSyncStatus(0)} sx={{}}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        sx={{
                            color: '#0061DB',
                            fontWeight: '700',
                            fontSize: {
                                xs: '14px',
                                sm: '12px',
                            },
                        }}
                    >
                        Step 2 of 2
                    </Typography>
                    <Typography
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? 'white'
                                    : '#121826',
                            fontWeight: '700',
                            fontSize: {
                                xs: '20px',
                                sm: '18px',
                            },
                        }}
                    >
                        {transactionType === 'IMPORT'
                            ? 'Import '
                            : 'UnImported '}
                        Bank Transactions
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: { xs: '', sm: 2 },
                        width: { xs: '100%', sm: 'auto' },
                    }}
                >
                    <LoadingButton
                        loading={isImportAllLoading}
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            borderRadius: '8px',
                            paddingX: '10px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                            width: {
                                xs: '100%',
                                sm: 'auto',
                            },
                            mt: { xs: 2, sm: 0 },
                        }}
                        onClick={() => importAllTransactions()}
                    >
                        <img
                            src={MyIcon}
                            alt="Icon"
                            style={{
                                width: 18,
                                height: 18,
                                marginRight: '8px',
                            }}
                        />
                        Import All
                    </LoadingButton>

                    {/* <Button
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1.5px solid #394150'
                                    : '1px solid #E5E6EB',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#9EA3AE'
                                    : '#6C727F',
                            borderRadius: '8px',
                            textTransform: 'none',
                            width: {
                                xs: '100%',
                                sm: 'auto',
                            },
                            mt: { xs: 2, sm: 0 },
                            paddingX: '10px',
                        }}
                        onClick={handleSelectAll}
                    >
                        {allSelected ? 'Unselect' : 'Select'}
                    </Button> */}
                </Box>
            </Box>

            <TableNew
                allRows={allRows}
                expanded={expanded}
                accountId={accountId}
                handleAccordionChange={handleAccordionChange}
                accountName={accountName}
                smDown={smDown}
                checkboxVisible={checkboxVisible}
                handleCheckboxChange={handleCheckboxChange}
                checkedRows={checkedRows}
                attach={attach}
                attachedTransactions={attachedTransactions}
                handleImport={handleImport}
                selectedAmounts={selectedAmounts}
                handleAmountChange={handleAmountChange}
                fetchDropdownOptions={fetchDropdownOptions}
                fetchDropdownOptionsCoa={fetchDropdownOptionsCoa}
                loadingOptions={loadingOptions}
                loadingOptionsCoa={loadingOptionsCoa}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                dropdownOptions={dropdownOptions}
                selectedCoa={selectedCoa}
                handleCoaChange={handleCoaChange}
                dropdownOptionsCoa={dropdownOptionsCoa}
                totalPages={totalPages}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                panelData={panelData}
                onListItemClick={onPanelListItemClick}
                changeDropDownLength={changeDropDownLength}
                importAccountCetegories={importAccountCetegories}
                isPanelDataLoading={isPanelDataLoading}
                openDropDownIndex={openDropDownIndex}
                handleDropDownToggle={handleDropDownToggle}
                handleDropDownClose={handleDropDownClose}
            />

            <ImportDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                handleAttachClick={handleAttachClick}
                loading={loading}
                data={
                    attachedTransactions?.find(
                        (item) => item?.plaid_transaction === rowId,
                    )
                        ? viewAttachedData
                        : ''
                }
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarMessage?.type || 'success'}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage?.message || snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ImportTransactionsSelect;
