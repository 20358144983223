import { LoadingButton } from '@mui/lab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import Accordian from 'assets/icons/accordian.svg';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { createClientRequest } from 'store/actions';
import * as Yup from 'yup';
import UsStates from './UsStates';

const AddClient = ({ setAdd, value, handleChange }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.company.data);
    const companyId = data.id;
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required('Full Name is required'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        phone: Yup.string()
            .matches(
                /^(\+?\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?[\d\s.-]{7,10}$/,
                'Phone number is not valid',
            )
            .required('Phone number is required'),
        organization: Yup.string(),
        addressLine1: Yup.string(),
        addressLine2: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zipCode: Yup.string(),
        privateNotes: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            phone: '',
            organization: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            privateNotes: '',
        },
        validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            const { token } = store.getState().auth;
            const clientData = {
                companies: [companyId],
                full_name: values.fullName,
                email: values.email,
                phone_number: values.phone,
                organization: values.organization,
                address_line_1: values.addressLine1,
                address_line_2: values.addressLine2,
                city: values.city,
                state: values.state,
                zip_code: values.zipCode,
                private_notes: values.privateNotes,
            };

            dispatch(createClientRequest({ clientData, token }));
            setTimeout(() => {
                setLoading(false);
                setAdd(false);
            }, 2000);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box mt={3}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Full Name
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    label="Add client’s full name"
                    variant="outlined"
                    name="fullName"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.fullName &&
                        Boolean(formik.errors.fullName)
                    }
                    helperText={
                        formik.touched.fullName && formik.errors.fullName
                    }
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '1px solid #E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box mt={2}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Email Address
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    label="Add email address"
                    variant="outlined"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '1px solid #E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box mt={2}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Phone number
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    label="Add Phone number"
                    variant="outlined"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    helperText={formik.touched.phone && formik.errors.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '1px solid #E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box mt={2}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Organization
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    label="Add client’s organization"
                    variant="outlined"
                    name="organization"
                    value={formik.values.organization}
                    onChange={formik.handleChange}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '1px solid #E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>

            <Accordion>
                <AccordionSummary
                    aria-controls="panel3-content"
                    id="panel3-header"
                    sx={{
                        padding: '0px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                        width: '110px',
                    }}
                    expandIcon={
                        <img
                            src={Accordian}
                            alt="Icon"
                            style={{
                                width: 14,
                                height: 14,
                            }}
                        />
                    }
                >
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#0061DB',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        More details
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '0px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                        >
                            Address Line 1
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            label="Add client’s address"
                            variant="outlined"
                            name="addressLine1"
                            value={formik.values.addressLine1}
                            onChange={formik.handleChange}
                            sx={{
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'none'
                                                : '1px solid #E5E6EB',
                                        borderRadius: '8px',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                                marginBottom: '10px',
                            }}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            label="Add client’s address"
                            variant="outlined"
                            name="addressLine2"
                            value={formik.values.addressLine2}
                            onChange={formik.handleChange}
                            sx={{
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'none'
                                                : '1px solid #E5E6EB',
                                        borderRadius: '8px',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                        mt={2}
                    >
                        <Box
                            sx={{
                                flexBasis: {
                                    xs: '48%',
                                    md: '32%',
                                },
                                maxWidth: {
                                    xs: '48%',
                                    md: '32%',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                }}
                            >
                                City
                            </Typography>
                            <TextField
                                fullWidth
                                size="small"
                                label="Ex. New York"
                                variant="outlined"
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                sx={{
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '1px solid #E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                flexBasis: {
                                    xs: '48%',
                                    md: '32%',
                                },
                                maxWidth: {
                                    xs: '48%',
                                    md: '32%',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                }}
                            >
                                State
                            </Typography>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <InputLabel id="custom-select-label">
                                    Select State
                                </InputLabel>
                                <Select
                                    labelId="custom-select-label"
                                    id="custom-select"
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    label="Select State"
                                    sx={{
                                        backgroundColor: '#F4F4F6',
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '6px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none', // Hide default border
                                        },
                                        '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                            {
                                                border: '1px solid #E5E6EB', // Border color on focus
                                            },
                                    }}
                                >
                                    {UsStates.map((state) => (
                                        <MenuItem
                                            key={state.abbreviation}
                                            value={state.abbreviation}
                                        >
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                flexBasis: {
                                    xs: '100%',
                                    md: '32%',
                                },
                                maxWidth: {
                                    xs: '100%',
                                    md: '32%',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                }}
                            >
                                Zip Code
                            </Typography>
                            <TextField
                                fullWidth
                                size="small"
                                label="Ex. 89482"
                                variant="outlined"
                                name="zipCode"
                                value={formik.values.zipCode}
                                onChange={formik.handleChange}
                                sx={{
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '1px solid #E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Box>

                    <Box mt={2}>
                        <Typography
                            sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                        >
                            Private Notes
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            label="Add private notes"
                            variant="outlined"
                            name="privateNotes"
                            value={formik.values.privateNotes}
                            onChange={formik.handleChange}
                            sx={{
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'none'
                                                : '1px solid #E5E6EB',
                                        borderRadius: '8px',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Box textAlign="end">
                <Button
                    sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#6C727F',
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        textTransform: 'none',
                        marginRight: '10px',
                    }}
                    size="small"
                    onClick={() => setAdd(false)}
                >
                    Cancel
                </Button>
                <LoadingButton
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        textTransform: 'none',
                        paddingX: '20px',
                        paddingY: '8px',
                        '&:hover': {
                            backgroundColor: '#004BB5',
                        },
                        width: {
                            xs: '100%',
                            sm: 'auto',
                        },
                    }}
                    size="small"
                    type="submit"
                    loading={loading}
                >
                    + Add
                </LoadingButton>
            </Box>
        </form>
    );
};

export default AddClient;
