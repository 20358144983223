import {
    Alert,
    Box,
    Divider,
    Grid,
    Snackbar,
    Table,
    TableBody,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import PaymentDue from 'components/Invoice/PaymentDue';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchInvoiceByIdRequest, setInvoiceTotals } from 'store/actions';
import { calculateTax, processPaymentStatus } from 'utils/helpers';
import ServiceInfoTableHeader from '../AddServiceinvoice/ServiceInfoTableHeader';
import ServiceItemRow from '../AddServiceinvoice/ServiceItemRow';
import AppBarComponent from './AppBarComponent';
import BillTo from './BillTo';
import InvoiceDetails from './InvoiceDetails';
import InvoiceTotals from './InvoiceTotals';

function PreviewInvoice({ tab, isSingle = true }) {
    if (tab == null || tab === undefined) {
        tab = 0;
    }
    const dispatch = useDispatch();
    const invoiceServices = useSelector(
        (state) => state.invoice.invoiceServices,
    );
    const client = useSelector((state) => state.invoice.client);
    const singleInvoice = useSelector((state) => state?.invoice?.singleInvoice);
    const invoiceTolbarTax = useSelector(
        (state) => state?.invoice?.singleInvoice?.taxes || [],
    );
    const theme = useTheme();
    const { invoiceIds } = useParams();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { invoice } = useSelector((state) => state.invoice);
    const location = useLocation();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('success')) {
            setSnackbarMessage('Payment completed successfully');
            setSnackbarOpen(true);
        }
    }, [location.search]);
    useEffect(() => {
        if (!isSingle) return;
        const fetchData = async () => {
            try {
                dispatch(
                    fetchInvoiceByIdRequest({
                        invoiceId: invoiceIds,
                    }),
                );
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSingle]);
    useEffect(() => {
        if (!isSingle && !invoiceServices?.length) return;
        dispatch(setInvoiceTotals(calculateTax(invoiceServices)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSingle, invoiceServices?.length]);

    const invoiceNumber = invoiceIds || invoice?.id || 0;
    const formatDate = useCallback((dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }, []);

    const dueDate = formatDate(new Date());
    const dateIssued = dueDate;
    const [invoiceStep, setInvoiceStep] = useState('INVOICE');

    const changeStep = useCallback((step) => {
        setInvoiceStep(step);
    }, []);

    const [selectedTabPreview, setSelectedTabPreview] = useState(0);

    const pdfRef = useRef();

    const handleSnackbarClose = useCallback(() => {
        setSnackbarOpen(false);
    }, []);

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={'info'}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {invoiceIds && (
                <AppBarComponent
                    changeStep={changeStep}
                    setSelectedTabPreview={setSelectedTabPreview}
                    invoiceStep={invoiceStep}
                    pdfRef={pdfRef} // Pass the ref to AppBarComponent
                />
            )}

            <Box
                // bgcolor="#F4F4F6"
                display="flex"
                flexDirection="column"
                paddingX="auto"
                ref={pdfRef} // Attach the ref here
                sx={{
                    width: '100%',
                    minHeight: '100vh',
                    paddingBottom: '30px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#121826' : '#F4F4F6',
                }}
            >
                {invoiceStep === 'INVOICE' ? (
                    <Grid
                        container
                        spacing={2}
                        mt={invoiceIds >= 0 ? 15 : 0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {tab === 0 && selectedTabPreview === 0 ? (
                            // Desktop View
                            <Grid
                                container
                                spacing={2}
                                mt={4}
                                justifyContent="center"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    boxSizing="border-box"
                                >
                                    <Box
                                        sx={{
                                            bgcolor:
                                                theme.palette.mode === 'dark'
                                                    ? '#212936'
                                                    : 'white',
                                            height: '100%',
                                            padding: '25px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                bgcolor:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#121826'
                                                        : '#F9FAFB',
                                                borderRadius: '10px',
                                                padding: '20px',
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                textAlign="center"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: '700',
                                                        fontSize: '20px',
                                                    }}
                                                >
                                                    Invoice
                                                </Typography>
                                                {!!singleInvoice?.status && (
                                                    <Typography
                                                        noWrap
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '500',
                                                            backgroundColor:
                                                                singleInvoice?.status ===
                                                                'sent'
                                                                    ? '#ECFDF3'
                                                                    : singleInvoice?.status ===
                                                                      'Overdue'
                                                                    ? '#FEF3F2'
                                                                    : singleInvoice?.status ===
                                                                      'draft'
                                                                    ? '#EEF4FF'
                                                                    : singleInvoice?.status ===
                                                                      'payment_processing'
                                                                    ? '#FFF7E5'
                                                                    : singleInvoice?.status ===
                                                                      'paid'
                                                                    ? '#E6F4FF'
                                                                    : '#FEF3F2',

                                                            color:
                                                                singleInvoice?.status ===
                                                                'sent'
                                                                    ? '#067647'
                                                                    : singleInvoice?.status ===
                                                                      'Overdue'
                                                                    ? '#B42318'
                                                                    : singleInvoice?.status ===
                                                                      'draft'
                                                                    ? '#3538CD'
                                                                    : singleInvoice?.status ===
                                                                      'payment_processing'
                                                                    ? '#FFB400'
                                                                    : singleInvoice?.status ===
                                                                      'paid'
                                                                    ? '#007BFF'
                                                                    : '#FEF3F2',
                                                            border:
                                                                singleInvoice?.status ===
                                                                'sent'
                                                                    ? '1px solid #ABEFC6'
                                                                    : singleInvoice?.status ===
                                                                      'Overdue'
                                                                    ? '1px solid #FECDCA'
                                                                    : singleInvoice?.status ===
                                                                      'draft'
                                                                    ? '1px solid #C7D7FE'
                                                                    : singleInvoice?.status ===
                                                                      'payment_processing'
                                                                    ? '1px solid #FFE5B4'
                                                                    : singleInvoice?.status ===
                                                                      'paid'
                                                                    ? '1px solid #C3E7FF'
                                                                    : '1px solid #FECDCA',
                                                            width: '150px',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            borderRadius:
                                                                '12px',
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        {processPaymentStatus(
                                                            singleInvoice?.status,
                                                        )}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box
                                                mt={4}
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                <BillTo client={client} />
                                                <InvoiceDetails
                                                    invoiceNumber={
                                                        invoiceNumber
                                                    }
                                                    dateIssued={dateIssued}
                                                    dueDate={dueDate}
                                                />
                                            </Box>
                                        </Box>
                                        <Table
                                            sx={{
                                                width: '100%',
                                                border: 'none',
                                            }}
                                        >
                                            <ServiceInfoTableHeader
                                                invoiceTolbarTax={invoiceTolbarTax?.filter(
                                                    (item) =>
                                                        item?.id &&
                                                        item?.unit === '%',
                                                )}
                                            />
                                            <TableBody>
                                                {invoiceServices?.map(
                                                    (service, index) => (
                                                        <ServiceItemRow
                                                            key={index}
                                                            service={service}
                                                            index={index}
                                                            isHovered={false}
                                                            invoiceTolbarTax={invoiceTolbarTax?.filter(
                                                                (item) =>
                                                                    item?.id &&
                                                                    item?.unit ===
                                                                        '%',
                                                            )}
                                                        />
                                                    ),
                                                )}
                                            </TableBody>
                                        </Table>
                                        <InvoiceTotals />
                                    </Box>
                                </Grid>
                            </Grid>
                        ) : (
                            // Mobile View
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                xl={4}
                                boxSizing="border-box"
                            >
                                <Box
                                    sx={{
                                        bgcolor:
                                            theme.palette.mode === 'dark'
                                                ? '#212936'
                                                : 'white',
                                        height: '100%',
                                        padding: '20px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            bgcolor:
                                                theme.palette.mode === 'dark'
                                                    ? '#121826'
                                                    : '#F9FAFB',
                                            borderRadius: '10px',
                                            padding: '15px',
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            textAlign="center"
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: '700',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Invoice
                                            </Typography>
                                        </Box>
                                        <Box
                                            mt={3}
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <BillTo
                                                client={client}
                                                invoiceNumber={invoiceNumber}
                                                dueDate={dueDate}
                                                dateIssued={dateIssued}
                                                compact
                                            />
                                        </Box>
                                    </Box>
                                    {/* <ServiceInfo />
                                    <div>
                                        {invoiceServices.map(
                                            (service, index) => (
                                                <ServiceItem
                                                    key={index}
                                                    service={service}
                                                />
                                            ),
                                        )}
                                    </div> */}
                                    <Table
                                        sx={{
                                            width: '100%',
                                            border: 'none',
                                        }}
                                    >
                                        <ServiceInfoTableHeader
                                            invoiceTolbarTax={invoiceTolbarTax?.filter(
                                                (item) =>
                                                    item?.id &&
                                                    item?.unit === '%',
                                            )}
                                        />
                                        <TableBody>
                                            {invoiceServices?.map(
                                                (service, index) => (
                                                    <ServiceItemRow
                                                        key={index}
                                                        service={service}
                                                        index={index}
                                                        isHovered={false}
                                                        invoiceTolbarTax={invoiceTolbarTax?.filter(
                                                            (item) =>
                                                                item?.id &&
                                                                item?.unit ===
                                                                    '%',
                                                        )}
                                                    />
                                                ),
                                            )}
                                        </TableBody>
                                    </Table>
                                    <Divider />
                                    <InvoiceTotals isMobile />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Box mt={8} paddingX={isSmallScreen ? 1 : 30}>
                        <PaymentDue
                            invoiceStep={false}
                            totalPreview={
                                invoiceServices[0]?.total *
                                invoiceServices[0]?.qty
                            }
                            setInvoiceStep={setInvoiceStep}
                            isSingle={isSingle}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
}

export default PreviewInvoice;
