import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    brandInvoiceRequest,
    deleteBrandInvoiceRequest,
    fetchInvoiceLogoRequest,
} from 'store/actions';
import { useCompanyService } from 'store/services/company.service';
import MyIcon from '../../../assets/icons/Trash.svg';
import InvoiceSttingPreview from '../Components/InvoicePreview/InvoiceSettingPreview';

const color = '#685CED';

function BrandYourInvoice({ onSyncAll }) {
    const dispatch = useDispatch();
    const { getUserAssociatedCompany } = useCompanyService();
    const invoiceLogo = useSelector((state) => state.invoice.invoiceLogo);
    const [userCompanyData, setUserCompanyData] = useState(null);

    useEffect(() => {
        const { token } = store.getState().auth;
        dispatch(fetchInvoiceLogoRequest({ token }));
    }, [dispatch]);

    const fileInputRef = useRef(null);

    const handleButtonClick2 = useCallback(() => {
        fileInputRef.current.click();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserAssociatedCompany();
            setUserCompanyData(data);
        };

        fetchData();
    }, [getUserAssociatedCompany]);

    const fetchData = useCallback(
        async (image) => {
            const { token } = store.getState().auth;
            const companyId = userCompanyData?.[0]?.id;
            const id = invoiceLogo?.id;
            try {
                dispatch(
                    brandInvoiceRequest({
                        companyId,
                        token,
                        image,
                        id,
                        color,
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        },
        [dispatch, invoiceLogo?.id, userCompanyData],
    );

    const deleteInvoiceBrand = useCallback(async () => {
        const { token } = store.getState().auth;
        const id = invoiceLogo?.id;
        try {
            dispatch(
                deleteBrandInvoiceRequest({
                    token,
                    id,
                }),
            );
        } catch (error) {
            console.error(error);
        }
    }, [dispatch, invoiceLogo?.id]);

    const handleFileChange = useCallback(
        (event) => {
            const file = event?.target?.files?.[0];
            if (file && file.type.startsWith('image/')) {
                fetchData(file);
            }
        },
        [fetchData],
    );

    const handleSubmit = useCallback(() => {
        onSyncAll('two');
    }, [onSyncAll]);
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark'
                            ? '#121826'
                            : 'transparent',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>
                    Invoice Settings
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                mt={2}
                mx={4}
            >
                <Grid
                    container
                    spacing={2}
                    p={1}
                    sx={{
                        borderRadius: '12px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    <Grid item xs={12} md={7.5} pr={2}>
                        <Box>
                            <Box mb={3}>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#6C727F',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Step 1 of 5
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        fontSize: '18px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#F9FAFB'
                                                : '#121826',
                                    }}
                                >
                                    Brand your invoice and make it unique
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#9EA3AE'
                                                : '#6C727F',
                                    }}
                                >
                                    Upload your logo select the color of your
                                    brand to keep it identical.
                                </Typography>
                            </Box>
                            <Divider />

                            <Box
                                mt={5}
                                display="flex"
                                flexDirection="column"
                                alignItems="end"
                                justifyContent="space-between"
                                gap={10}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            // alignItems="center"
                                        >
                                            <Typography
                                                sx={{
                                                    mb: 1,
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Brand logo
                                            </Typography>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px dashed #E5E6EB',
                                                    borderRadius: '12px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        p: 4,
                                                        border: 'none',
                                                        color: '#6C727F',
                                                        textTransform: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        backgroundSize:
                                                            'contain',
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundImage:
                                                            invoiceLogo?.brand_logo
                                                                ? `url(${invoiceLogo?.brand_logo})`
                                                                : 'none',
                                                    }}
                                                    onClick={handleButtonClick2}
                                                >
                                                    {!invoiceLogo?.brand_logo &&
                                                        '+ Click to upload'}
                                                </Button>
                                                {invoiceLogo?.brand_logo && (
                                                    <IconButton
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            right: 8,
                                                            top: 8,
                                                            backgroundColor:
                                                                'rgba(255, 255, 255, 0.8)',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    'rgba(255, 255, 255, 1)',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            deleteInvoiceBrand()
                                                        }
                                                    >
                                                        <img
                                                            src={MyIcon}
                                                            alt="Icon"
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={12} md={6}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography
                                                sx={{
                                                    mb: 1,
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Brand color
                                            </Typography>
                                            <Button
                                                sx={{
                                                    width: '100%',
                                                    p: 4,
                                                    backgroundColor: color,
                                                    border: 'none',
                                                    color: '#FFFFFF',
                                                    borderRadius: '12px',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#5749C8',
                                                    },
                                                }}
                                                onClick={handleButtonClick}
                                            >
                                                Select color
                                            </Button>
                                            <input
                                                type="color"
                                                ref={colorInputRef}
                                                value={color}
                                                onChange={handleColorChange}
                                                style={{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    transform:
                                                        'translateX(-50%)',
                                                    opacity: 0,
                                                    height: 0,
                                                    width: 0,
                                                    border: 'none',
                                                    padding: '0',
                                                }}
                                            />
                                        </Box>
                                    </Grid> */}
                                </Grid>

                                <Box display="flex" gap={2}>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => onSyncAll('two')}
                                    >
                                        Skip
                                    </Button>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            border: '1px solid #E5E6EB',
                                            textTransform: 'none',
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        Next {'-->'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <InvoiceSttingPreview />
                </Grid>
            </Box>
        </>
    );
}

export default BrandYourInvoice;
