import { Box, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

const BillTo = ({ client, invoiceNumber, dueDate, dateIssued, compact }) => {
    const { singleInvoice, invoiceLogo } = useSelector(
        (state) => state.invoice,
    );
    const theme = useTheme();
    return (
        <Box sx={{ width: compact && '100%' }}>
            <img
                src={singleInvoice?.brand_logo || invoiceLogo?.brand_logo}
                alt=""
                srcSet=""
                style={{
                    width: 'fit-content',
                    height: 'auto',
                    maxHeight: '100px',
                    maxWidth: '200px',
                }}
            />
            <Typography sx={{ color: '#6C727F', marginY: '15px' }}>
                Bill to
            </Typography>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ width: '100%' }}
            >
                <Box ml={2} sx={{ width: '40%' }}>
                    <Typography
                        fontWeight="500"
                        sx={{
                            color:
                                theme.palette.mode === 'dark'
                                    ? '#F9FAFB'
                                    : '#121826',
                        }}
                    >
                        {client?.fullName}
                    </Typography>
                    <Typography fontWeight="500" sx={{ color: '#121826' }}>
                        {client?.email}
                    </Typography>
                    <Typography fontWeight="500" sx={{ color: '#121826' }}>
                        {client?.phone}
                    </Typography>
                    <Typography fontWeight="500" sx={{ color: '#121826' }}>
                        {client?.address}
                    </Typography>
                </Box>
                {compact && (
                    <Box display="flex" flexDirection="column">
                        <Box>
                            <Typography
                                sx={{
                                    color: '#6C727F',
                                    marginBottom: '5px',
                                    fontSize: '12px',
                                }}
                            >
                                Invoice #
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#4D5461',
                                    marginBottom: '5px',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                }}
                            >
                                {invoiceNumber}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#6C727F',
                                    marginBottom: '5px',
                                    fontSize: '12px',
                                }}
                            >
                                Next payment due
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#4D5461',
                                    marginBottom: '5px',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                }}
                            >
                                {dueDate}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    color: '#6C727F',
                                    marginBottom: '5px',
                                    fontSize: '12px',
                                }}
                            >
                                Date issued
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#4D5461',
                                    marginBottom: '5px',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                }}
                            >
                                {dateIssued}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default BillTo;
