import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import Trash from 'assets/icons/Trash.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    deleteInvoiceService,
    fetchServicesRequest,
    fetchServicesSuccess,
    serviceInvoiceRequest,
} from 'store/actions';
import InvoiceSttingPreview from '../Components/InvoicePreview/InvoiceSettingPreview';

function ListOfServices({ onSyncAll }) {
    const serviceEndRef = useRef();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.company.data);
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [showAddServiceButton, setShowAddServiceButton] = useState(false);

    const services = useSelector(
        (state) => state.invoice.dropDownServices || [],
    );

    useEffect(() => {
        if (services?.[services?.length - 1]?.id) {
            setShowAddServiceButton(true);
        } else if (!services?.length) {
            setShowAddServiceButton(true);
        } else {
            setShowAddServiceButton(false);
        }
    }, [services]);

    useEffect(() => {
        const { token } = store.getState().auth;
        dispatch(fetchServicesRequest({ token }));
    }, [dispatch]);

    const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);

    const scrollToBottom = useCallback(() => {
        serviceEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [serviceEndRef]);

    const handleServiceChange = useCallback(
        (ind, field, value) => {
            const updatedServices = services.map((service, index) =>
                index === ind
                    ? {
                          ...service,
                          [field]: value,
                          errors: { ...service.errors, [field]: '' },
                      }
                    : service,
            );
            dispatch(fetchServicesSuccess(updatedServices));
        },
        [dispatch, services],
    );

    const addService = useCallback(() => {
        const newService = {
            name: '',
            price: '',
            details: '',
            isNew: true,
            company: data?.id,
            errors: { name: '', price: '', details: '' },
        };
        dispatch(fetchServicesSuccess([...services, newService]));
        setShowSubmitButton(true);
        setTimeout(() => {
            scrollToBottom();
        }, 200);
    }, [dispatch, scrollToBottom, services, data]);

    const removeService = useCallback(
        (index) => {
            if (services?.[index]?.id) {
                const { token } = store.getState().auth;
                dispatch(
                    deleteInvoiceService({
                        serviceId: services?.[index]?.id,
                        token,
                    }),
                );
            } else {
                setShowSubmitButton(false);
                const updatedServices = services?.filter((_, i) => i !== index);
                dispatch(fetchServicesSuccess(updatedServices));
            }
            setSelectedServiceIndex(null);
        },
        [dispatch, services],
    );

    const validateServices = useCallback(() => {
        let valid = true;
        const updatedServices = services.map((service) => {
            const errors = {
                name: !service.name ? 'Service is required' : '',
                price: !service.price ? 'Price is required' : '',
                details: !service.details ? 'Details are required' : '',
            };
            if (errors.name || errors.price || errors.details) valid = false;
            return { ...service, errors };
        });
        dispatch(fetchServicesSuccess(updatedServices));
        return valid;
    }, [dispatch, services]);

    const saveServices = useCallback(async () => {
        onSyncAll('four');
    }, [onSyncAll]);

    const submit = useCallback(() => {
        if (!validateServices()) return;
        const { token } = store.getState().auth;
        const newService = services?.[services?.length - 1];
        dispatch(
            serviceInvoiceRequest({
                name: newService?.name,
                details: newService.details,
                price: newService.price,
                company: data?.id,
                token,
            }),
        );
        setShowSubmitButton(false);
        setSelectedServiceIndex(null);
    }, [data?.id, dispatch, services, validateServices]);

    const handleBoxClick = useCallback((index) => {
        setSelectedServiceIndex(index);
    }, []);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark'
                            ? '#121826'
                            : 'transparent',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>
                    Invoice Settings
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                mt={2}
                mx={4}
            >
                <Grid
                    container
                    spacing={2}
                    p={1}
                    sx={{
                        borderRadius: '12px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    <Grid item xs={12} md={7.5} pr={2}>
                        <Box>
                            <Box mb={3}>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#6C727F',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Step 3 of 5
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        fontSize: '18px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#F9FAFB'
                                                : '#121826',
                                    }}
                                >
                                    List of services you offer
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#9EA3AE'
                                                : '#6C727F',
                                    }}
                                >
                                    Name your top services. You can add more
                                    services and description later.
                                </Typography>
                            </Box>
                            <Divider />

                            <Box
                                mt={5}
                                display="flex"
                                flexDirection="column"
                                alignItems="end"
                                sx={{
                                    height: '400px',
                                    overflowY: 'auto',
                                    mt: 2,
                                    width: '100%',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#888',
                                        borderRadius: '8px',
                                        border: '2px solid transparent',
                                        backgroundClip: 'content-box',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: '#555',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#f1f1f1',
                                        borderRadius: '8px',
                                    },
                                }}
                            >
                                {services?.map((service, index) => (
                                    <>
                                        <Box
                                            key={index}
                                            width="100%"
                                            display="flex"
                                            flexDirection="column"
                                            gap={2}
                                            border="none"
                                            borderRadius="8px"
                                            p={2}
                                            position="relative"
                                            // sx={{
                                            //     border:
                                            //         selectedServiceIndex ===
                                            //         index
                                            //             ? '1px solid blue'
                                            //             : 'none',
                                            // }}
                                            // onClick={() =>
                                            //     handleBoxClick(index)
                                            // }
                                            onMouseEnter={() =>
                                                handleBoxClick(index)
                                            }
                                            onMouseLeave={() =>
                                                handleBoxClick(null)
                                            }
                                        >
                                            {selectedServiceIndex === index && (
                                                <Button
                                                    onMouseDown={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeService(index);
                                                    }}
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '5px',
                                                        bottom: '5px',
                                                        zIndex: '1000',
                                                        border: '1px solid #FA9999',
                                                        borderRadius: '20%',
                                                        color: '#F75555',
                                                        bgcolor: 'white',
                                                        textTransform: 'none',
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '16px',
                                                        },
                                                        width: '30px',
                                                        height: '30px',
                                                        minWidth: 'unset',
                                                        padding: '5px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <img
                                                        src={Trash}
                                                        alt="Icon"
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                </Button>
                                            )}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: {
                                                        xs: 'column',
                                                        md: 'row',
                                                    },
                                                    gap: 2,
                                                    width: '100%',
                                                }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={8}>
                                                        <Autocomplete
                                                            freeSolo
                                                            fullWidth
                                                            size="small"
                                                            value={
                                                                service?.name
                                                            }
                                                            onInputChange={(
                                                                e,
                                                                newValue,
                                                            ) =>
                                                                handleServiceChange(
                                                                    index,
                                                                    'name',
                                                                    newValue,
                                                                )
                                                            }
                                                            options={services?.map(
                                                                (option) =>
                                                                    option?.name,
                                                            )}
                                                            renderInput={(
                                                                params,
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Service"
                                                                    variant="outlined"
                                                                    error={
                                                                        !!service
                                                                            ?.errors
                                                                            ?.name
                                                                    }
                                                                    helperText={
                                                                        service
                                                                            ?.errors
                                                                            ?.name
                                                                    }
                                                                    sx={{
                                                                        backgroundColor:
                                                                            '#F4F4F6',
                                                                        bgcolor:
                                                                            (
                                                                                theme,
                                                                            ) =>
                                                                                theme
                                                                                    .palette
                                                                                    .mode ===
                                                                                'dark'
                                                                                    ? '#394150'
                                                                                    : '#F4F4F6',
                                                                        borderRadius:
                                                                            '6px',
                                                                        '& .MuiOutlinedInput-notchedOutline':
                                                                            {
                                                                                border: (
                                                                                    theme,
                                                                                ) =>
                                                                                    theme
                                                                                        .palette
                                                                                        .mode ===
                                                                                    'dark'
                                                                                        ? 'none'
                                                                                        : '1px solid #E5E6EB',
                                                                                borderRadius:
                                                                                    '8px',
                                                                            },
                                                                        '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                            {
                                                                                border: (
                                                                                    theme,
                                                                                ) =>
                                                                                    theme
                                                                                        .palette
                                                                                        .mode ===
                                                                                    'dark'
                                                                                        ? 'none'
                                                                                        : '1px solid #E5E6EB',
                                                                                borderRadius:
                                                                                    '8px',
                                                                            },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            placeholder="$ Price"
                                                            variant="outlined"
                                                            value={
                                                                service?.price
                                                            }
                                                            onChange={(e) => {
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                // Check if the value is a valid integer
                                                                if (
                                                                    /^\d*$/.test(
                                                                        value,
                                                                    )
                                                                ) {
                                                                    handleServiceChange(
                                                                        index,
                                                                        'price',
                                                                        value,
                                                                    );
                                                                }
                                                            }}
                                                            error={
                                                                !!service
                                                                    ?.errors
                                                                    ?.price
                                                            }
                                                            helperText={
                                                                service?.errors
                                                                    ?.price
                                                            }
                                                            inputProps={{
                                                                inputMode:
                                                                    'numeric',
                                                                pattern:
                                                                    '[0-9]*',
                                                            }}
                                                            sx={{
                                                                bgcolor: (
                                                                    theme,
                                                                ) =>
                                                                    theme
                                                                        .palette
                                                                        .mode ===
                                                                    'dark'
                                                                        ? '#394150'
                                                                        : '#F4F4F6',
                                                                borderRadius:
                                                                    '6px',
                                                                '& .MuiOutlinedInput-root':
                                                                    {
                                                                        '& fieldset':
                                                                            {
                                                                                border: (
                                                                                    theme,
                                                                                ) =>
                                                                                    theme
                                                                                        .palette
                                                                                        .mode ===
                                                                                    'dark'
                                                                                        ? 'none'
                                                                                        : '1px solid #E5E6EB',
                                                                                borderRadius:
                                                                                    '8px',
                                                                            },
                                                                        '&:hover fieldset':
                                                                            {
                                                                                border: (
                                                                                    theme,
                                                                                ) =>
                                                                                    theme
                                                                                        .palette
                                                                                        .mode ===
                                                                                    'dark'
                                                                                        ? 'none'
                                                                                        : '1px solid #E5E6EB',
                                                                                borderRadius:
                                                                                    '8px',
                                                                            },
                                                                        '&.Mui-focused fieldset':
                                                                            {
                                                                                border: (
                                                                                    theme,
                                                                                ) =>
                                                                                    theme
                                                                                        .palette
                                                                                        .mode ===
                                                                                    'dark'
                                                                                        ? 'none'
                                                                                        : '1px solid #E5E6EB',
                                                                                borderRadius:
                                                                                    '8px',
                                                                            },
                                                                    },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                placeholder="Service details"
                                                variant="outlined"
                                                value={service.details}
                                                onChange={(e) =>
                                                    handleServiceChange(
                                                        index,
                                                        'details',
                                                        e.target.value,
                                                    )
                                                }
                                                error={
                                                    !!service?.errors?.details
                                                }
                                                helperText={
                                                    service?.errors?.details
                                                }
                                                sx={{
                                                    bgcolor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#394150'
                                                            : '#F4F4F6',
                                                    borderRadius: '6px',
                                                    '& .MuiOutlinedInput-root':
                                                        {
                                                            '& fieldset': {
                                                                border: (
                                                                    theme,
                                                                ) =>
                                                                    theme
                                                                        .palette
                                                                        .mode ===
                                                                    'dark'
                                                                        ? 'none'
                                                                        : '1px solid #E5E6EB',
                                                                borderRadius:
                                                                    '8px',
                                                            },
                                                            '&:hover fieldset':
                                                                {
                                                                    border: (
                                                                        theme,
                                                                    ) =>
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'dark'
                                                                            ? 'none'
                                                                            : '1px solid #E5E6EB',
                                                                    borderRadius:
                                                                        '8px',
                                                                },
                                                            '&.Mui-focused fieldset':
                                                                {
                                                                    border: (
                                                                        theme,
                                                                    ) =>
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'dark'
                                                                            ? 'none'
                                                                            : '1px solid #E5E6EB',
                                                                    borderRadius:
                                                                        '8px',
                                                                },
                                                        },
                                                }}
                                            />
                                        </Box>
                                    </>
                                ))}
                                <div ref={serviceEndRef}></div>
                                {showSubmitButton && (
                                    <Button
                                        sx={{
                                            backgroundColor: '#0061DB',
                                            color: 'white',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            paddingX: '10px',
                                            paddingY: '5px',
                                            '&:hover': {
                                                backgroundColor: '#004BB5',
                                            },
                                        }}
                                        size="small"
                                        onClick={submit}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </Box>

                            <Box
                                mt={5}
                                display="flex"
                                flexDirection="column"
                                alignItems="end"
                                justifyContent="space-between"
                                gap={4}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    gap={2}
                                    width="100%"
                                >
                                    <Box>
                                        {showAddServiceButton && (
                                            <Button
                                                onClick={addService}
                                                sx={{
                                                    fontWeight: '600',
                                                    color: '#0061DB',
                                                }}
                                            >
                                                + Add service
                                            </Button>
                                        )}
                                    </Box>
                                </Box>

                                <Box display="flex" gap={2}>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => onSyncAll('two')}
                                    >
                                        {'<-'} Previous
                                    </Button>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => onSyncAll('four')}
                                    >
                                        Skip
                                    </Button>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            textTransform: 'none',
                                        }}
                                        onClick={saveServices}
                                    >
                                        Next {'-->'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <InvoiceSttingPreview />
                </Grid>
            </Box>
        </>
    );
}

export default ListOfServices;
