import React from 'react';
import {
    Box,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const CustomPagination = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
    rowsPerPageOptions,
    onRowsPerPageChange,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handlePageNumberClick = (event, newPage) => {
        onPageChange(event, newPage);
    };

    const totalPages = Math.ceil(count / rowsPerPage);
    const pageNumbers = [];

    if (totalPages <= 7) {
        for (let i = 0; i < totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        pageNumbers.push(0, 1);

        let start = Math.max(2, page - 1);
        let end = Math.min(totalPages - 3, page + 1);

        if (start > 2) {
            pageNumbers.push('...');
        }

        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }

        if (end < totalPages - 3) {
            pageNumbers.push('...');
        }

        pageNumbers.push(totalPages - 2, totalPages - 1);
    }

    return (
        <Box
            sx={{
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: theme.palette.mode === 'dark' ? '#212936' : 'white',
            }}
            py={3}
        >
            {!isSmallScreen && (
                <Box display="flex" alignItems="center">
                    <Typography variant="body2" sx={{ mx: 2 }}>
                        Show result:
                    </Typography>
                    <select
                        value={rowsPerPage}
                        onChange={onRowsPerPageChange}
                        style={{
                            border: '1px solid #E5E6EB',
                            borderRadius: '8px',
                            padding: '8px',
                        }}
                    >
                        {rowsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </Box>
            )}

            <Box display="flex" alignItems="center">
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <Box display="flex" alignItems="center">
                    {pageNumbers.map((pageNumber, index) =>
                        pageNumber === '...' ? (
                            <Typography
                                key={index}
                                variant="body2"
                                sx={{ mx: 1 }}
                            >
                                ...
                            </Typography>
                        ) : (
                            <IconButton
                                key={pageNumber}
                                onClick={(event) =>
                                    handlePageNumberClick(event, pageNumber)
                                }
                                aria-label={`page ${pageNumber + 1}`}
                                sx={{
                                    color:
                                        page === pageNumber
                                            ? '#0061DB'
                                            : '#6C727F',
                                    backgroundColor:
                                        page === pageNumber
                                            ? '#CCDFF8'
                                            : 'transparent',
                                    '&:hover': {
                                        backgroundColor:
                                            page === pageNumber
                                                ? '#BCCFF9'
                                                : 'rgba(0, 0, 0, 0.08)',
                                    },
                                    borderRadius: '15%',
                                    padding: '8px 8px',
                                    margin: '0 4px',
                                }}
                            >
                                <Typography variant="body2" sx={{ mx: 1 }}>
                                    {pageNumber + 1}
                                </Typography>
                            </IconButton>
                        ),
                    )}
                </Box>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= totalPages - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
            </Box>
        </Box>
    );
};

export default CustomPagination;
