import { Grid } from '@mui/material';
import { React, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Animation from './Components/Animation';
import TransactionPopup from './Components/TransactionPopup';
import ImportTransactions from './ImportTransactions';
import ImportTransactionsSelect from './ImportTransactionsSelect';
import SyncBankAccounts from './SyncBankAccounts';

const ImportTransaction = () => {
    const transactionType = useSelector((state) => state.me.transactionType);
    const [syncStatus, setSyncStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [bank, setBank] = useState(false);
    const { accounts, loading } = useSelector((state) => state.bank);
    const [dialogOpen, setDialogOpen] = useState(true);
    const [importTransaction, setImportTransaction] = useState(false);
    const [result, setResult] = useState([]);

    const handleSyncAll = useCallback(
        (id, b, transactionType) => {
            const results = accounts?.map((item) => ({
                id:
                    transactionType === 'IMPORT' ||
                    transactionType === 'INITIAL'
                        ? item?.account_id
                        : item?.id,
                name: item.name,
            }));
            setResult(results);
            setSyncStatus(id);
            if (id === 0 && b === 'bank') {
                setBank(true);
            }
        },
        [accounts],
    );

    const handlePopup = useCallback(() => {
        setBank(false);
    }, []);

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false);
        setImportTransaction(false);
    }, []);

    return (
        <>
            <Grid
                item
                xs={12}
                p={2}
                borderRadius={3}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                {syncStatus === 0 ? (
                    <SyncBankAccounts
                        onSyncAll={handleSyncAll}
                        setImportTransaction={setImportTransaction}
                        setIsLoading={setIsLoading}
                        setSyncStatus={setSyncStatus}
                    />
                ) : (
                    <ImportTransactionsSelect
                        accounts={result}
                        accounts2={accounts}
                        setIsLoading={setIsLoading}
                        setSyncStatus={setSyncStatus}
                    />
                )}

                {isLoading && <Animation />}
                {bank && <TransactionPopup onPopup={handlePopup} />}
                {importTransaction && (
                    <ImportTransactions
                        onSyncAll={handleSyncAll}
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        setImportTransaction={setImportTransaction}
                        setIsLoading={setIsLoading}
                    />
                )}
            </Grid>
        </>
    );
};

export default ImportTransaction;
