import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReconciliationTotalsRequest } from 'store/actions';
import { formatCurrencyAmount } from 'utils/helpers';
import AccountReconcile from './AccountReconcile';
import BalanceSummary from './BalanceSummary';
import ContinueReconcile from './ContinueReconcile';
import MatchedReconciliation from './MatchedReconciliation';
import UnmatchedReconciliation from './UnmatchedReconciliation';

export const BankReconciliation = () => {
    const [syncStatus, setSyncStatus] = React.useState('one');
    const [reconcileAccount, setReconcileAccount] = useState('');
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const reconcileBalanceSummary = useSelector(
        (state) => state.bank.reconcileBalance,
    );

    const handleSyncAll = (status, account) => {
        setReconcileAccount(account);
        setSyncStatus(status);
    };

    const tabsData = [{ label: 'Unmatched' }, { label: 'Matched' }];

    const [selectedTab, setSelectedTab] = useState(0);
    const changeTab = (tab, index) => {
        setSelectedTab(index);
    };

    useEffect(() => {
        if (reconcileAccount?.id) {
            dispatch(
                fetchReconciliationTotalsRequest({
                    bankAccountId: reconcileAccount?.id,
                    companyId: 89,
                    token,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reconcileAccount?.id]);

    return (
        <>
            {syncStatus === 'one' || syncStatus === 'two' ? (
                <Grid
                    item
                    xs={12}
                    p={2}
                    borderRadius={3}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    {syncStatus === 'one' ? (
                        <AccountReconcile onSyncAll={handleSyncAll} />
                    ) : syncStatus === 'two' ? (
                        <ContinueReconcile
                            onSyncAll={handleSyncAll}
                            account={reconcileAccount}
                        />
                    ) : (
                        ''
                    )}
                </Grid>
            ) : (
                ''
            )}

            {syncStatus === 'three' ? (
                <Grid container xs={12} p={2}>
                    <Grid
                        marginBottom={5}
                        item
                        xs={12}
                        sm={4}
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: { xs: 4, sm: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : 'white',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            }}
                            px={1}
                        >
                            {tabsData?.map((tab, index) => (
                                <Button
                                    key={index}
                                    onClick={() => changeTab(tab, index)}
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '15px',
                                        fontWeight:
                                            selectedTab === index
                                                ? '600'
                                                : '500',
                                        backgroundColor:
                                            selectedTab === index
                                                ? '#CEEFDF'
                                                : 'transparent',
                                        borderRadius:
                                            selectedTab === index
                                                ? '3px'
                                                : 'none',
                                        margin: '8px 0px',
                                        color:
                                            selectedTab === index
                                                ? '#0A8C4D'
                                                : '#6C727F',
                                        '&:hover': {
                                            color:
                                                selectedTab === index
                                                    ? '#0A8C4D'
                                                    : '',
                                        },
                                        variant:
                                            selectedTab === index
                                                ? 'contained'
                                                : 'text',
                                    }}
                                >
                                    {tab.label}
                                </Button>
                            ))}
                        </Box>
                        {/* <Box display="flex" gap={5}>
                            <Box
                                sx={{
                                    textAlign: { sx: 'start', sm: 'end' },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#6C727F',
                                    }}
                                >
                                    Bank Account Balance
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#121826',
                                    }}
                                >
                                    ${' '}
                                    {
                                        reconciliationTotals?.plaid_transactions_total
                                    }
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: { sx: 'start', sm: 'end' } }}>
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#6C727F',
                                    }}
                                >
                                    Circler Account Balance
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#6C727F',
                                    }}
                                >
                                    ${' '}
                                    {
                                        reconciliationTotals?.bookkeeper_transactions_total
                                    }
                                </Typography>
                            </Box>
                        </Box> */}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <BalanceSummary
                            bank_reconciliation_id={
                                reconcileBalanceSummary?.bank_reconciliation_id
                            }
                            bankBalance={formatCurrencyAmount(
                                reconcileBalanceSummary?.bank_ending_balance ||
                                    0,
                            )}
                            beginningBalance={formatCurrencyAmount(
                                reconcileBalanceSummary?.bank_beginning_balance ||
                                    0,
                            )}
                            circlerBalance={formatCurrencyAmount(
                                reconcileBalanceSummary?.circler_ending_balance ||
                                    0,
                            )}
                            currentMonthTransactions={formatCurrencyAmount(
                                reconcileBalanceSummary?.transactions_total ||
                                    0,
                            )}
                            difference={formatCurrencyAmount(
                                (reconcileBalanceSummary?.bank_ending_balance ||
                                    0) -
                                    (reconcileBalanceSummary?.circler_ending_balance ||
                                        0),
                            )}
                            realDifference={
                                !!(
                                    (reconcileBalanceSummary?.bank_ending_balance ||
                                        0) -
                                    (reconcileBalanceSummary?.circler_ending_balance ||
                                        0)
                                )
                            }
                            setSyncStatus={setSyncStatus}
                        />
                    </Grid>
                    {selectedTab === 0 ? (
                        <UnmatchedReconciliation
                            accountId={reconcileAccount?.id}
                        />
                    ) : (
                        <MatchedReconciliation
                            accountId={reconcileAccount?.id}
                        />
                    )}
                </Grid>
            ) : (
                ''
            )}
        </>
    );
};
