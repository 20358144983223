import { Box, Typography } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from 'assets/icons/Circler Loading Animation.json';

function Animation() {
    return (
        <Box
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: '#1A202C99',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 99900000,
            }}
        >
            <Box
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                p={4}
                borderRadius={4}
            >
                <Lottie
                    loop
                    animationData={animationData}
                    play
                    style={{ width: 150, height: 150 }}
                />
                <Typography sx={{ fontWeight: 'bold', fontSize: '25px' }}>
                    Downloading your <br /> bank activities
                </Typography>
                <Typography sx={{ color: '#9EA3AE' }}>
                    This typically takes a few minutes. Please do not <br />{' '}
                    close or refresh this tab.
                </Typography>
            </Box>
        </Box>
    );
}

export default Animation;
