import { useState } from 'react';
import {
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
    Link,
    Fade,
    FormHelperText,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Image from 'mui-image';
import CirclerImage from '../assets/icons/Content-Left.svg';
import { ReactComponent as CirclerLogo } from '../assets/icons/circler-logo.svg';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';
import { TextField, Button, CheckboxSelection } from '../shared';
import GoogleIcon from '../assets/icons/google-icon.png';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { useThemeToggle } from '../hooks/useThemeToggle';
import {
    SIGN_UP_PENDING,
    SIGN_UP_SUCCESS,
    SIGN_UP_ERROR,
    SAVE_TOKEN,
} from '../store/actions';
import { useAuthService } from '../store/services/auth.service';
import * as Yup from 'yup';

const styles = {
    container: {
        height: '100vh',
        overflow: 'hidden',
    },
    mainHeading: {
        fontSize: '32px',
        fontWeight: '700',
        color: (theme) => theme.textColor.dark,
        lineHeight: '38px',
    },
    subHeading: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#6C727F',
        lineHeight: '24px',
        mt: 0.5,
    },

    forgotPassword: {
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 500,
    },
    loginBtnStyle: {
        borderRadius: '8px',
        fontSize: '18px',
        fontWeight: 500,
        height: '48px',
    },
    otherSignUpOptionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    otherSignUpOptionsText: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
    },
    signUpOption: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '500',
    },
    googleSignUpOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '500',
    },
    otherSignUpOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '400',
    },
    link: {
        textDecoration: 'none',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        cursor: 'pointer', // Optional: Change cursor on hover,
        fontWeight: 600,
        fontSize: '16px',
    },
    termsAndCondition: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: (theme) => theme.textColor.dark,
    },
};

export const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { signup, isLoading } = useAuthService();

    const [isTermsAccepted, setIsTermsAccepted] = useState(true);
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [registerData, setRegisterData] = useState({
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
    });

    const RegisterSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email must be a valid email address'),
        confirmEmail: Yup.string().oneOf(
            [Yup.ref('email'), null],
            'Email must match',
        ),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match',
        ),
    });

    const { themeMode } = useThemeToggle();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const handleCheckboxChange = (event) => {
        setIsTermsAccepted(event.target.checked);
    };

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible((prev) => !prev);
    };

    const handleChangeRegistration = async (e) => {
        const { name, value } = e.target;
        setRegisterData((previous) => ({ ...previous, [name]: value }));

        if (formErrors?.[name]) {
            try {
                await RegisterSchema.validateAt(name, {
                    ...registerData,
                    [name]: value,
                });
                setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            } catch (validationError) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: validationError?.message,
                }));
            }
        }
    };

    const handleSignup = async (e) => {
        if (!isTermsAccepted) {
            setError(
                'You must agree to the Terms and Conditions and Privacy Policy.',
            );
            return;
        }

        setError('');
        try {
            await RegisterSchema.validate(registerData, { abortEarly: false });
            dispatch(SIGN_UP_PENDING());
            signup(
                registerData.email.toLowerCase(),
                registerData.password,
                // registerData.phoneNumber,
            )
                .then((res) => {
                    dispatch(SIGN_UP_SUCCESS());
                    dispatch(SAVE_TOKEN({ token: res.token }));
                    navigate('/onboarding');
                    // setShowConfirmation(true);
                })
                .catch((err) => {
                    dispatch(SIGN_UP_ERROR({ error: err.errorMessage }));
                    setError(err.errorMessage);
                });
        } catch (validationErrors) {
            let errors = {};
            validationErrors.inner.forEach((error) => {
                // Capture the field name (key) and the message
                errors[error.path] = error.message;
            });
            console.log(errors);
            setFormErrors(errors);
        }
    };

    return (
        <Grid
            flex={{ xs: '1', sm: '2', md: '3' }}
            container
            sx={{
                ...styles.container,
                overflow: isScreenLowerThan1024 ? 'auto' : 'hidden',
            }}
        >
            <Grid
                item
                position="relative"
                xs={12}
                md={6}
                lg={6}
                maxWidth={'700px'}
                display={{ xs: 'none', md: 'block', lg: 'block' }}
                overflow={{ xs: 'scroll', md: 'hidden', lg: 'hidden' }}
            >
                <Image alt="Left Content" src={CirclerImage} />
            </Grid>
            <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                flex="1"
                alignContent="flex-start"
                justifyContent={'center'}
                px={{ xs: 2, sm: 4, md: 8 }}
                pt="50px"
                sx={{
                    overflow: 'auto',
                    backgroundColor: (theme) => theme.backgroundColor.primary,
                }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    maxWidth={'700px'}
                    padding={isScreenLowerThan1024 ? '24px 0' : '0 10%'}
                    gap={2}
                    textAlign={'start'}
                >
                    <Grid item>
                        {themeMode === 'dark' ? (
                            <DarkCirclerLogo
                                width="180.995px"
                                height="45.22px"
                            />
                        ) : (
                            <CirclerLogo width="180.995px" height="45.22px" />
                        )}
                    </Grid>
                    {showConfirmation ? (
                        <>
                            <Grid item>
                                <Typography
                                    sx={{
                                        ...styles.mainHeading,
                                        marginBottom: '10px',
                                    }}
                                >
                                    Confirm your account
                                </Typography>
                                <Typography sx={styles.subHeading}>
                                    A confirmation email has been sent to your
                                    email address. Please activate your account.
                                </Typography>
                            </Grid>
                            {/* <Grid item>
                                <TextField
                                    name="verificationCode"
                                    placeholder="Enter verification code"
                                    type="text"
                                    value={verificationCode}
                                    onChange={(e) => {
                                        const inputText = e.target.value;
                                        const numericInput = inputText.replace(
                                            /\D/g,
                                            '',
                                        ); // Remove non-numeric characters
                                        setVerificationCode(numericInput);
                                    }}
                                />
                            </Grid> */}
                            <Grid item>
                                <Button
                                    text={
                                        <Typography sx={styles.signUpOption}>
                                            Sign In
                                        </Typography>
                                    }
                                    fullWidth
                                    customStyles={styles.loginBtnStyle}
                                    onClick={() => {
                                        navigate('/login');
                                    }}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item>
                                <Typography sx={styles.mainHeading}>
                                    Create an account
                                </Typography>
                                <Typography sx={styles.subHeading}>
                                    Please enter your details to sign up.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    name="email"
                                    placeholder="Email *"
                                    type="text"
                                    value={registerData.email}
                                    onChange={handleChangeRegistration}
                                />
                                {formErrors?.email && (
                                    <FormHelperText error>
                                        <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: 12,
                                                fontWeight: 500,
                                            }}
                                        >
                                            {formErrors?.email}
                                        </Typography>
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Fade in={!!registerData.email} unmountOnExit>
                                <Grid item>
                                    <TextField
                                        name="confirmEmail"
                                        placeholder="Confirm Email *"
                                        type="text"
                                        value={registerData.confirmEmail}
                                        onChange={handleChangeRegistration}
                                    />
                                    {formErrors?.confirmEmail && (
                                        <FormHelperText error>
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {formErrors?.confirmEmail}
                                            </Typography>
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Fade>

                            {/* TODO: Not part of MVP
                            <Grid item>
                                <PhoneNumber
                                    onChange={handleChangeRegistration}
                                    value={registerData.phoneNumber}
                                />
                            </Grid> */}
                            <Grid item>
                                <TextField
                                    name="password"
                                    placeholder="Password *"
                                    value={registerData.password}
                                    passwordVisible={passwordVisible}
                                    type="password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleTogglePasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {passwordVisible ? (
                                                        <VisibilityOutlinedIcon fontSize="small" />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleChangeRegistration}
                                />
                                {formErrors?.password && (
                                    <FormHelperText error>
                                        <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: 12,
                                                fontWeight: 500,
                                            }}
                                        >
                                            {formErrors?.password}
                                        </Typography>
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Fade in={!!registerData.password} unmountOnExit>
                                <Grid item>
                                    <TextField
                                        name="confirmPassword"
                                        placeholder="Confirm Password *"
                                        value={registerData.confirmPassword}
                                        passwordVisible={passwordVisible}
                                        type="password"
                                        onChange={handleChangeRegistration}
                                    />
                                    {formErrors?.confirmPassword && (
                                        <FormHelperText error>
                                            <Typography
                                                sx={{
                                                    color: 'red',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {formErrors?.confirmPassword}
                                            </Typography>
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Fade>
                            <Grid container item justifyContent="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        sx={{ padding: 0 }}
                                        control={
                                            <CheckboxSelection
                                                sx={styles.rememberMeChkBox}
                                                color="primary"
                                                onChange={handleCheckboxChange}
                                                checked={isTermsAccepted}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={styles.termsAndCondition}
                                            >
                                                Agree to the&nbsp;
                                                <Link
                                                    href="https://www.termsfeed.com/live/2c8418f9-830b-4917-95c9-1a30f68a8c7a"
                                                    target="_blank"
                                                    sx={styles.link}
                                                >
                                                    Terms and Conditions
                                                </Link>{' '}
                                                and{' '}
                                                <Link
                                                    href="https://www.termsfeed.com/live/c453ce2a-a7f6-4fd4-8956-efc53c6f2510"
                                                    target="_blank"
                                                    sx={styles.link}
                                                >
                                                    Privacy Policy.
                                                </Link>
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {error && (
                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: 'red',
                                            fontSize: 16,
                                            fontWeight: 500,
                                        }}
                                    >
                                        {error}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    text={
                                        <Typography sx={styles.signUpOption}>
                                            Sign Up
                                        </Typography>
                                    }
                                    fullWidth
                                    loading={isLoading}
                                    customStyles={styles.loginBtnStyle}
                                    onClick={handleSignup}
                                />
                            </Grid>

                            {/* google sign */}
                            {/* <Grid
                                container
                                item
                                justifyContent="space-between"
                                sx={styles.otherSignUpOptionsContainer}
                            >
                                <Grid
                                    item
                                    width={{ xs: '20%', md: '15%', lg: '30%' }}
                                >
                                    <Divider
                                        sx={{
                                            background: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#E5E6EB',
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={styles.otherSignUpOptionsText}
                                    >
                                        Or sign up with
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    width={{ xs: '20%', md: '15%', lg: '30%' }}
                                >
                                    <Divider
                                        sx={{
                                            background: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#E5E6EB',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    text={
                                        <Typography
                                            sx={styles.googleSignUpOption}
                                        >
                                            Google
                                        </Typography>
                                    }
                                    fullWidth
                                    variant="outlined"
                                    customStyles={{
                                        ...styles.loginBtnStyle,
                                        backgroundColor: 'transparent',
                                        color: '#121826',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                    }}
                                    startIcon={
                                        <img
                                            src={GoogleIcon}
                                            alt="Google icon"
                                            height={'22px'}
                                            width={'22px'}
                                        />
                                    }
                                />
                            </Grid> */}

                            <Grid item textAlign={'center'}>
                                <Typography sx={styles.otherSignUpOption}>
                                    Already have an account?&nbsp;
                                    <Link href="/login" sx={styles.link}>
                                        Sign in
                                    </Link>
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
