import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const DeleteButton = styled(Button)({
    backgroundColor: '#FF6347',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#e5533d',
    },
});

const CancelButton = styled(Button)({
    backgroundColor: '#f4f4f4',
    color: '#333',
    '&:hover': {
        backgroundColor: '#e0e0e0',
    },
});

const ConfirmDialog = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                <Typography variant="h6" fontWeight="bold">
                    Delete
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#FFFFFF'
                                : '#000000',
                    }}
                >
                    Are you sure you want to delete?
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        padding: '0 24px 24px 24px',
                    }}
                >
                    <DeleteButton
                        onClick={onConfirm}
                        sx={{
                            marginRight: 1,
                            textTransform: 'none',
                            borderRadius: 2,
                        }}
                    >
                        Delete
                    </DeleteButton>
                    <CancelButton
                        onClick={onClose}
                        sx={{ textTransform: 'none', borderRadius: 2 }}
                    >
                        Cancel
                    </CancelButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
