import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { SET_SELECTED_ITEM } from 'store/actions';
import { useDispatch } from 'react-redux';

function TransactionPopup({ onPopup }) {
    const dispatch = useDispatch();
    const reconcile = () => {
        dispatch(SET_SELECTED_ITEM('Bank reconciliation'));
        onPopup();
    };
    return (
        <Box
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: '#1A202C99',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 99900000,
            }}
        >
            <Box
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    textAlign: 'center',
                }}
                p={4}
                pt={0}
                borderRadius={4}
            >
                <img
                    src="https://i.gifer.com/7efs.gif"
                    alt=""
                    srcset=""
                    width="250px"
                    height="180px"
                />
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '25px',
                        lineHeight: '22px',
                    }}
                >
                    Your transactions have <br /> been imported.
                </Typography>
                <Typography
                    my={2}
                    lineHeight={1.3}
                    sx={{ fontSize: '13px', color: '#6C727F' }}
                >
                    Your last transactions are available to <br /> match or
                    manage. Now you can match <br /> them through{' '}
                    <span style={{ color: '#0061DB', fontWeight: 'bold' }}>
                        {' '}
                        Bank Reconciliation.
                    </span>
                </Typography>

                <Button
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        paddingX: '20px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#0056b3',
                            '@media (hover: hover)': {
                                backgroundColor: '#0056b3',
                            },
                        },
                    }}
                    onClick={reconcile}
                >
                    Go to Bank Reconciliation
                </Button>
            </Box>
        </Box>
    );
}

export default TransactionPopup;
