import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import LoadingGif from '../assets/icons/CirclerLoader.gif';
import LoadingTextGif from '../assets/icons/CirclerLoaderText.gif';
import GifPlayer from 'react-gif-player';
import Typography from '@mui/material/Typography'; // Import Typography for text styling

export const LoaderOnboarding = ({
    showTextLoader = false,
    loaderText = '',
}) => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: 'flex', // Ensure content is centered
                flexDirection: 'column', // Stack items vertically
                alignItems: 'center', // Center items horizontally
                justifyContent: 'center', // Center items vertically
            }}
            open={true}
        >
            <GifPlayer
                gif={showTextLoader ? LoadingTextGif : LoadingGif}
                autoplay={true}
            />
            {showTextLoader && (
                <div
                    style={{
                        fontSize: '20px',
                        fontWeight: 600,
                        color: 'darkgrey',
                        fontFamily: 'Inter, sans-serif',
                    }}
                >
                    {loaderText || ''}
                </div>
            )}
        </Backdrop>
    );
};
