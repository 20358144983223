import {
    Checkbox,
    FormControlLabel,
    IconButton,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import {
    distributeTaxAmongTaxed,
    formatAccountTitle,
    formatCurrencyAmount,
} from 'utils/helpers';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';
import { useEffect } from 'react';

const DropDownTableRow = ({
    themeMode,
    row,
    hasTax,
    index,
    handleTaxClick,
    handleTaxChangeCheckbox,
    handleEditClickSecondTable,
    editIdSecondTable,
    rowIndex,
    handleRemoveTransactionItem,
    parentRow,
    isEdit,
    setData,
}) => {
    // useEffect(() => {
    //     console.log(parentRow);
    //     setData((previousData) =>
    //         previousData?.map((item, indexNew) =>
    //             indexNew === rowIndex
    //                 ? {
    //                       ...item,
    //                       transaction_items: distributeTaxAmongTaxed(
    //                           item?.transaction_items,
    //                       ),
    //                   }
    //                 : item,
    //         ),
    //     );
    // }, [parentRow?.transaction_items, rowIndex, setData]);
    // Define isTaxFlags outside of the useEffect
    const isTaxFlags = parentRow?.transaction_items?.map((item) => ({
        isThisTax: item?.isThisTax,
        isTaxed: item?.isTaxed,
    }));

    useEffect(() => {
        setData((previousData) =>
            previousData?.map((item, indexNew) =>
                indexNew === rowIndex
                    ? {
                          ...item,
                          transaction_items: distributeTaxAmongTaxed(
                              item?.transaction_items,
                          ),
                      }
                    : item,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(isTaxFlags), rowIndex, setData]);

    return (
        <TableRow
            className={
                editIdSecondTable === index ? 'dropDownTableRowDesktop' : ''
            }
            sx={{
                backgroundColor: themeMode === 'dark' ? '#323A46' : '#F9FAFB',
                cursor: isEdit ? 'pointer' : 'default',
            }}
            onClick={() =>
                isEdit &&
                handleEditClickSecondTable(
                    row,
                    index,
                    editIdSecondTable,
                    parentRow,
                )
            }
        >
            <TableCell>
                <Typography>{row?.description}</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
                <Typography>
                    {formatAccountTitle(
                        parentRow?.book_by_each_item
                            ? row?.account
                            : parentRow?.account,
                    )}
                </Typography>
            </TableCell>
            {/* <TableCell>
                <Typography noWrap>
                    ${' '}
                    {formatCurrencyAmount(
                        row?.isThisTax
                            ? 0
                            : (+row?.price || 0) +
                                  (row?.taxAmount || 0) / row?.quantity,
                    )}
                </Typography>
            </TableCell> */}
            {/* <TableCell
                sx={{
                    textAlign: 'center',
                }}
            >
                <Typography>{parseInt(row?.quantity || 0)}</Typography>
            </TableCell> */}
            <TableCell>
                <Typography>
                    ${' '}
                    {formatCurrencyAmount(
                        row?.isThisTax
                            ? 0
                            : (+row?.item_total || 0) + (row?.taxAmount || 0),
                    )}
                </Typography>
            </TableCell>
            {isEdit && (
                <TableCell>
                    <Typography>
                        <FormControlLabel
                            key={index}
                            onClick={(e) => e.stopPropagation()}
                            control={
                                hasTax === index || hasTax === null ? (
                                    <Checkbox
                                        disabled={!isEdit}
                                        size="small"
                                        checked={row?.isThisTax}
                                        onChange={(event) =>
                                            handleTaxClick(event, index)
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                ) : (
                                    <Checkbox
                                        disabled={!isEdit}
                                        size="small"
                                        checked={row?.isTaxed}
                                        onChange={(event) =>
                                            handleTaxChangeCheckbox(
                                                event,
                                                index,
                                            )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                )
                            }
                            label={
                                hasTax === index || hasTax === null ? (
                                    <Typography sx={{ userSelect: 'none' }}>
                                        <i>This is tax to allocate</i>
                                    </Typography>
                                ) : (
                                    <Typography sx={{ userSelect: 'none' }}>
                                        $ {formatCurrencyAmount(row?.taxAmount)}
                                    </Typography>
                                )
                            }
                        />
                    </Typography>
                </TableCell>
            )}
            <TableCell>
                {isEdit && (
                    <IconButton
                        onClick={(e) => {
                            e?.stopPropagation();
                            handleRemoveTransactionItem(rowIndex, index, false);
                        }}
                    >
                        <Trash />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
};

export default DropDownTableRow;
