import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Paper } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';
import { ReactComponent as CirclerLogo } from '../assets/icons/Logo.svg';
import { useAuth } from '../hooks/useAuth';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { Button, TextField, Typography } from '../shared';
import { LoaderOnboarding } from '../shared/LoaderOnboarding';
import { PhoneNumber } from '../shared/PhoneNumber';
import { Progress } from '../shared/Progress';
import { SET_CURRENT_USER_SUCCESS } from '../store/actions';
import { useAuthService } from '../store/services/auth.service';
import { useCompanyService } from '../store/services/company.service';
import {
    CREATE_COMPANY_ERROR,
    CREATE_COMPANY_PENDING,
    CREATE_COMPANY_SUCCESS,
} from '../store/slices/company.slice';
import { CompanyInformation } from './Settings/CompanyInformation';

const makeStyles = ({ step, isScreenLowerThan1024 }) => ({
    onboardingContainer: {
        p: '28px 0',
        justifyContent: 'center',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    progressStyle: {
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: (theme) =>
                theme.backgroundColor.uploadFileContainer,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: (theme) => theme.progress.main,
        },
    },
    onboardingContent: {
        display: 'flex',
        m: 'auto',
        p: isScreenLowerThan1024 ? '40px 20px' : '80px 20px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '800px',
        // height: isScreenLowerThan1024 ? '100%' : step === 1 ? '85vh' : 'auto',
    },
    step: {
        p: '32px',
        boxShadow: '10px 15px 50px 0px rgba(113, 128, 150, 0.24)',
        borderRadius: '12px',
        border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        backgroundColor: (theme) => theme.backgroundColor.primary,
        '& input': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : 'inherit', //(theme) => theme.backgroundColor.primary,
            borderRadius: 1,
        },
    },
});

function Onboarding() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [step, setStep] = useState(1);
    const [userData, setUserData] = useState({});
    const { updateUser } = useAuthService();
    const { themeMode } = useThemeToggle();
    const { createCompany, isLoading } = useCompanyService();
    const [progress, setProgress] = useState(0);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [onBoardingUser, setOnBoardingUser] = useState({
        firstName: user.data.first_name,
        lastName: user.data.last_name,
        phoneNumber: user.data.phone_number,
    });
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const is_onboarded = useSelector((state) => state.me.data.is_onboarded);

    const styles = makeStyles({
        step,
        isScreenLowerThan1024,
    });

    useEffect(() => {
        if (location.state?.companyInfo) {
            setCompanyInfo(location.state.companyInfo);
        }
        if (location.state?.userData) {
            setUserData(location.state.userData);
        }
    }, [location]);

    useEffect(() => {
        if (is_onboarded) {
            // navigate('/subscriptions');
            navigate('/dashboard');
        }
    }, [is_onboarded, navigate]);

    const handleUserChange = useCallback(
        (e) => {
            setOnBoardingUser({
                ...onBoardingUser,
                [e.target.name]: e.target.value,
            });
            setUserData(onBoardingUser);
        },
        [onBoardingUser],
    );

    const handleCompanyChange = useCallback(
        (company) => {
            setCompanyInfo({ ...companyInfo, ...company });
        },
        [companyInfo],
    );

    const handleFinishBoardingProcess = useCallback(async () => {
        if (step === 2) {
            if (!is_onboarded) {
                setShowLoader(true);
                try {
                    dispatch(CREATE_COMPANY_PENDING());
                    // TODO: Need to dispatch actions of user well
                    const [updateUserResponse, companyResponse] =
                        await Promise.all([
                            updateUser({
                                first_name: onBoardingUser.firstName,
                                last_name: onBoardingUser.lastName,
                                phone_number: onBoardingUser.phoneNumber,
                            }),
                            createCompany(
                                companyInfo.name,
                                companyInfo.industry.value,
                                companyInfo.entity.value,
                                companyInfo.timezone.value,
                            ),
                        ]);
                    dispatch(
                        CREATE_COMPANY_SUCCESS({
                            data: {
                                ...companyResponse,
                                timezone: {
                                    label: companyResponse.timezone,
                                    value: companyResponse.timezone,
                                },
                                industry: {
                                    label: companyInfo.industry.label,
                                    value: companyInfo.industry.label,
                                },
                                entity: {
                                    label: companyResponse.entity,
                                    value: companyResponse.entity,
                                },
                            },
                        }),
                    );
                    const updatedUserResponse = {
                        ...updateUserResponse,
                        is_onboarded: true, // Manually set is_onboarded to true
                    };

                    dispatch(
                        SET_CURRENT_USER_SUCCESS({ data: updatedUserResponse }),
                    );

                    navigate('/dashboard');
                    // Show loader and navigate after both updateUser and createCompany are done
                    // setShowLoader(true);
                    // setTimeout(() => {
                    //     navigate('/home');
                    // }, 4000);
                } catch (error) {
                    // Handle errors here
                    console.error('Error:', error);
                    dispatch(CREATE_COMPANY_ERROR({ err: error }));
                } finally {
                    setShowLoader(false); // Hide the loader regardless of the outcome
                }
            } else {
                navigate('/dashboard');
            }
        }
    }, [
        companyInfo?.entity?.value,
        companyInfo?.industry?.label,
        companyInfo?.industry?.value,
        companyInfo?.name,
        companyInfo?.timezone?.value,
        createCompany,
        dispatch,
        is_onboarded,
        navigate,
        onBoardingUser?.firstName,
        onBoardingUser?.lastName,
        onBoardingUser?.phoneNumber,
        step,
        updateUser,
    ]);

    if (showLoader) {
        return (
            <LoaderOnboarding
                showTextLoader={true}
                loaderText="Circler is preparing your chart of accounts"
            />
        );
    }
    return (
        <Grid container sx={styles.onboardingContainer}>
            <Grid item>
                {themeMode === 'dark' ? (
                    <DarkCirclerLogo width="180.995px" height="45.22px" />
                ) : (
                    <CirclerLogo width="180.995px" height="45.22px" />
                )}
            </Grid>
            <Grid container p={1}>
                <Grid item xs={12}>
                    <Progress
                        progress={progress}
                        customStyle={styles.progressStyle}
                    />
                </Grid>
            </Grid>
            <Grid container sx={styles.onboardingContent}>
                <Grid item alignContent={'center'} display={'flex'}>
                    <Typography
                        text={'Welcome to Circler!'}
                        customStyles={{ fontSize: 32, fontWeight: 700 }}
                    />
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent={'space-around'}
                >
                    {step !== 1 && (
                        <Grid
                            item
                            alignContent={'center'}
                            display={'flex'}
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <ArrowBackIosIcon
                                onClick={(e) => {
                                    e.preventDefault();
                                    setProgress(0);
                                    setStep((prevStep) =>
                                        prevStep === 2
                                            ? prevStep - 1
                                            : prevStep - 2,
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item textAlign={'center'} display={'flex'}>
                        <Typography
                            text={
                                step === 1
                                    ? 'Tell us about yourself so we can personalize your experience.'
                                    : 'Tell us about your company so we can personalize your experience.'
                            }
                            customStyles={{
                                fontSize: 18,
                                fontWeight: 500,
                                color: '#6C727F',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item pt={3} width={'100%'}>
                    <Paper elevation={2} sx={styles.step}>
                        {step === 1 ? (
                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        value={onBoardingUser.firstName}
                                        label="First name"
                                        placeholder="Enter first name"
                                        name={'firstName'}
                                        customLabelStyles={{
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                        onChange={handleUserChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        value={onBoardingUser.lastName}
                                        label="Last name"
                                        placeholder="Enter last name"
                                        name={'lastName'}
                                        customLabelStyles={{
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                        onChange={handleUserChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PhoneNumber
                                        fullWidth
                                        value={onBoardingUser.phoneNumber}
                                        label="Phone number"
                                        placeholder="Enter phone number"
                                        name={'phoneNumber'}
                                        onChange={handleUserChange}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                container
                                spacing={2}
                                xs={12}
                                sx={{ p: 0 }}
                            >
                                <CompanyInformation
                                    showIndustry={true}
                                    companyLabel="Company Name"
                                    timezoneLabel={'Time Zone'}
                                    industryLabel={'Industry'}
                                    typeOfEntityLabel={'Type of Entity'}
                                    isOnboardingFlow={true}
                                    onCompanyChange={handleCompanyChange}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} pt={3}>
                            <Button
                                text={
                                    <Typography
                                        text={
                                            step === 2 || step === 3
                                                ? 'Finish'
                                                : 'Next'
                                        }
                                        customStyles={{
                                            color: '#FFFFFF',
                                            fontSize: '18px',
                                            fontWeight: 500,
                                        }}
                                    />
                                }
                                fullWidth
                                loading={isLoading}
                                onClick={() => {
                                    setStep((prevStep) => prevStep + 1);
                                    setProgress(
                                        (prevProgress) => prevProgress + 50,
                                    );
                                    handleFinishBoardingProcess();
                                }}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Onboarding;
