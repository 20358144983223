import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    Snackbar,
    Table,
    TableBody,
    Typography,
    useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    appendInvoiceNewService,
    fetchInvoiceByIdRequest,
    fetchInvoiceLogoRequest,
    fetchServicesRequest,
    serviceInvoiceRequest,
    setInvoiceServices,
    setInvoiceTotals,
    updateInvoiceRequest,
} from 'store/actions';
import {
    calculateTax,
    changeServicesTax,
    formatDateService,
    formatServices,
} from 'utils/helpers';
import AddServiceModal from './AddServiceinvoice/AddServiceModal';
import InvoiceHeader from './AddServiceinvoice/InvoiceHeader';
import ServiceInfoHeader from './AddServiceinvoice/ServiceInfoTableHeader';
import ServiceItemRow from './AddServiceinvoice/ServiceItemRow';

function AddServicesInvoice({ onSyncAll, setPayload }) {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.company.data);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(true);
    const [isNextButtonPressed, setIsNextButtonPressed] = useState(false);
    const [isNextButtonPressed2, setIsNextButtonPressed2] = useState(false);
    const [serviceItem, setServiceItem] = useState({
        serviceId: null,
        name: '',
        qty: 1,
        item: 'Item',
        unitPrice: 1,
        tax: 0,
        total: 0,
        taxed: [],
        details: '',
        company: data?.id,
        errors: { name: '', unitPrice: '', qty: '', details: '' },
    });
    const invoiceId = useSelector((state) => state.me.invoiceId);
    const theme = useTheme();

    const invoiceTolbarTax = useSelector(
        (state) => state?.invoice?.singleInvoice?.taxes || [],
    );
    const client = useSelector((state) => state.invoice.client);

    const services = useSelector((state) => state.invoice.invoiceServices);
    const invoiceTotals = useSelector((state) => state.invoice.invoiceTotals);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const invoiceType = useSelector((state) => state.me.invoiceType);
    const recurringData = useSelector((state) => state.me.recurringData);
    const dropDownServices = useSelector(
        (state) => state?.invoice?.dropDownServices || [],
    );
    const isLoading = useSelector((state) => state?.invoice?.loading);
    const invoiceLogo = useSelector((state) => state.invoice.invoiceLogo);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setIsEdit(false);
        setServiceItem({
            serviceId: null,
            name: '',
            qty: 1,
            item: 'Item',
            unitPrice: 1,
            total: 0,
            taxed: [],
            details: '',
            company: data?.id,
            errors: { name: '', unitPrice: '', qty: '', details: '' },
        });
    }, [data?.id]);

    const validateService = useCallback(() => {
        let valid = true;
        const errors = {
            name: !serviceItem.name ? 'Name is required' : '',
            unitPrice: !serviceItem.unitPrice
                ? 'Price is required'
                : isNaN(serviceItem.unitPrice)
                ? 'Price must be a number'
                : serviceItem?.unitPrice <= 0
                ? 'Price must be greater than 0'
                : '',
            qty: !serviceItem?.qty
                ? 'Quantity is required'
                : isNaN(serviceItem?.qty)
                ? 'Quantity must be a number'
                : serviceItem?.qty <= 0
                ? 'Quantity must be greater than 0'
                : '',
            details: !serviceItem.details ? 'Details are required' : '',
        };
        if (errors?.name || errors?.unitPrice || errors?.qty || errors?.details)
            valid = false;
        setServiceItem((prev) => ({ ...prev, errors }));
        return valid;
    }, [serviceItem]);

    const handleServiceNameChange = useCallback((event) => {
        setServiceItem((prevItem) => ({
            ...prevItem,
            name: event?.target?.value?.trim(),
        }));
    }, []);

    const handleServiceDetailsChange = useCallback((event) => {
        setServiceItem((prevItem) => ({
            ...prevItem,
            details: event?.target?.value?.trim(),
        }));
    }, []);

    const handleDropDownClick = useCallback(
        (service) => {
            setServiceItem((prevItem) => ({
                ...prevItem,
                serviceId: service?.id,
                name: service?.name,
                qty: 1,
                item: 'Item',
                unitPrice: service?.price,
                total: service?.price,
                taxed: invoiceTolbarTax?.filter(
                    (tax) => tax?.id && tax?.unit === '$',
                ),
                details: service?.details,
            }));
        },
        [invoiceTolbarTax],
    );

    const handleEditServiceClick = useCallback(
        (service) => {
            setServiceItem((prevItem) => ({
                serviceId: service?.serviceId,
                name: service?.name,
                qty: service?.qty,
                item: service?.item,
                unitPrice: service?.unitPrice,
                total: service?.total,
                taxed: service?.taxed,
                details: service?.details,
                company: data?.id,
                errors: { name: '', unitPrice: '', qty: '', details: '' },
            }));
            setIsEdit(true);
            setIsModalOpen(true);
        },
        [data?.id],
    );

    const handleServiceUnitPriceChange = useCallback((event) => {
        const value = event?.target?.value;
        if (isNaN(value)) return;
        setServiceItem((prevItem) => ({
            ...prevItem,
            unitPrice: value,
        }));
    }, []);

    const handleServiceQuantityChange = useCallback((event) => {
        const value = event?.target?.value;
        if (isNaN(value)) return;
        setServiceItem((prevItem) => ({
            ...prevItem,
            qty: value,
        }));
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsApiLoading(true);
            try {
                const { token } = store.getState().auth;
                dispatch(fetchServicesRequest({ token }));
                dispatch(
                    fetchInvoiceByIdRequest({
                        invoiceId: invoiceId,
                    }),
                );
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsApiLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setInvoiceTotals(calculateTax(services)));
    }, [dispatch, services]);

    useEffect(() => {
        if (invoiceId && !isApiLoading) {
            const { token } = store.getState().auth;
            const lastThreeTaxIds = invoiceTolbarTax
                ?.filter((tax) => tax.id)
                .map((tax) => tax?.id);

            const payload = {
                invoice_services: formatServices(services),
                subtotal_amount: invoiceTotals?.subTotal,
                total_amount: invoiceTotals?.totalAmount,
                taxes: lastThreeTaxIds,
            };

            dispatch(
                updateInvoiceRequest({
                    payload,
                    token,
                    invoiceId: invoiceId,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, invoiceId, invoiceTotals, isApiLoading]);

    useEffect(() => {
        if (invoiceId && !isApiLoading) {
            const { token } = store.getState().auth;

            const payload = {
                client: client?.id,
            };

            dispatch(
                updateInvoiceRequest({
                    payload,
                    token,
                    invoiceId: invoiceId,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client?.id, invoiceId, dispatch, isApiLoading]);

    const submit = useCallback(() => {
        if (!validateService()) return;
        const { token } = store.getState().auth;
        if (serviceItem?.serviceId && isEdit) {
            const updatedServices = services?.map((service) =>
                service?.serviceId === serviceItem?.serviceId
                    ? {
                          name: serviceItem?.name,
                          serviceId: serviceItem?.serviceId,
                          unitPrice: parseFloat(serviceItem?.unitPrice) || 0,
                          details: serviceItem?.details,
                          item: serviceItem?.item,
                          total:
                              parseFloat(serviceItem?.unitPrice) *
                              serviceItem?.qty,
                          qty: serviceItem?.qty,
                          taxed: serviceItem?.taxed,
                      }
                    : service,
            );
            dispatch(setInvoiceServices(updatedServices));
        } else if (serviceItem?.serviceId) {
            dispatch(
                appendInvoiceNewService({
                    name: serviceItem?.name,
                    serviceId: serviceItem?.serviceId,
                    unitPrice: parseFloat(serviceItem?.unitPrice) || 0,
                    details: serviceItem?.details,
                    item: serviceItem?.item,
                    total:
                        parseFloat(serviceItem?.unitPrice) * serviceItem?.qty,
                    qty: serviceItem?.qty,
                    taxed: serviceItem?.taxed,
                }),
            );
        } else {
            dispatch(
                serviceInvoiceRequest({
                    name: serviceItem?.name,
                    details: serviceItem.details,
                    price: serviceItem.unitPrice,
                    company: data?.id,
                    token,
                    quantity: serviceItem?.qty,
                    serviceId: serviceItem?.serviceId,
                    taxed: invoiceTolbarTax?.filter(
                        (tax) => tax?.id && tax?.unit === '$',
                    ),
                }),
            );
        }
        closeModal();
    }, [
        closeModal,
        data?.id,
        dispatch,
        invoiceTolbarTax,
        isEdit,
        serviceItem.details,
        serviceItem?.item,
        serviceItem?.name,
        serviceItem?.qty,
        serviceItem?.serviceId,
        serviceItem?.taxed,
        serviceItem.unitPrice,
        services,
        validateService,
    ]);

    const handleOpenModal = useCallback(() => {
        setServiceItem({
            serviceId: null,
            name: '',
            qty: '',
            item: 'Item',
            unitPrice: '',
            tax: 0,
            total: 0,
            taxed: [],
            details: '',
            company: data?.id,
            errors: { name: '', unitPrice: '', qty: '', details: '' },
        });
        setIsEdit(false);
        setIsModalOpen(true);
    }, [data?.id]);

    useEffect(() => {
        const { token } = store.getState().auth;
        dispatch(fetchInvoiceLogoRequest({ token }));
    }, [dispatch]);

    const calling = useCallback(async () => {
        if (!invoiceLogo?.stripe_info?.connected_account_id) {
            setSnackbarMessage('Please Connect Stripe Account First');
            setSnackbarOpen(true);
            setIsNextButtonPressed2(true);
            return;
        }
        if (!client?.id) {
            setSnackbarMessage('Please enter client details');
            setSnackbarOpen(true);
            setIsNextButtonPressed(true);
            return;
        }

        if (!services?.length) {
            setSnackbarMessage('Please enter a service');
            setSnackbarOpen(true);
            return;
        }
        const { token } = store.getState().auth;
        const dueDate = formatDateService(new Date());

        const payload = {
            due_date: dueDate,
            subtotal_amount: invoiceTotals?.subTotal,
            total_amount: invoiceTotals?.totalAmount,
        };
        setIsNextButtonPressed(false);
        setIsNextButtonPressed2(false);

        dispatch(
            updateInvoiceRequest({
                payload,
                token,
                invoiceId: invoiceId,
            }),
        );
        onSyncAll('two');
    }, [
        client?.id,
        dispatch,
        invoiceId,
        invoiceLogo?.stripe_info?.connected_account_id,
        invoiceTotals?.subTotal,
        invoiceTotals?.totalAmount,
        onSyncAll,
        services?.length,
    ]);

    useEffect(() => {
        if (client?.id) {
            setIsNextButtonPressed(false);
        }
    }, [client?.id]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleRemoveService = useCallback(
        (index) => {
            const updatedServices = services?.filter((_, i) => i !== index);
            dispatch(setInvoiceServices(updatedServices));
        },
        [dispatch, services],
    );

    const handleTaxCheckboxChange = useCallback(
        (index, tax, isChecked) => {
            dispatch(
                setInvoiceServices(
                    changeServicesTax(services, isChecked, tax, index),
                ),
            );
        },
        [dispatch, services],
    );

    return (
        <>
            {invoiceType === 'Recurring' && (
                <Box
                    sx={{
                        borderLeft: '4px solid #007DB3',
                        borderRadius: '4px',
                        marginBottom: '20px',
                        padding: '10px 20px',
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? '#121826'
                                : '#F9FAFB',
                        boxShadow:
                            theme.palette.mode === 'dark'
                                ? '0 1px 3px rgba(0, 0, 0, 0.2)'
                                : '0 1px 3px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Typography
                        sx={{
                            color:
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                    >
                        Invoices are issued and payment is due{' '}
                        <strong>
                            on{' '}
                            {dayjs(recurringData.invoiceDue).format('MMMM D')}{' '}
                            every {recurringData.repeatEvery}.
                        </strong>
                    </Typography>
                </Box>
            )}

            <Box
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    height: '100%',
                    padding: '25px',
                    borderRadius: '10px',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        position: 'absolute',
                        top: -40,
                        right: 0,
                    }}
                >
                    <Button
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            borderRadius: '8px',
                            textTransform: 'none',
                            paddingX: '10px',
                            paddingY: '5px',
                            '&:hover': {
                                backgroundColor: '#004BB5',
                            },
                        }}
                        size="small"
                        onClick={calling}
                    >
                        Next
                    </Button>
                </Box>
                <InvoiceHeader
                    clientInfo={client}
                    paramId={invoiceId}
                    isNextButtonPressed={isNextButtonPressed}
                    isNextButtonPressed2={isNextButtonPressed2}
                />
                <Table
                    sx={{
                        width: '100%',
                        border: 'none',
                    }}
                >
                    <ServiceInfoHeader
                        invoiceTolbarTax={invoiceTolbarTax?.filter(
                            (item) => item?.id && item?.unit === '%',
                        )}
                    />
                    <TableBody>
                        {services?.map((service, index) => (
                            <ServiceItemRow
                                key={index}
                                service={service}
                                index={index}
                                hover
                                isHovered={hoveredRow === index}
                                invoiceTolbarTax={invoiceTolbarTax?.filter(
                                    (item) => item?.id && item?.unit === '%',
                                )}
                                setHoveredRow={setHoveredRow}
                                handleRemoveService={handleRemoveService}
                                onTaxCheckboxChange={handleTaxCheckboxChange}
                                onEditClick={handleEditServiceClick}
                            />
                        ))}
                    </TableBody>
                </Table>
                <Box display="flex" marginY="20px">
                    <Button
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            color: '#0061DB',
                            textTransform: 'none',
                        }}
                        onClick={handleOpenModal}
                    >
                        + Add Service
                    </Button>
                </Box>
                <Divider />
                <Grid container>
                    <Grid item xs={12} sm={8} />
                    <Grid item xs={12} sm={4}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            m={4}
                            mr={0}
                            ml={0}
                            gap={2}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                gap={10}
                            >
                                <Typography>Subtotal</Typography>
                                <Typography
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#F9FAFB'
                                                : '#121826',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                    }}
                                >
                                    ${invoiceTotals?.subTotal || 0}
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" gap={1}>
                                {invoiceTotals?.taxArray?.map((tax, index) => (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        gap={10}
                                    >
                                        <Box
                                            display="flex"
                                            gap={1}
                                            alignItems="center"
                                        >
                                            <Typography key={tax.id}>
                                                {tax.taxName}
                                            </Typography>
                                            {tax?.taxPercent && (
                                                <Typography
                                                    key={`${tax.id}${index}`}
                                                    variant="body2"
                                                >
                                                    ({tax?.taxPercent}%)
                                                </Typography>
                                            )}
                                        </Box>
                                        <Typography
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#F9FAFB'
                                                        : '#121826',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                            }}
                                        >
                                            ${tax?.calculatedAmount}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Divider flexItem />
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                gap={10}
                            >
                                <Typography
                                    sx={{ fontWeight: '500', fontSize: '16px' }}
                                >
                                    Total (USD)
                                </Typography>

                                <Typography
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#F9FAFB'
                                                : '#121826',
                                        fontWeight: '800',
                                        fontSize: '16px',
                                    }}
                                >
                                    ${invoiceTotals?.totalAmount || 0}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <AddServiceModal
                open={isModalOpen}
                onClose={() => closeModal()}
                onSave={submit}
                isEdit={isEdit}
                serviceItem={serviceItem}
                dropDownServices={dropDownServices}
                onNameChange={handleServiceNameChange}
                onPriceChange={handleServiceUnitPriceChange}
                onQuantityChange={handleServiceQuantityChange}
                onDetailsChange={handleServiceDetailsChange}
                loading={isLoading}
                onDropDownClick={handleDropDownClick}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default AddServicesInvoice;
