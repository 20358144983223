import {
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import NestedDropDown from 'components/Transaction/TabsData/ImportBankTransaction/Components/ImportTransactionSelect/NestedDropDown';
import { useCallback, useEffect, useRef } from 'react';
import { formatAccountTitleRow, formatCurrencyAmount } from 'utils/helpers';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';

const DropDownTableRowEdit = ({
    themeMode,
    row,
    panelData,
    onListItemClick,
    changeDropDownLength,
    openDropDownIndex,
    handleDropDownToggle,
    isPanelDataLoading,
    handleDropDownClose,
    submitSecondaryTableRow,
    secondTableTransactionDetails,
    secondTableAmount,
    secondTableQuantity,
    secondTableCategory,
    errorsSecondTable,
    rowIndex,
    index,
    handleSecondDescriptionChange,
    handleSecondAmountChange,
    handleSecondQuantityChange,
    handleRemoveTransactionItem,
    parentRow,
}) => {
    const rowRef = useRef(null);

    const onSaveClick = useCallback(() => {
        submitSecondaryTableRow(
            secondTableCategory,
            secondTableTransactionDetails,
            secondTableAmount,
            secondTableQuantity,
            rowIndex,
            index,
        );
    }, [
        index,
        rowIndex,
        secondTableAmount,
        secondTableCategory,
        secondTableQuantity,
        secondTableTransactionDetails,
        submitSecondaryTableRow,
    ]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.closest('.dropDownTableRowDesktop')) {
                return;
            }
            if (rowRef.current && !rowRef.current.contains(event.target)) {
                if (
                    event.target.closest('.dropDownTableRowDesktop') ||
                    event.target.closest('.MuiMenu-paper') ||
                    event.target.closest('.MuiPopover-root') ||
                    event.target.closest('.MuiPickersPopper-root')
                ) {
                    return;
                }

                onSaveClick();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onSaveClick]);

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onSaveClick();
            }
        },
        [onSaveClick],
    );
    return (
        <TableRow
            ref={rowRef}
            onKeyDown={handleKeyPress}
            sx={{
                backgroundColor: themeMode === 'dark' ? '#323A46' : '#F9FAFB',
                cursor: 'pointer',
            }}
        >
            <TableCell>
                <TextField
                    error={errorsSecondTable?.secondTableTransactionDetails}
                    type="text"
                    value={secondTableTransactionDetails}
                    onChange={handleSecondDescriptionChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errorsSecondTable?.description && (
                    <FormHelperText error>
                        {errorsSecondTable?.description}
                    </FormHelperText>
                )}
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
                <NestedDropDown
                    item={panelData}
                    title={formatAccountTitleRow(secondTableCategory)}
                    plaidId={row?.id}
                    companyId={row?.company?.id}
                    onItemClick={onListItemClick}
                    transactionId={row?.id}
                    changeDropDownLength={changeDropDownLength}
                    isDropDownOpen={row?.id === openDropDownIndex}
                    handleDropDownToggle={handleDropDownToggle}
                    isPanelDataLoading={isPanelDataLoading}
                    handleDropDownClose={handleDropDownClose}
                    onRight
                />
            </TableCell>
            <TableCell>
                <TextField
                    error={errorsSecondTable?.price}
                    value={secondTableAmount}
                    onChange={handleSecondAmountChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            maxWidth: 100,
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errorsSecondTable?.price && (
                    <FormHelperText error>
                        {errorsSecondTable?.price}
                    </FormHelperText>
                )}
            </TableCell>
            {/* <TableCell>
                <TextField
                    error={errorsSecondTable?.price}
                    value={secondTableQuantity}
                    onChange={handleSecondQuantityChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errorsSecondTable?.price && (
                    <FormHelperText error>
                        {errorsSecondTable?.price}
                    </FormHelperText>
                )}
            </TableCell> */}
            {/* <TableCell>
                <Typography>
                    ${' '}
                    {formatCurrencyAmount(
                        (+secondTableAmount || 0) * (secondTableQuantity || 0),
                    )}
                </Typography>
            </TableCell> */}
            <TableCell></TableCell>
            <TableCell>
                <IconButton
                    onClick={(e) => {
                        e?.stopPropagation();
                        handleRemoveTransactionItem(rowIndex, index, true);
                    }}
                >
                    <Trash />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default DropDownTableRowEdit;
