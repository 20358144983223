import {
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Preview from 'assets/icons/preview.svg';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { INVOICE_PREVIEW_URL } from 'utils/constants';
import { formatDateTime, processPaymentStatus } from 'utils/helpers';
import AddServicesInvoice from './Components/AddServicesInvoice';
import InvoiceShow from './Components/InvoiceToolbar/index';
import CreateLinkPopup from './Components/ShareInvoice/CreateLinkPopup';
import SharePopup from './Components/ShareInvoice/SharePopup';

function AddServiceInvoice({ onSyncAll }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const toolbarShow = useMediaQuery(theme.breakpoints.down('md'));

    const [sharePopupOpen, setSharePopupOpen] = useState(false);
    const [createLinkPopupOpen, setCreateLinkPopupOpen] = useState(false);
    const [link, setLink] = useState('');

    const handleSharePopupOpen = () => {
        setSharePopupOpen(true);
    };

    const handleSharePopupClose =
        (() => {
            setSharePopupOpen(false);
        },
        []);

    const handleCreateLinkPopupOpen = useCallback(() => {
        setCreateLinkPopupOpen(true);
    }, []);

    const handleCreateLinkPopupClose = useCallback(() => {
        setCreateLinkPopupOpen(false);
    }, []);

    const { invoice } = useSelector((state) => state.invoice);
    const invoiceId = useSelector((state) => state.me.invoiceId);
    const id = invoiceId;
    let paramId;
    if (id) {
        paramId = id;
    } else {
        paramId = invoice?.id;
    }
    const companyid = useSelector((state) => state?.company?.data?.id);
    const invoiceIds = paramId;
    const companyIds = companyid;
    const { singleInvoice } = useSelector((state) => state.invoice);
    const [nameOfService, setNameOfService] = useState('Invoice Name');
    const [timeDate, setTimeDate] = useState('');
    useEffect(() => {
        const formattedDateTime = formatDateTime(singleInvoice?.created_at);
        setNameOfService(singleInvoice?.name);
        setTimeDate(formattedDateTime);
    }, [singleInvoice]);

    useEffect(() => {
        const formattedDateTime = formatDateTime(invoice?.created_at);
        setNameOfService(invoice?.name);
        setTimeDate(formattedDateTime);
    }, [invoice]);

    let emailLink = `${INVOICE_PREVIEW_URL}/invoice/${invoiceIds}/${companyIds}`;

    const handleCreateLink = useCallback(() => {
        const newLink = `${INVOICE_PREVIEW_URL}/invoice/${invoiceIds}/${companyIds}`;
        navigator.clipboard.writeText(newLink);
        setLink(newLink);
        handleCreateLinkPopupClose();
    }, [companyIds, handleCreateLinkPopupClose, invoiceIds]);

    const handleCopyLink = useCallback(() => {
        navigator.clipboard.writeText(link);
    }, [link]);
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
            >
                <Box>
                    <Box display="flex" alignItems="center" gap={2} mb={1}>
                        <Typography
                            sx={{
                                fontWeight: '700',
                                fontSize: '18px',
                                lineHeight: '22px',
                            }}
                        >
                            {nameOfService}
                        </Typography>
                        <Typography
                            noWrap
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                backgroundColor:
                                    singleInvoice?.status === 'sent'
                                        ? '#ECFDF3'
                                        : singleInvoice?.status === 'Overdue'
                                        ? '#FEF3F2'
                                        : singleInvoice?.status === 'draft'
                                        ? '#EEF4FF'
                                        : singleInvoice?.status ===
                                          'payment_processing'
                                        ? '#FFF7E5'
                                        : singleInvoice?.status === 'paid'
                                        ? '#E6F4FF'
                                        : '#FEF3F2',

                                color:
                                    singleInvoice?.status === 'sent'
                                        ? '#067647'
                                        : singleInvoice?.status === 'Overdue'
                                        ? '#B42318'
                                        : singleInvoice?.status === 'draft'
                                        ? '#3538CD'
                                        : singleInvoice?.status ===
                                          'payment_processing'
                                        ? '#FFB400'
                                        : singleInvoice?.status === 'paid'
                                        ? '#007BFF'
                                        : '#FEF3F2',
                                border:
                                    singleInvoice?.status === 'sent'
                                        ? '1px solid #ABEFC6'
                                        : singleInvoice?.status === 'Overdue'
                                        ? '1px solid #FECDCA'
                                        : singleInvoice?.status === 'draft'
                                        ? '1px solid #C7D7FE'
                                        : singleInvoice?.status ===
                                          'payment_processing'
                                        ? '1px solid #FFE5B4'
                                        : singleInvoice?.status === 'paid'
                                        ? '1px solid #C3E7FF'
                                        : '1px solid #FECDCA',
                                width: '150px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '12px',
                                textTransform: 'capitalize',
                            }}
                        >
                            {processPaymentStatus(singleInvoice?.status)}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            color: '#6C727F',
                            fontSize: '14px',
                            fontWeight: '500',
                        }}
                    >
                        {timeDate}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }} gap={3}>
                    <Button
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #505C6F'
                                    : '1px solid #E5E6EB',
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark' ? '' : 'white',
                            color: '#6C727F',
                            borderRadius: '8px',
                            outline: 'none',
                            textTransform: 'none',
                            paddingX: '20px',
                        }}
                        onClick={() => onSyncAll('preview one')}
                        fullWidth={isSmallScreen}
                    >
                        <img
                            src={Preview}
                            alt="Icon"
                            style={{
                                width: 18,
                                height: 18,
                                marginRight: '6px',
                            }}
                        />
                        Preview
                    </Button>
                    {/* <Button
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: '#0051bb',

                                '@media (hover: none)': {
                                    backgroundColor: '#0061DB',
                                },
                            },
                            textTransform: 'none',
                            paddingX: '20px',
                        }}
                        startIcon={<ExitToAppIcon />}
                        fullWidth={isSmallScreen}
                        onClick={handleSharePopupOpen}
                    >
                        Share
                    </Button> */}
                </Box>
            </Box>

            <Grid container spacing={2} mt={4}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <AddServicesInvoice onSyncAll={onSyncAll} />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InvoiceShow />
                </Grid>
            </Grid>

            <SharePopup
                open={sharePopupOpen}
                handleClose={handleSharePopupClose}
                onCreateLink={handleCreateLinkPopupOpen}
                link={link}
                onCopyLink={handleCopyLink}
                emailLink={emailLink}
                invoiceIds={invoiceIds}
            />
            <CreateLinkPopup
                open={createLinkPopupOpen}
                handleClose={handleCreateLinkPopupClose}
                onCreateLink={handleCreateLink}
            />
        </>
    );
}
export default AddServiceInvoice;
