import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const PaymentAmount = ({ amount }) => {
    const invoiceTotals = useSelector((state) => state.invoice.invoiceTotals);
    return (
        <>
            <Typography
                sx={{
                    fontWeight: '600',
                    fontSize: '16px',
                    marginTop: '20px',
                }}
            >
                Amount due
            </Typography>
            <Typography sx={{ fontWeight: '700', fontSize: '32px' }}>
                $ {invoiceTotals?.totalAmount || 0}
            </Typography>
        </>
    );
};
export default PaymentAmount;
