import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import Trash from 'assets/icons/Trash.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from 'store';
import {
    createTaxRequest,
    deleteTaxRequest,
    fetchTaxesRequest,
    updateInvoiceRequest,
} from 'store/actions';
import { formatServices } from 'utils/helpers';
import InvoiceSttingPreview from '../Components/InvoicePreview/InvoiceSettingPreview';

function TaxDetails({ onSyncAll, generalSettings }) {
    const taxEndRef = useRef();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const data = useSelector((state) => state.company.data);
    const allTaxes = useSelector((state) => state.invoice.allTaxes);
    const companyId = data?.id;
    const [taxDetails, setTaxDetails] = useState([]);
    const [errors, setErrors] = useState({});
    const invoiceId = useSelector((state) => state.me.invoiceId);
    const services = useSelector((state) => state.invoice.invoiceServices);

    useEffect(() => {
        dispatch(fetchTaxesRequest({ token }));
    }, [dispatch, token]);

    useEffect(() => {
        if (allTaxes?.length === 0) {
            const defaultTax = {
                id: Date.now(),
                tax: '',
                rate: '',
                type: '%',
                isNew: true,
            };
            setTaxDetails([defaultTax]);
        } else {
            const newTaxDetails = allTaxes?.map((tax) => ({
                id: tax.id,
                tax: tax.name,
                rate: tax.amount,
                type: tax.unit,
                isNew: false,
            }));
            let taxArray = [];
            if (newTaxDetails?.length === 3) {
                taxArray = [...newTaxDetails];
            } else {
                // taxArray = newTaxDetails?.slice(0, 3);
                taxArray = [...newTaxDetails];
            }
            setTaxDetails([...taxArray]);
        }
    }, [allTaxes]);

    const scrollToBottom = useCallback(() => {
        taxEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const handleAddTax = useCallback(() => {
        setTaxDetails((prevDetails) => [
            ...prevDetails,
            { id: Date.now(), tax: '', rate: '', type: '%', isNew: true },
        ]);

        setTimeout(() => {
            scrollToBottom();
        }, 0);
    }, [scrollToBottom]);

    const handleRemoveTax = useCallback(
        (id) => {
            dispatch(deleteTaxRequest({ token, taxId: id }));
            setTaxDetails((prevDetails) =>
                prevDetails.filter((detail) => detail.id !== id),
            );
        },
        [dispatch, token],
    );

    const handleTaxChange = useCallback((id, field, value) => {
        if (field === 'rate') {
            if (value?.trim() === '') {
                value = '';
            } else if (isNaN(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [id]: {
                        ...prevErrors[id],
                        [field]: 'Rate must be a number',
                    },
                }));
                return;
            } else if (value?.split('.')?.[0]?.length > 3) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [id]: {
                        ...prevErrors[id],
                        [field]: 'Only 3 Digits allowed before decimal point',
                    },
                }));
                return;
            } else if (value?.split('.')?.[1]?.length > 2) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [id]: {
                        ...prevErrors[id],
                        [field]:
                            'Only 2 digits are allowed after decimal point',
                    },
                }));
                return;
            }
        }
        setTaxDetails((prevDetails) =>
            prevDetails.map((detail) =>
                detail.id === id ? { ...detail, [field]: value } : detail,
            ),
        );
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: { ...prevErrors[id], [field]: '' },
        }));
    }, []);

    const validate = useCallback((taxDetails) => {
        let valid = true;
        const newErrors = {};
        taxDetails?.forEach((detail) => {
            if (!detail.tax) {
                valid = false;
                newErrors[detail.id] = {
                    ...newErrors[detail.id],
                    tax: 'Tax name is required',
                };
            }
            if (!detail.rate) {
                valid = false;
                newErrors[detail.id] = {
                    ...newErrors[detail.id],
                    rate: 'Rate is required',
                };
            }
            if (isNaN(detail?.rate)) {
                valid = false;
                newErrors[detail.id] = {
                    ...newErrors[detail.id],
                    rate: 'Rate must be a number',
                };
            }
        });
        setErrors(newErrors);
        return valid;
    }, []);

    const handleLogDetails = useCallback(async () => {
        if (!validate(taxDetails)) return;

        const { token } = store.getState().auth;
        const newTaxes = taxDetails.filter((tax) => tax.isNew);
        const payload = {
            invoice_services: formatServices(services),
            taxes: taxDetails
                ?.filter((tax) => tax?.id && !tax?.isNew)
                .map((tax) => tax?.id)
                ?.slice(0, 3),
        };
        const existingTaxesId = {
            payload,
            token,
            invoiceId: invoiceId,
        };

        for (const tax of newTaxes) {
            const taxData = {
                name: tax.tax,
                amount: tax.rate,
                unit: tax.type,
                company: companyId,
            };

            dispatch(
                createTaxRequest({
                    token,
                    taxData,
                    ...(payload?.taxes?.length < 3 ? { existingTaxesId } : {}),
                    generalSettings,
                }),
            );
        }
        if (!newTaxes?.length && !generalSettings) {
            dispatch(updateInvoiceRequest({ ...existingTaxesId }));
        }

        await onSyncAll('one');
        navigate('/invoiceList');
    }, [
        companyId,
        dispatch,
        generalSettings,
        invoiceId,
        navigate,
        onSyncAll,
        services,
        taxDetails,
        validate,
    ]);

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark'
                            ? '#121826'
                            : 'transparent',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>
                    Invoice Settings
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                mt={2}
                mx={4}
            >
                <Grid
                    container
                    spacing={2}
                    p={1}
                    sx={{
                        borderRadius: '12px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    <Grid item xs={12} md={7.5} pr={2}>
                        <Box>
                            <Box>
                                <Box mb={3}>
                                    <Typography
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            color: '#6C727F',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Step 5 of 5
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: '700',
                                            fontSize: '18px',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#F9FAFB'
                                                    : '#121826',
                                        }}
                                    >
                                        Add Tax Details
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#9EA3AE'
                                                    : '#6C727F',
                                        }}
                                    >
                                        Add tax and others.
                                    </Typography>
                                </Box>
                            </Box>

                            <Divider />

                            <Box
                                mt={5}
                                display="flex"
                                flexDirection="column"
                                alignItems="end"
                                sx={{
                                    height: '400px',
                                    overflowY: 'auto',
                                    mt: 2,
                                    width: '100%',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#888',
                                        borderRadius: '8px',
                                        border: '2px solid transparent',
                                        backgroundClip: 'content-box',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: '#555',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#f1f1f1',
                                        borderRadius: '8px',
                                    },
                                }}
                            >
                                {taxDetails.map((detail, index) => (
                                    <Box
                                        key={index}
                                        width="100%"
                                        display="flex"
                                        flexDirection="column"
                                        gap={2}
                                        p={2}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={9} sm={10}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Tax"
                                                    variant="outlined"
                                                    value={detail.tax}
                                                    onChange={(e) =>
                                                        handleTaxChange(
                                                            detail.id,
                                                            'tax',
                                                            e.target.value,
                                                        )
                                                    }
                                                    error={
                                                        errors[detail.id]?.tax
                                                            ? true
                                                            : false
                                                    }
                                                    helperText={
                                                        errors[detail.id]?.tax
                                                    }
                                                    sx={{
                                                        bgcolor: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? '#394150'
                                                                : '#F4F4F6',
                                                        borderRadius: '6px',
                                                        '& .MuiOutlinedInput-root':
                                                            {
                                                                '& fieldset': {
                                                                    border: (
                                                                        theme,
                                                                    ) =>
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'dark'
                                                                            ? 'none'
                                                                            : '1px solid #E5E6EB',
                                                                    borderRadius:
                                                                        '8px',
                                                                },
                                                                '&:hover fieldset':
                                                                    {
                                                                        border: 'none',
                                                                    },
                                                                '&.Mui-focused fieldset':
                                                                    {
                                                                        border: 'none',
                                                                    },
                                                            },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3} sm={2}>
                                                <Button
                                                    fullWidth
                                                    onClick={() =>
                                                        handleRemoveTax(
                                                            detail?.id,
                                                        )
                                                    }
                                                    sx={{
                                                        border: '1px solid #FA9999',
                                                        borderRadius: '8px',
                                                        color: '#F75555',
                                                        bgcolor: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? '#EFEFF2'
                                                                : 'transparent',
                                                        textTransform: 'none',
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '16px',
                                                        },
                                                    }}
                                                >
                                                    <img
                                                        src={Trash}
                                                        alt="Icon"
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            marginRight: '5px',
                                                        }}
                                                    />
                                                    Delete
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={7} sm={10}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Rate"
                                                    variant="outlined"
                                                    value={detail.rate}
                                                    onChange={(e) =>
                                                        handleTaxChange(
                                                            detail.id,
                                                            'rate',
                                                            e.target.value,
                                                        )
                                                    }
                                                    error={
                                                        errors[detail.id]?.rate
                                                            ? true
                                                            : false
                                                    }
                                                    helperText={
                                                        errors[detail.id]?.rate
                                                    }
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                        pattern: '[0-9]*',
                                                    }}
                                                    sx={{
                                                        bgcolor: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? '#394150'
                                                                : '#F4F4F6',
                                                        borderRadius: '6px',
                                                        '& .MuiOutlinedInput-root':
                                                            {
                                                                '& fieldset': {
                                                                    border: (
                                                                        theme,
                                                                    ) =>
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'dark'
                                                                            ? 'none'
                                                                            : '1px solid #E5E6EB',
                                                                    borderRadius:
                                                                        '8px',
                                                                },
                                                                '&:hover fieldset':
                                                                    {
                                                                        border: 'none',
                                                                    },
                                                                '&.Mui-focused fieldset':
                                                                    {
                                                                        border: 'none',
                                                                    },
                                                            },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5} sm={2}>
                                                <ButtonGroup
                                                    fullWidth
                                                    variant="string"
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            handleTaxChange(
                                                                detail.id,
                                                                'type',
                                                                '%',
                                                            )
                                                        }
                                                        sx={{
                                                            border:
                                                                detail.type ===
                                                                '%'
                                                                    ? '1px solid #1976d2'
                                                                    : 'none',
                                                            backgroundColor: (
                                                                theme,
                                                            ) =>
                                                                theme.palette
                                                                    .mode ===
                                                                'dark'
                                                                    ? (detail.type ===
                                                                          '%' &&
                                                                          '#0061DB26') ||
                                                                      '#394150'
                                                                    : (detail.type ===
                                                                          '%' &&
                                                                          '#0061DB26') ||
                                                                      theme
                                                                          .backgroundColor
                                                                          .secondary,
                                                            color: 'text.primary',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    detail.type ===
                                                                    '%'
                                                                        ? '#bbdefb'
                                                                        : 'rgba(0, 0, 0, 0.04)',
                                                            },
                                                        }}
                                                    >
                                                        %
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            handleTaxChange(
                                                                detail.id,
                                                                'type',
                                                                '$',
                                                            )
                                                        }
                                                        sx={{
                                                            border:
                                                                detail.type ===
                                                                '$'
                                                                    ? '1px solid #1976d2'
                                                                    : 'none',
                                                            backgroundColor: (
                                                                theme,
                                                            ) =>
                                                                theme.palette
                                                                    .mode ===
                                                                'dark'
                                                                    ? (detail.type ===
                                                                          '$' &&
                                                                          '#0061DB26') ||
                                                                      '#394150'
                                                                    : (detail.type ===
                                                                          '$' &&
                                                                          '#0061DB26') ||
                                                                      theme
                                                                          .backgroundColor
                                                                          .secondary,
                                                            color: 'text.primary',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    detail.type ===
                                                                    '$'
                                                                        ? '#bbdefb'
                                                                        : 'rgba(0, 0, 0, 0.04)',
                                                            },
                                                        }}
                                                    >
                                                        $
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                                <div ref={taxEndRef}></div>
                            </Box>
                            <Box width="100% " mt={2}>
                                <Box
                                    display="flex"
                                    justifyContent="left"
                                    width="100%"
                                >
                                    <Button
                                        onClick={handleAddTax}
                                        sx={{
                                            fontWeight: '600',
                                            color: '#0061DB',
                                            textTransform: 'none',
                                        }}
                                    >
                                        + Add Tax
                                    </Button>
                                </Box>

                                <Box
                                    display="flex"
                                    gap={2}
                                    alignItems="end"
                                    width="100%"
                                    justifyContent="end"
                                >
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => onSyncAll('four')}
                                    >
                                        {'<--'} Back
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor: '#0061DB',
                                            color: 'white',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            paddingX: '20px',
                                            paddingY: '8px',
                                            '&:hover': {
                                                backgroundColor: '#004BB5',
                                            },
                                        }}
                                        onClick={handleLogDetails}
                                    >
                                        Finish {'-->'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <InvoiceSttingPreview />
                </Grid>
            </Box>
        </>
    );
}

export default TaxDetails;
