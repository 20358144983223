/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function PaymentSchedule({ data, invoiceStep }) {
    const { invoice, invoices } = useSelector((state) => state.invoice);
    const { invoiceIds } = useParams();
    const invoiceId = useSelector((state) => state.me.invoiceId);
    const dispatch = useDispatch();

    let id;
    if (invoiceIds) {
        id = invoiceIds;
    } else {
        id = invoice?.id || invoiceId;
    }

    // const [newInvoice, setNewInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const singleInvoice = useSelector((state) => state?.invoice?.singleInvoice);
    const newInvoice = [singleInvoice];

    // useEffect(() => {
    //     const fetchInvoiceData = async () => {
    //         setLoading(true);
    //         try {
    //             dispatch(fetchInvoiceByIdRequest({ invoiceId: id }));
    //             setNewInvoice([singleInvoice]);
    //             setLoading(false);
    //         } catch (error) {
    //             console.error(
    //                 'There was a problem with the fetch operation:',
    //                 error,
    //             );
    //             setLoading(false);
    //         }
    //     };
    //     if (invoiceIds) {
    //         fetchInvoiceData();
    //     } else {
    //         const invoiceFetch = invoices?.find(
    //             (invoice) => invoice?.id === id,
    //         );
    //         setNewInvoice([invoiceFetch]);
    //         setLoading(false);
    //     }
    // }, [dispatch, id, invoiceIds, invoices, singleInvoice]);

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={!invoiceStep ? 12 : 8}
            lg={!invoiceStep ? 12 : 8}
            xl={!invoiceStep ? 12 : 8}
        >
            <Box
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    height: '100%',
                    padding: '25px',
                    borderRadius: '10px',
                }}
            >
                <Typography ml={1.8} pb={2} sx={{ fontWeight: '800' }}>
                    PAYMENT SCHEDULE
                </Typography>

                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            fontWeight: 'bold',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    >
                                        Amount
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    >
                                        Due date
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    >
                                        Payment ID
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                        }}
                                    >
                                        Status
                                    </TableCell>
                                </TableRow>
                                {newInvoice?.map((row) => (
                                    <TableRow
                                        key={row?.id}
                                        sx={{
                                            borderBottom: 'none',
                                            borderTop: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                borderTop: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',

                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : 'black',
                                            }}
                                        >
                                            {row?.total_amount}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                textAlign: 'left',
                                                borderTop: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : 'black',
                                            }}
                                        >
                                            {row?.due_date}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                textAlign: 'center',
                                                borderTop: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : 'black',
                                            }}
                                        >
                                            {row?.id}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                textAlign: 'center',
                                                borderTop: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : 'black',
                                            }}
                                        >
                                            <Typography fontWeight="500">
                                                {row?.status}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Grid>
    );
}

export default PaymentSchedule;
