import NavListFirst from './mini/NavListFirst';

const NestedDropDown = ({
    item,
    onItemClick,
    plaidId,
    title,
    changeDropDownLength,
    isDropDownOpen,
    handleDropDownToggle,
    rowIndex,
    companyId,
    isPanelDataLoading,
    transactionId,
    handleDropDownClose,
    arrowDown,
    onRight,
}) => {
    return (
        <NavListFirst
            onAccountLick={onItemClick}
            transactionId={transactionId}
            plaidId={plaidId}
            key={title}
            data={item}
            depth={1}
            title={title}
            hasChild={!!item?.children}
            changeDropDownLength={changeDropDownLength}
            isDropDownOpen={isDropDownOpen}
            handleDropDownToggle={handleDropDownToggle}
            rowIndex={rowIndex}
            companyId={companyId}
            isPanelDataLoading={isPanelDataLoading}
            handleDropDownClose={handleDropDownClose}
            arrowDown={arrowDown}
            onRight={onRight}
        />
    );
};
export default NestedDropDown;
