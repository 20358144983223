import {
    Box,
    Divider,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRecurringInvoice } from 'store/actions';
import {
    convertFromMilliseconds,
    convertToEpochInterval,
    formatDateService,
} from 'utils/helpers';

function RecurringInvoiceDetails({ onViewRecurringInvoiceDetailsClick }) {
    const dispatch = useDispatch();
    const singleInvoice = useSelector((state) => state?.invoice?.singleInvoice);

    const handleInvoiceDueDateChange = useCallback(
        (newValue) => {
            dispatch(
                setRecurringInvoice({
                    first_invoice_due_date: formatDateService(newValue),
                }),
            );
        },
        [dispatch],
    );

    const handleLastInvoiceDateChange = useCallback(
        (newValue) => {
            dispatch(
                setRecurringInvoice({
                    on_last_invoice_date: formatDateService(newValue),
                }),
            );
        },
        [dispatch],
    );

    const handleInvoiceRepeat = useCallback(
        (event) => {
            dispatch(
                setRecurringInvoice({
                    recurring_frequency: event.target.value,
                    custom_period: 'days',
                    custom_interval: convertToEpochInterval(1, 'days'),
                }),
            );
        },
        [dispatch],
    );

    const handleInvoiceCustomRepeat = useCallback(
        (event) => {
            dispatch(
                setRecurringInvoice({
                    custom_period: event?.target?.value,
                }),
            );
        },
        [dispatch],
    );

    const handleLastInvoiceOption = useCallback(
        (event) => {
            dispatch(
                setRecurringInvoice({
                    last_invoice_option: event?.target?.value,
                    ...(event?.target?.value === 'after' && {
                        after_number_of_invoices: 1,
                    }),
                    ...(event?.target?.value !== 'after' && {
                        after_number_of_invoices: null,
                    }),
                }),
            );
        },
        [dispatch],
    );

    const handleAfterInvoiceOption = useCallback(
        (event) => {
            dispatch(
                setRecurringInvoice({
                    after_number_of_invoices: event?.target?.value,
                }),
            );
        },
        [dispatch],
    );

    const handleInvoiceCustomInterval = useCallback(
        (event) => {
            const miliSeconds = convertToEpochInterval(
                event?.target?.value,
                singleInvoice?.recurring_details?.custom_period,
            );
            dispatch(
                setRecurringInvoice({
                    custom_interval: miliSeconds,
                }),
            );
        },
        [dispatch, singleInvoice?.recurring_details?.custom_period],
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    marginTop: 3,
                    justifyContent: 'start',
                    alignItems: 'start',
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            flex: '0 0 30%',
                        }}
                    >
                        1st invoice due
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disablePast
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '8px',
                                    border: 'none',
                                    height: '40px',
                                    minHeight: '40px',
                                },
                            }}
                            size="small"
                            value={
                                singleInvoice?.recurring_details
                                    ?.first_invoice_due_date
                                    ? dayjs(
                                          singleInvoice?.recurring_details
                                              ?.first_invoice_due_date,
                                      )
                                    : null
                            }
                            onChange={handleInvoiceDueDateChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    readOnly
                                    onClick={() => {
                                        params.inputProps.onFocus();
                                    }}
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '8px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : 'none',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                            minHeight: '36px',
                                            height: '36px',
                                        },
                                        '& .MuiInputBase-root': {
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F4F4F6',
                                            borderRadius: '8px',
                                        },
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            flex: '0 0 30%',
                        }}
                    >
                        Repeat every
                    </Typography>
                    <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{
                            flex: '0 0 60%',
                        }}
                    >
                        <Select
                            value={
                                singleInvoice?.recurring_details
                                    ?.recurring_frequency || 'day'
                            }
                            onChange={handleInvoiceRepeat}
                            sx={{
                                backgroundColor: '#F4F4F6',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                    {
                                        border: '1px solid #E5E6EB',
                                    },
                            }}
                        >
                            <MenuItem value="day">Day</MenuItem>
                            <MenuItem value="week">Week</MenuItem>
                            <MenuItem value="month">Month</MenuItem>
                            <MenuItem value="year">Year</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {singleInvoice?.recurring_details?.recurring_frequency ===
                    'custom' && (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={2}
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                flex: '0 0 30%',
                            }}
                        >
                            Every
                        </Typography>
                        <TextField
                            size="small"
                            value={convertFromMilliseconds(
                                singleInvoice?.recurring_details
                                    ?.custom_interval,
                                singleInvoice?.recurring_details?.custom_period,
                            )}
                            onChange={handleInvoiceCustomInterval}
                            sx={{
                                flex: '0 0 10%', // Set a fixed proportion
                            }}
                        />
                        <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            sx={{
                                flex: '0 0 40%',
                            }}
                        >
                            <Select
                                value={
                                    singleInvoice?.recurring_details
                                        ?.custom_period
                                }
                                onChange={handleInvoiceCustomRepeat}
                                label="Select Country"
                                sx={{
                                    backgroundColor: '#F4F4F6',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none', // Hide default border
                                    },
                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                        {
                                            border: '1px solid #E5E6EB', // Border color on focus
                                        },
                                }}
                            >
                                <MenuItem value="days">Days</MenuItem>
                                <MenuItem value="weeks">Weeks</MenuItem>
                                <MenuItem value="months">Months</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}

                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            flex: '0 0 30%',
                        }}
                    >
                        Last invoice
                    </Typography>
                    <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        sx={{
                            flex:
                                singleInvoice?.recurring_details
                                    ?.last_invoice_option === 'on' ||
                                singleInvoice?.recurring_details
                                    ?.last_invoice_option === 'after'
                                    ? '0 0 30%'
                                    : '0 0 60%',
                        }}
                    >
                        <Select
                            value={
                                singleInvoice?.recurring_details
                                    ?.last_invoice_option || 'no_end_date'
                            }
                            onChange={handleLastInvoiceOption}
                            sx={{
                                backgroundColor: '#F4F4F6',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none', // Hide default border
                                },
                                '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                    {
                                        border: '1px solid #E5E6EB', // Border color on focus
                                    },
                            }}
                        >
                            <MenuItem value="no_end_date">No end Date</MenuItem>
                            <MenuItem value="on">on</MenuItem>
                            <MenuItem value="after">after</MenuItem>
                        </Select>
                    </FormControl>

                    {singleInvoice?.recurring_details?.last_invoice_option ===
                    'after' ? (
                        <TextField
                            size="small"
                            value={
                                singleInvoice?.recurring_details
                                    ?.after_number_of_invoices || 0
                            }
                            onChange={handleAfterInvoiceOption}
                            sx={{
                                flex: '0 0 30%',
                            }}
                        />
                    ) : (
                        ''
                    )}

                    {singleInvoice?.recurring_details?.last_invoice_option ===
                    'on' ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disablePast
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '8px',
                                        border: 'none',
                                        height: '40px',
                                        minHeight: '40px',
                                    },
                                }}
                                size="small"
                                value={
                                    singleInvoice?.recurring_details
                                        ?.on_last_invoice_date
                                        ? dayjs(
                                              singleInvoice?.recurring_details
                                                  ?.on_last_invoice_date,
                                          )
                                        : null
                                }
                                onChange={handleLastInvoiceDateChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        size="small"
                                        sx={{
                                            bgcolor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F4F4F6',
                                            borderRadius: '8px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : 'none',
                                                    borderRadius: '8px',
                                                },
                                                '&:hover fieldset': {
                                                    border: 'none',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                },
                                                minHeight: '36px',
                                                height: '36px',
                                            },
                                            '& .MuiInputBase-root': {
                                                backgroundColor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#394150'
                                                        : '#F4F4F6',
                                                borderRadius: '8px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    ) : (
                        ''
                    )}
                </Box>

                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                    }}
                >
                    {singleInvoice?.recurring_details?.last_invoice_option ===
                        'on' ||
                    singleInvoice?.recurring_details?.last_invoice_option ===
                        'after'
                        ? `Invoice 1 of ${singleInvoice?.recurring_details?.after_number_of_invoices}`
                        : 'Invoice 1 (no end date)'}
                </Typography>

                <Typography
                    onClick={onViewRecurringInvoiceDetailsClick}
                    sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: '#0061DB',
                        marginBottom: '10px',
                        cursor: 'pointer',
                    }}
                >
                    View recurring invoice details
                </Typography>
            </Box>

            <Divider />
        </>
    );
}

export default RecurringInvoiceDetails;
