const FORMAT_PDF = ['pdf'];
const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg'];

const iconUrl = (icon) => `/assets/icons/files/${icon}.svg`;

export function fileFormat(fileUrl) {
    let format;

    switch (fileUrl?.includes(fileTypeByUrl(fileUrl))) {
        case FORMAT_IMG.includes(fileTypeByUrl(fileUrl)):
            format = 'image';
            break;
        case FORMAT_PDF.includes(fileTypeByUrl(fileUrl)):
            format = 'pdf';
            break;
        default:
            format = fileTypeByUrl(fileUrl);
    }

    return format;
}

export function fileThumb(fileUrl) {
    let thumb;

    switch (fileFormat(fileUrl)) {
        case 'pdf':
            thumb = iconUrl('ic_pdf');
            break;
        case 'image':
            thumb = iconUrl('ic_img');
            break;
        default:
            thumb = iconUrl('ic_file');
    }
    return thumb;
}

export function fileTypeByUrl(fileUrl = '') {
    return (fileUrl && fileUrl.split('.').pop()) || '';
}

export function fileNameByUrl(fileUrl) {
    return fileUrl.split('/').pop();
}

export function fileData(file) {
    // Url
    if (typeof file === 'string') {
        return {
            key: file,
            preview: file,
            name: fileNameByUrl(file),
            type: fileTypeByUrl(file),
        };
    }

    return {
        key: file.preview,
        name: file.name,
        size: file.size,
        path: file.path,
        type: file.type,
        preview: file.preview,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
    };
}
