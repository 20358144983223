import { Link, ListItemText } from '@mui/material';
import { ReactComponent as RightArrow } from 'assets/icons/RightArrow.svg';
import { ReactComponent as RightArrowDarkMode } from 'assets/icons/RightArrowDarkMode.svg';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StyledItem } from './styles';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const NavItem = forwardRef(
    (
        {
            item,
            mainTitle,
            onAccountLick,
            plaidId,
            depth,
            open,
            active,
            isExternalLink,
            transactionId,
            onRight,
            ...other
        },
        ref,
    ) => {
        const { title, children, accountNumber, isParent } = item;
        const subItem = depth !== 1;
        const { themeMode } = useThemeToggle();

        const renderContent = (
            <StyledItem
                ref={ref}
                open={open}
                depth={depth}
                active={active}
                disabled={false}
                {...other}
            >
                {!!children &&
                    !!!onRight &&
                    (themeMode !== 'dark' ? (
                        <RightArrow
                            width={16}
                            height={16}
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    ) : (
                        <RightArrowDarkMode
                            width={16}
                            height={16}
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    ))}
                <ListItemText
                    primary={
                        <>
                            {isParent && (
                                <SubdirectoryArrowRightIcon fontSize="small" />
                            )}
                            {title}
                        </>
                    }
                    primaryTypographyProps={{
                        noWrap: true,
                        sx: {
                            // width: 200,
                            fontSize: 16,
                            lineHeight: '16px',
                            alignItems: 'center',
                            textAlign: 'center',
                            ...(active && {
                                fontWeight: 'fontWeightMedium',
                            }),
                            ...(subItem && {
                                fontSize: 14,
                                width: 'auto',
                                textAlign: 'left',
                            }),
                        },
                    }}
                />
                {!!children &&
                    !!onRight &&
                    (themeMode !== 'dark' ? (
                        <RightArrow width={16} height={16} />
                    ) : (
                        <RightArrowDarkMode width={16} height={16} />
                    ))}
            </StyledItem>
        );

        const renderItem = (
            onAccountLick,
            plaidId,
            depth,
            title,
            transactionId,
        ) => {
            return (
                <Link
                    component={RouterLink}
                    onClick={(e) => {
                        if (
                            depth === 4 &&
                            title?.toLowerCase()?.trim() !== 'no data'
                        ) {
                            onAccountLick(
                                accountNumber,
                                plaidId,
                                transactionId,
                            );
                        }
                    }}
                    underline="none"
                >
                    {renderContent}
                </Link>
            );
        };

        return (
            <>
                {' '}
                {renderItem(
                    onAccountLick,
                    plaidId,
                    depth,
                    title,
                    transactionId,
                )}{' '}
            </>
        );
    },
);

export default NavItem;
