import SyncIcon from '@mui/icons-material/Sync';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import Bank from 'assets/icons/bank.svg';
import BankDark from 'assets/icons/bankDark.svg';
import Card from 'assets/icons/card.svg';
import CardDark from 'assets/icons/cardDark.svg';

function WebView({ reconcile, data, setSelectedAccount }) {
    const theme = useTheme();

    return (
        <TableContainer
            sx={{
                maxHeight: 360,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                    height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                },
            }}
        >
            <Table>
                <TableBody>
                    <TableRow
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        <TableCell
                            sx={{
                                borderBottom: 'none',
                                fontWeight: 'bold',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                            }}
                        >
                            Account
                        </TableCell>
                        <TableCell
                            sx={{
                                borderBottom: 'none',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                            }}
                        >
                            Type
                        </TableCell>
                        <TableCell
                            sx={{
                                borderBottom: 'none',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                            }}
                        >
                            Latest Reconciliation
                        </TableCell>
                        {/* <TableCell
                            sx={{
                                borderBottom: 'none',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                            }}
                        >
                            Auto-validation check
                        </TableCell> */}
                        <TableCell
                            sx={{
                                borderBottom: 'none',
                                fontWeight: 'bold',
                                textAlign: 'right',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                            }}
                        >
                            Action
                        </TableCell>
                    </TableRow>
                    {data.map((row, index) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                borderBottom: 'none',
                                borderTop: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                            }}
                        >
                            <TableCell
                                sx={{
                                    borderBottom: 'none',
                                    borderTop: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '1px solid #394150'
                                            : '1px solid #E5E6EB',

                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : 'black',
                                    width: '300px',
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                    overflowWrap: 'break-word',
                                    overflow: 'hidden',
                                }}
                            >
                                {row.name}
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: 'none',
                                    textAlign: 'left',
                                    borderTop: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '1px solid #394150'
                                            : '1px solid #E5E6EB',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : 'black',
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    {row.type == 'Bank' ? (
                                        <img
                                            src={
                                                theme.palette.mode === 'dark'
                                                    ? BankDark
                                                    : Bank
                                            }
                                            alt="Icon"
                                            style={{
                                                width: 24,
                                                height: 24,
                                                marginRight: '6px',
                                            }}
                                        />
                                    ) : row.type == 'credit' ? (
                                        <img
                                            src={
                                                theme.palette.mode === 'dark'
                                                    ? CardDark
                                                    : Card
                                            }
                                            alt="Icon"
                                            style={{
                                                width: 24,
                                                height: 24,
                                                marginRight: '6px',
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={
                                                theme.palette.mode === 'dark'
                                                    ? BankDark
                                                    : Bank
                                            }
                                            alt="Icon"
                                            style={{
                                                width: 24,
                                                height: 24,
                                                marginRight: '6px',
                                            }}
                                        />
                                    )}
                                    {row.type}
                                </Box>
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderBottom: 'none',
                                    textAlign: 'center',
                                    borderTop: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '1px solid #394150'
                                            : '1px solid #E5E6EB',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : 'black',
                                }}
                            >
                                {row?.latest_reconciliation?.statement_date ? (
                                    <Button
                                        onClick={() => setSelectedAccount(row)}
                                        sx={{
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#9EA3AE'
                                                    : '#6C727F',
                                            borderRadius: '8px',
                                            outline: 'none',
                                            textTransform: 'none',
                                        }}
                                    >
                                        <Typography variant="subtitle">
                                            {
                                                row?.latest_reconciliation
                                                    ?.statement_date
                                            }
                                        </Typography>
                                    </Button>
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                            {/* <TableCell
                                               sx={{
                                                   borderBottom: 'none',
                                                   textAlign: 'center',
                                                   borderTop: (theme) =>
                                                       theme.palette.mode === 'dark'
                                                           ? '1px solid #394150'
                                                           : '1px solid #E5E6EB',
                                                   color: (theme) =>
                                                       theme.palette.mode === 'dark'
                                                           ? '#D3D5DA'
                                                           : 'black',
                                               }}
                                           >
                                               <Box
                                                   display="flex"
                                                   flexDirection="column"
                                                   justifyContent="center"
                                                   alignItems="center"
                                               >
                                                   <Typography
                                                       display="flex"
                                                       alignItems="center"
                                                       fontWeight="400"
                                                       fontSize="16px"
                                                   >
                                                       {' '}
                                                       <img
                                                           src={
                                                               theme.palette.mode === 'dark'
                                                                   ? MagicPenDark
                                                                   : MagicPen
                                                           }
                                                           alt="Icon"
                                                           style={{
                                                               width: 24,
                                                               height: 24,
                                                               marginRight: '6px',
                                                           }}
                                                       />
                                                       Difference $0.00
                                                   </Typography>
                                                   <Typography
                                                       fontWeight="500"
                                                       fontSize="14px"
                                                       color="#6C727F"
                                                   >
                                                       through{' '}
                                                       {dayjs(row.last_synced).format(
                                                           'YYYY-MM-DD',
                                                       )}
                                                   </Typography>
                                               </Box>
                                           </TableCell> */}
                            <TableCell
                                sx={{
                                    borderBottom: 'none',
                                    textAlign: 'right',
                                }}
                            >
                                <Button
                                    size="small"
                                    startIcon={<SyncIcon />}
                                    sx={{
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1.5px solid #394150'
                                                : '1px solid #E5E6EB',

                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#9EA3AE'
                                                : '#6C727F',
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => reconcile(row)}
                                >
                                    Reconcile
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default WebView;
