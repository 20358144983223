import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    Snackbar,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    createStripeAccountLinkRequest,
    fetchInvoiceLogoRequest,
} from 'store/actions';
import InvoiceSttingPreview from '../Components/InvoicePreview/InvoiceSettingPreview';

function PaymentPreferences({ onSyncAll }) {
    const [isConnectedB, setIsConnectedB] = useState(false);
    const dispatch = useDispatch();
    const invoiceLogo = useSelector((state) => state.invoice.invoiceLogo);
    const error = useSelector((state) => state.invoice.error);
    const [alertOpen, setAlertOpen] = useState(false);
    const companyid = useSelector((state) => state.company.data?.id);

    useEffect(() => {
        if (invoiceLogo?.stripe_info?.connected_account_id) {
            setIsConnectedB(true);
        } else {
            setIsConnectedB(false);
        }
    }, [invoiceLogo]);

    const openSite = (url) => {
        if (url) {
            window.open(url, '_blank');
        } else {
            setAlertOpen(true);
        }
    };

    useEffect(() => {
        const { token } = store.getState().auth;
        dispatch(fetchInvoiceLogoRequest({ token }));
    }, [dispatch]);

    const handleClick = (btn) => {
        const { token } = store.getState().auth;
        // if (!isConnectedB || !invoiceLogo?.stripe_info?.onboarding_complete) {
        if (token) {
            dispatch(
                createStripeAccountLinkRequest({
                    companyId: companyid,
                    token,
                    openSite,
                    setAlertOpen,
                }),
            );
        }
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    return (
        <>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {error}
                </Alert>
            </Snackbar>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark'
                            ? '#121826'
                            : 'transparent',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>
                    Invoice Settings
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                mt={2}
                mx={4}
            >
                <Grid
                    container
                    spacing={2}
                    p={1}
                    sx={{
                        borderRadius: '12px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    <Grid item xs={12} md={7.5} pr={2}>
                        <Box>
                            <Box mb={3}>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#6C727F',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Step 2 of 5
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '700',
                                        fontSize: '18px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#F9FAFB'
                                                : '#121826',
                                    }}
                                >
                                    Payment Preferences
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#9EA3AE'
                                                : '#6C727F',
                                    }}
                                >
                                    Connect your payment methods. You can do it
                                    later if you want.
                                </Typography>
                            </Box>
                            <Divider />

                            <Box
                                mt={5}
                                display="flex"
                                flexDirection="column"
                                alignItems="end"
                                justifyContent="space-between"
                                gap={10}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            gap={1}
                                            onClick={() => handleClick('B')}
                                            sx={{
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                                cursor: 'pointer',
                                            }}
                                            py={1}
                                            borderRadius={4}
                                            style={{
                                                boxShadow:
                                                    'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px',
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                gap={2}
                                                sx={{
                                                    borderBottom: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '1px solid #394150'
                                                            : '1px solid #E5E6EB',
                                                }}
                                                width="100%"
                                                py={3}
                                            >
                                                <img
                                                    src="https://svgshare.com/i/165G.svg"
                                                    alt="Logo"
                                                    width="150px"
                                                    height="35px"
                                                    color="red"
                                                />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                        color: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? '#9EA3AE'
                                                                : '#6C727F',
                                                    }}
                                                >
                                                    Connect with Stripe
                                                </Typography>
                                            </Box>
                                            <Divider />

                                            <Button
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    color: isConnectedB
                                                        ? invoiceLogo
                                                              ?.stripe_info
                                                              ?.onboarding_complete
                                                            ? '#0CAF60'
                                                            : '#FF0000'
                                                        : '#6C727F',
                                                    textTransform: 'none',
                                                    marginTop: '-10px',
                                                    borderColor: isConnectedB
                                                        ? invoiceLogo
                                                              ?.stripe_info
                                                              ?.onboarding_complete
                                                            ? '#0CAF60'
                                                            : '#FF0000'
                                                        : '#6C727F',
                                                    textAlign: 'center',
                                                }}
                                                startIcon={
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            width: 15,
                                                            height: 15,
                                                            borderRadius: '50%',
                                                            border: `2px solid ${
                                                                isConnectedB
                                                                    ? invoiceLogo
                                                                          ?.stripe_info
                                                                          ?.onboarding_complete
                                                                        ? '#0CAF60'
                                                                        : '#FF0000' // Red color if invoiceLogo?.[0]?.onboarding_complete is false and isConnectedB is true
                                                                    : '#6C727F' // Grey color if isConnectedB is null or undefined
                                                            }`,
                                                            padding: '8px', // Adjust the padding as needed
                                                        }}
                                                    >
                                                        {isConnectedB ? (
                                                            <CheckIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '16px',
                                                                    color: invoiceLogo
                                                                        ?.stripe_info
                                                                        ?.onboarding_complete
                                                                        ? '#0CAF60'
                                                                        : '#FF0000', // Red color if onboarding_complete is false and isConnectedB is true
                                                                }}
                                                            />
                                                        ) : (
                                                            <AddIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '16px',
                                                                    color: '#6C727F',
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                }
                                            >
                                                {isConnectedB
                                                    ? invoiceLogo?.stripe_info
                                                          ?.onboarding_complete
                                                        ? 'Connected'
                                                        : 'Complete Onboarding'
                                                    : 'Connect'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box display="flex" gap={2}>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => onSyncAll('one')}
                                    >
                                        {'<-'} Previous
                                    </Button>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => onSyncAll('three')}
                                    >
                                        Skip
                                    </Button>
                                    <Button
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: '#6C727F',
                                            border: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => onSyncAll('three')}
                                    >
                                        Next {'->'}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <InvoiceSttingPreview />
                </Grid>
            </Box>
        </>
    );
}

export default PaymentPreferences;
