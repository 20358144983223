import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import {
    Alert,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmailRequest } from 'store/actions';

const modules = {
    toolbar: [
        [{ font: [] }, { size: [] }],
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ],
};

const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'color',
    'background',
    'list',
    'bullet',
    'link',
];

function SharePopup({
    open,
    handleClose,
    onCreateLink,
    link,
    onCopyLink,
    emailLink,
    invoiceIds,
}) {
    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState([]);
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const { loading, error } = useSelector((state) => state.invoice);
    const data = useSelector((state) => state.company.data);
    const companyId = data?.id;

    const handleAddEmail = useCallback(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emails.length >= 4) {
            setAlertMessage('You can only add up to 4 emails.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        if (!emailRegex.test(emailInput)) {
            setAlertMessage('Please enter a valid email.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        setEmails([...emails, emailInput]);
        setEmailInput('');
    }, [emailInput, emails]);

    const handleSend = useCallback(() => {
        if (emails.length === 0) {
            setAlertMessage('Please add at least one email.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        if (!subject) {
            setAlertMessage('Please add subject.');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        emails.forEach((email, index) => {
            const emailData = {
                to_email: email,
                subject: subject,
                html_content: content,
                company_id: companyId,
                invoice_id: invoiceIds,
            };

            dispatch(sendEmailRequest({ emailData, token }));
        });
        setEmailSent(true);
        handleClose();
    }, [
        companyId,
        content,
        dispatch,
        emails,
        handleClose,
        invoiceIds,
        subject,
        token,
    ]);

    useEffect(() => {
        if (emailSent && !loading) {
            if (error) {
                setAlertMessage(error);
                setAlertSeverity('error');
            } else {
                setAlertMessage('Email sent successfully!');
                setAlertSeverity('success');
            }
            setAlertOpen(true);
            setEmailSent(false); // Reset email sent attempt state
        }
    }, [loading, error, emailSent]);

    const handleAlertClose = useCallback(() => {
        setAlertOpen(false);
    }, []);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '8px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography fontWeight="bold" fontSize={19}>
                        Share Basic Invoice
                    </Typography>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    mb={2}
                    width="100%"
                    sx={{
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E0E0E0',
                    }}
                    borderRadius={2}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        overflowX="auto"
                        width="auto"
                    >
                        <Typography
                            sx={{
                                marginRight: '10px',
                                marginLeft: '10px',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                                color: '#757575',
                            }}
                        >
                            EMAIL TO
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            overflow="hidden"
                        >
                            <TextField
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                                onBlur={handleAddEmail}
                                placeholder="Add 1 or more participants"
                                size="small"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        color: '#757575',
                                        fontSize: '14px',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    overflowX: 'auto',
                                                    maxWidth: '100%',
                                                    '&::-webkit-scrollbar': {
                                                        height: '4px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb':
                                                        {
                                                            backgroundColor:
                                                                '#b0b0b0',
                                                            borderRadius: '4px',
                                                        },
                                                    '&::-webkit-scrollbar-track':
                                                        {
                                                            backgroundColor:
                                                                '#f0f0f0',
                                                        },
                                                }}
                                            >
                                                {emails?.map((email, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={email}
                                                        onDelete={() => {
                                                            setEmails(
                                                                emails.filter(
                                                                    (e, i) =>
                                                                        i !==
                                                                        index,
                                                                ),
                                                            );
                                                        }}
                                                        sx={{
                                                            marginRight: 0.5,
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />
                            <IconButton onClick={handleAddEmail}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Divider />
                    <Box display="flex" alignItems="center" width="100%">
                        <Typography
                            sx={{
                                marginRight: '10px',
                                marginLeft: '10px',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                                color: '#757575',
                            }}
                        >
                            SUBJECT
                        </Typography>
                        <TextField
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="What's the topic?"
                            variant="outlined"
                            size="small"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#757575', fontSize: '14px' },
                            }}
                            InputProps={{
                                style: {
                                    borderRadius: '4px',
                                    height: '40px',
                                },
                            }}
                            sx={{
                                '.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Divider />

                    <ReactQuill
                        value={content}
                        onChange={setContent}
                        placeholder="Type email body"
                        modules={modules}
                        formats={formats}
                        style={{
                            borderRadius: '4px',
                            height: '200px',
                            border: 'none',
                            minHeight: '200px',
                        }}
                    />
                    <style jsx global>{`
                        .ql-container {
                            border: none !important;
                        }
                        .ql-toolbar {
                            border: none !important;
                        }
                        .ql-editor::before {
                            color: 'white';
                            font-style: italic;
                        }
                    `}</style>

                    <Divider />

                    <Box display="flex" justifyContent="end" p={2}>
                        <Button
                            onClick={handleSend}
                            variant="contained"
                            color="primary"
                            endIcon={<SendIcon />}
                            sx={{
                                textTransform: 'none',
                                paddingX: '20px',
                                mt: { xs: '15px', sm: '0' },
                                width: { xs: '100%', sm: 'auto' },
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>

                {/* // */}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                    mt={5}
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                    }}
                >
                    <Box display="flex" flexDirection="column">
                        <Typography fontWeight="bold">
                            Send project participants a link to this file
                        </Typography>

                        <Typography color="textSecondary">
                            To access it, they must enter the email they
                            associated with this project.
                        </Typography>
                    </Box>
                    {link ? (
                        <Button
                            onClick={onCopyLink}
                            startIcon={<ContentCopyIcon />}
                            variant="outlined"
                            sx={{
                                backgroundColor: 'white',
                                color: '#3f51b5',
                                borderColor: '#3f51b5',
                                '&:hover': {
                                    backgroundColor: '#e8eaf6',
                                    borderColor: '#3f51b5',
                                },
                                textTransform: 'none',
                                paddingX: '20px',
                                mt: { xs: '15px', sm: '0' },
                                width: { xs: '100%', sm: 'auto' },
                            }}
                        >
                            Copy Link
                        </Button>
                    ) : (
                        <Button
                            onClick={onCreateLink}
                            startIcon={<LinkIcon />}
                            variant="outlined"
                            sx={{
                                backgroundColor: 'white',
                                color: '#3f51b5',
                                borderColor: '#3f51b5',
                                '&:hover': {
                                    backgroundColor: '#e8eaf6',
                                    borderColor: '#3f51b5',
                                },
                                textTransform: 'none',
                                paddingX: '20px',
                                mt: { xs: '15px', sm: '0' },
                                width: { xs: '100%', sm: 'auto' },
                            }}
                        >
                            Generate a Link
                        </Button>
                    )}
                </Box>
            </DialogContent>

            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default SharePopup;
