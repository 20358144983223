/* eslint-disable no-unused-vars */
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    SET_TRANSACTION_TYPE,
    syncInitialTransactionsRequest,
} from 'store/actions';
dayjs.extend(isSameOrAfter);

function AddBankStartDateModal({
    handleDateChange,
    open,
    onClose,
    selectedDate,
    createPlaidLinkToken,
    isCreatLinkLoading,
}) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="import-dialog-title"
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    minHeight: '400px',
                    borderRadius: '16px',
                },
            }}
        >
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '400px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Box sx={{ padding: 1 }}>
                    <Typography
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? 'white'
                                    : '#121826',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}
                    >
                        Add New Bank Account
                    </Typography>

                    <Box sx={{ width: { xs: '100%', md: '100%' } }} mt={3}>
                        <Typography
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'white'
                                        : '#121826',
                                fontWeight: '700',
                                fontSize: '32px',
                                lineHeight: '38px',
                            }}
                        >
                            How far back should we import your transactions from
                            the new bank account?
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontWeight: '500',
                                mb: '5px',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#121826',
                            }}
                            mt={2}
                        >
                            Import starting from
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    disableFuture
                                    shouldDisableDate={(date) => {
                                        const dayjsDate = dayjs(date);
                                        const ninetyDaysAgo = dayjs().subtract(
                                            365,
                                            'days',
                                        );
                                        const thirtyDaysAgo = dayjs().subtract(
                                            30,
                                            'days',
                                        );

                                        return (
                                            dayjsDate.isBefore(
                                                ninetyDaysAgo,
                                                'day',
                                            ) || // Disable dates older than 365 days
                                            dayjsDate.isAfter(dayjs(), 'day') || // Disable future dates
                                            dayjsDate.isAfter(
                                                thirtyDaysAgo,
                                                'day',
                                            ) // Disable last 30 days
                                        );
                                    }}
                                    disableHighlightToday
                                    l="Select Date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" />
                                    )}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        gap={2}
                        mt={5}
                        width="100%"
                    >
                        <Button
                            sx={{
                                border: '1px solid #394150',
                                color: '#6C727F',
                                borderRadius: '8px',
                                outline: 'none',
                                textTransform: 'none',
                            }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={isCreatLinkLoading}
                            fullWidth={isSmallScreen}
                            sx={{
                                backgroundColor: '#0061DB',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '8px',
                                paddingX: '20px',
                                '&:hover': {
                                    backgroundColor: '#0056b3',
                                    '@media (hover: hover)': {
                                        backgroundColor: '#0056b3',
                                    },
                                },
                            }}
                            onClick={() => createPlaidLinkToken(selectedDate)}
                        >
                            Add Bank Account
                        </LoadingButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default AddBankStartDateModal;
