import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material';
import Setting from 'assets/icons/setting.svg';
import { useSelector } from 'react-redux';

function InvoiceSettingPreview() {
    const { services, taxes, invoiceLogo } = useSelector(
        (state) => state.invoice,
    );

    return (
        <Grid
            item
            xs={12}
            md={4.5}
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#121826' : '#F9FAFB',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: '12px',
            }}
        >
            <Box
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#121826' : 'white',
                    borderRadius: '10px',
                    p: 2,
                    width: { xs: '100%', sm: '80%' },
                    maxWidth: { xs: '100%', sm: '80%' },
                }}
                my={5}
            >
                <Box
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#121826'
                                : '#F9FAFB',
                        borderRadius: '10px',
                        p: 1,
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        textAlign="center"
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                fontSize: '12px', // Smaller font size
                            }}
                        >
                            Invoice
                        </Typography>

                        <Box
                            p={0.5}
                            border="1px solid #E5E6EB"
                            display="flex"
                            justifyContent="center"
                            textAlign="center"
                            borderRadius={3}
                        >
                            <img
                                src={Setting}
                                alt="Icon"
                                style={{
                                    width: 18,
                                    height: 18,
                                    color: '#0061DB',
                                }}
                            />
                        </Box>
                    </Box>

                    <Box
                        mt={1}
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={{ xs: 'column', md: 'row' }}
                    >
                        <Box>
                            {invoiceLogo?.brand_logo && (
                                <img
                                    src={invoiceLogo?.brand_logo}
                                    alt=".."
                                    srcSet=""
                                    style={{
                                        width: 'fit-content',
                                        height: 'auto',
                                        maxHeight: '100px',
                                        maxWidth: '200px',
                                    }}
                                />
                            )}

                            <Typography
                                sx={{
                                    color: '#6C727F',
                                    mt: 0.5,
                                    mb: 0.5,
                                    fontSize: '10px',
                                }}
                            >
                                Bill to
                            </Typography>

                            <Box ml={1}>
                                {[
                                    'Client’s full name',
                                    'Company name',
                                    'Street address',
                                    'City, state, ZIP code',
                                    'Country name',
                                ].map((text, index) => (
                                    <Typography
                                        key={index}
                                        fontWeight="500"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {text}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{
                                mt: { xs: 2, md: 0 },
                                ml: { md: 2 },
                            }}
                        >
                            {[
                                {
                                    label: 'Invoice #',
                                    value: '234761-176812',
                                },
                                {
                                    label: 'Next payment due',
                                    value: 'Jan 28, 2024',
                                },
                            ].map((item, index) => (
                                <Box key={index} mb={1}>
                                    <Typography
                                        sx={{
                                            color: '#6C727F',
                                            mb: 0.5,
                                            fontSize: '10px', // Smaller font size
                                        }}
                                    >
                                        {item.label}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#4D5461',
                                            fontWeight: '600',
                                            fontSize: '10px', // Smaller font size
                                        }}
                                    >
                                        {item.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>

                <Box
                    py={2}
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '10px',
                            color: '#4D5461',
                        }}
                    >
                        SERVICE INFO
                    </Typography>
                    <Box display="flex" gap={2}>
                        {['QTY', 'UNIT', 'UNIT PRICE', 'TAX', 'TOTAL'].map(
                            (text, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '10px',
                                        color: '#6C727F',
                                    }}
                                >
                                    {text}
                                </Typography>
                            ),
                        )}
                    </Box>
                </Box>

                {Array.from({ length: 1 }).map((_, idx) => (
                    <Box
                        key={idx}
                        border="none"
                        outline="none"
                        p={2}
                        mb={2}
                        borderRadius={3}
                        sx={{ bgcolor: 'background.paper' }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            sx={{
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: '700',
                                    fontSize: '12px',
                                    color: 'text.primary',
                                }}
                            >
                                {services.name || ''}
                            </Typography>
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ gap: { xs: 1, sm: 2 } }}
                            >
                                {[
                                    '1',
                                    'Item',
                                    `$ ${services?.price || ''}`,
                                    '',
                                    `$ ${services?.price || ''}`,
                                ].map((text, index) => (
                                    <Typography
                                        key={index}
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '10px',
                                            color: 'text.primary',
                                        }}
                                    >
                                        {index === 3 ? (
                                            <Checkbox
                                                defaultChecked
                                                size="small"
                                            />
                                        ) : (
                                            text
                                        )}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>

                        <Typography
                            sx={{
                                color: '#6C727F',
                                fontSize: '10px',
                                mt: 0.5,
                            }}
                        >
                            {services?.details || ''}
                        </Typography>
                    </Box>
                ))}

                <Divider />

                <Box display="flex" justifyContent="end" gap={15} mt={2}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        {['Subtotal', 'Tax 8.75%', ''].map((text, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    fontWeight: '500',
                                    fontSize: '10px',
                                    color: 'text.secondary',
                                }}
                            >
                                {text}
                            </Typography>
                        ))}

                        <Divider />
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontSize: '12px',
                            }}
                        >
                            Total (USD)
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="end"
                        gap={2}
                    >
                        {[
                            `$ ${services?.price || 0}`,
                            `${taxes?.unit || ''} ${taxes?.amount || ''}`,
                            '',
                            services?.price - taxes?.amount || '',
                        ].map((text, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    color: 'text.primary',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                }}
                            >
                                {text}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default InvoiceSettingPreview;
