import { Collapse, Divider, Stack } from '@mui/material';
import DropDownTableMobile from './DropDownTableMobile';
import { ScanReceiptEditRowMobile } from './ScanReceiptEditRowMobile';
import { ScanReceiptRowMobile } from './ScanReceiptRowMobile';

export const DataTableScanReceiptMobile = ({
    data = [],
    themeMode,
    onEditClick,
    handleCollapse,
    collapse,
    editId,
    handleEditClick,
    onSave,
    description,
    handleDescriptionChange,
    transactionDate,
    setTransactionDate,
    selectedPaymentStatus,
    handlePaymentStatusChange,
    amount,
    handleAmountChange,
    errors,
    isSubmitLoading,
    handleVendorChange,
    vendor,
    handlePaymentDropdownOpen,
    paymentStatuses,
    isPaymentLoading,
    panelData,
    onListItemClick,
    changeDropDownLength,
    isPanelDataLoading,
    openDropDownIndex,
    handleDropDownToggle,
    handleDropDownClose,
    handleEditClickSecondTable,
    hanldeCloseEditSecondTable,
    editIdSecondTable,
    submitSecondaryTableRow,
    setSecondaryRows,
    importPostTransaction,
    handleRemoveTransaction,
    addTransactionItem,
    secondTableTransactionDetails,
    secondTableAmount,
    secondTableQuantity,
    secondTableCategory,
    errorsSecondTable,
    handleSecondDescriptionChange,
    handleSecondAmountChange,
    handleSecondQuantityChange,
    handleRemoveTransactionItem,
    handleAddTransactionItem,
    isEdit,
    handleSourceModalOpen,
}) => {
    return (
        <Stack
            gap={1}
            divider={<Divider flexItem />}
            sx={{
                p: 1,
                borderRadius: '10px',
            }}
        >
            {data?.map((row, index) => (
                <>
                    {editId === index ? (
                        <ScanReceiptEditRowMobile
                            row={row}
                            index={index}
                            themeMode={themeMode}
                            onEditClick={onEditClick}
                            editId={editId}
                            handleEditClick={handleEditClick}
                            handleCollapse={handleCollapse}
                            isCollapse={collapse === index}
                            onSave={onSave}
                            description={description}
                            handleDescriptionChange={handleDescriptionChange}
                            transactionDate={transactionDate}
                            setTransactionDate={setTransactionDate}
                            selectedPaymentStatus={selectedPaymentStatus}
                            handlePaymentStatusChange={
                                handlePaymentStatusChange
                            }
                            amount={amount}
                            handleAmountChange={handleAmountChange}
                            errors={errors}
                            isSubmitLoading={isSubmitLoading}
                            handleVendorChange={handleVendorChange}
                            vendor={vendor}
                            handlePaymentDropdownOpen={
                                handlePaymentDropdownOpen
                            }
                            paymentStatuses={paymentStatuses}
                            isPaymentLoading={isPaymentLoading}
                        />
                    ) : (
                        <ScanReceiptRowMobile
                            isEdit={isEdit}
                            row={row}
                            index={index}
                            themeMode={themeMode}
                            onEditClick={onEditClick}
                            editId={editId}
                            handleEditClick={handleEditClick}
                            handleCollapse={handleCollapse}
                            isCollapse={collapse === index}
                            importPostTransaction={importPostTransaction}
                            handleRemoveTransaction={handleRemoveTransaction}
                            handleSourceModalOpen={handleSourceModalOpen}
                        />
                    )}
                    {(isEdit || !!row?.transaction_items?.length) && (
                        <Collapse
                            in={collapse === index}
                            timeout="auto"
                            unmountOnExit
                            sx={{
                                bgcolor: 'background.neutral',
                            }}
                        >
                            <DropDownTableMobile
                                isEdit={isEdit}
                                row={row}
                                themeMode={themeMode}
                                panelData={panelData}
                                onListItemClick={onListItemClick}
                                changeDropDownLength={changeDropDownLength}
                                handleDropDownToggle={handleDropDownToggle}
                                openDropDownIndex={openDropDownIndex}
                                isPanelDataLoading={isPanelDataLoading}
                                handleDropDownClose={handleDropDownClose}
                                handleEditClickSecondTable={
                                    handleEditClickSecondTable
                                }
                                hanldeCloseEditSecondTable={
                                    hanldeCloseEditSecondTable
                                }
                                editIdSecondTable={editIdSecondTable}
                                submitSecondaryTableRow={
                                    submitSecondaryTableRow
                                }
                                secondaryRows={row?.transaction_items}
                                setSecondaryRows={setSecondaryRows}
                                addTransactionItem={addTransactionItem}
                                rowIndex={index}
                                secondTableTransactionDetails={
                                    secondTableTransactionDetails
                                }
                                secondTableAmount={secondTableAmount}
                                secondTableQuantity={secondTableQuantity}
                                secondTableCategory={secondTableCategory}
                                errorsSecondTable={errorsSecondTable}
                                handleSecondDescriptionChange={
                                    handleSecondDescriptionChange
                                }
                                handleSecondAmountChange={
                                    handleSecondAmountChange
                                }
                                handleSecondQuantityChange={
                                    handleSecondQuantityChange
                                }
                                handleRemoveTransactionItem={
                                    handleRemoveTransactionItem
                                }
                                handleAddTransactionItem={
                                    handleAddTransactionItem
                                }
                            />
                        </Collapse>
                    )}
                </>
            ))}
        </Stack>
    );
};
