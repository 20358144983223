import { Box, Button, ButtonGroup, Typography } from '@mui/material';

const PaymentOptions = ({ selectedD, setSelectedD }) => (
    <>
        <Box display="flex" justifyContent="start" mt={3} mb={1}>
            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                }}
            >
                How do you want to pay?
            </Typography>
        </Box>

        <Box sx={{ width: { md: '50%', sm: '100%' } }}>
            <ButtonGroup
                fullWidth
                variant="string"
                sx={{ display: 'flex', width: '100%' }}
            >
                <Button
                    onClick={() => setSelectedD('first')}
                    sx={{
                        border:
                            selectedD === 'first'
                                ? '1px solid #1976d2'
                                : 'none',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? (selectedD === 'first' && '#0061DB26') ||
                                  '#394150'
                                : (selectedD === 'first' && '#0061DB26') ||
                                  theme.backgroundColor.secondary,
                        color: 'text.primary',
                        '&:hover': {
                            backgroundColor:
                                selectedD === 'first'
                                    ? '#bbdefb'
                                    : 'rgba(0, 0, 0, 0.04)',
                        },
                        textTransform: 'none',
                    }}
                >
                    Credit card
                </Button>
                <Button
                    onClick={() => setSelectedD('second')}
                    sx={{
                        border:
                            selectedD === 'second'
                                ? '1px solid #1976d2'
                                : 'none',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? (selectedD === 'second' && '#0061DB26') ||
                                  '#394150'
                                : (selectedD === 'second' && '#0061DB26') ||
                                  theme.backgroundColor.secondary,
                        color: 'text.primary',
                        '&:hover': {
                            backgroundColor:
                                selectedD === 'second'
                                    ? '#bbdefb'
                                    : 'rgba(0, 0, 0, 0.04)',
                        },
                        textTransform: 'none',
                    }}
                >
                    Bank transfer
                </Button>
            </ButtonGroup>
        </Box>
    </>
);

export default PaymentOptions;
