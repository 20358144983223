import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export default function TableSkeleton({ numberOfColumns = 5, ...other }) {
    return (
        <TableRow {...other}>
            <TableCell colSpan={12}>
                <Stack spacing={3} direction="row" alignItems="center">
                    {[...Array(numberOfColumns)].map((i, index) => (
                        <>
                            <Skeleton
                                key={index}
                                sx={{ width: 1, height: 12 }}
                            />
                        </>
                    ))}
                </Stack>
            </TableCell>
        </TableRow>
    );
}
