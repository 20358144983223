import {
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import DropDownTable from './DropDownTable';
import { ScanReceiptEditRow } from './ScanReceiptEditRow';
import { ScanReceiptRow } from './ScanReceiptRow';
import TableSkeleton from './table-skeleton';

export const DataTableScanReceipt = ({
    data = [],
    isLoading = false,
    themeMode,
    onEditClick,
    handleCollapse,
    collapse,
    editId,
    handleEditClick,
    onSave,
    description,
    handleDescriptionChange,
    transactionDate,
    setTransactionDate,
    selectedPaymentStatus,
    handlePaymentStatusChange,
    amount,
    handleAmountChange,
    errors,
    isSubmitLoading,
    handleVendorChange,
    vendor,
    handlePaymentDropdownOpen,
    paymentStatuses,
    isPaymentLoading,
    panelData,
    onListItemClick,
    changeDropDownLength,
    isPanelDataLoading,
    openDropDownIndex,
    handleDropDownToggle,
    handleDropDownClose,
    handleEditClickSecondTable,
    hanldeCloseEditSecondTable,
    editIdSecondTable,
    submitSecondaryTableRow,
    setSecondaryRows,
    importPostTransaction,
    handleRemoveTransaction,
    addTransactionItem,
    secondTableTransactionDetails,
    secondTableAmount,
    secondTableQuantity,
    secondTableCategory,
    errorsSecondTable,
    handleSecondDescriptionChange,
    handleSecondAmountChange,
    handleSecondQuantityChange,
    handleRemoveTransactionItem,
    handleAddTransactionItem,
    handleSourceModalOpen,
    isEdit,
}) => {
    return (
        <TableContainer
            sx={{
                width: '100%',
                background: (theme) => theme.backgroundColor.primary,
                overflow: 'auto',
                height: '600px',
                padding: '4px 24px 0px 24px',
                borderRadius: '12px',
                '& .MuiTableCell-root': {
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB',
                    fontSize: '12px',
                },
                '&::-webkit-scrollbar': {
                    width: '0.4rem',
                    height: '0.4rem',
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                    margin: 1,
                    borderRadius: '8px',
                    backgroundColor: (theme) => theme.primary.dark,
                },
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                textAlign: 'left',
                                padding: '16px',
                            }}
                        >
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Date
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                textAlign: 'left',
                                padding: '16px 16px',
                            }}
                        >
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Transaction Details
                            </Typography>
                        </TableCell>
                        {!isEdit && (
                            <TableCell
                                sx={{
                                    textAlign: 'left',
                                    padding: '16px 16px',
                                }}
                            >
                                <Typography
                                    sx={{ fontSize: 14, fontWeight: 500 }}
                                >
                                    Category
                                </Typography>
                            </TableCell>
                        )}
                        <TableCell
                            sx={{
                                textAlign: 'right',
                                padding: '16px',
                            }}
                        >
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Amount
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                textAlign: 'left',
                                padding: '16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                            >
                                Vendor/Client
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                textAlign: 'center',
                                padding: '16px 0',
                            }}
                        >
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Status
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                textAlign: 'center',
                                padding: '16px 0',
                            }}
                        >
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Source
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                textAlign: 'center',
                                padding: '16px 0',
                            }}
                        >
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                Actions
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {isLoading ? (
                    <TableBody>
                        {[...Array(10)].map((i, index) => (
                            <TableSkeleton key={index} numberOfColumns={7} />
                        ))}
                    </TableBody>
                ) : (
                    <TableBody>
                        {data?.length > 0 &&
                            data?.map((row, index) => (
                                <>
                                    {editId === index ? (
                                        <ScanReceiptEditRow
                                            row={row}
                                            index={index}
                                            themeMode={themeMode}
                                            onEditClick={onEditClick}
                                            editId={editId}
                                            handleEditClick={handleEditClick}
                                            handleCollapse={handleCollapse}
                                            isCollapse={collapse === index}
                                            onSave={onSave}
                                            description={description}
                                            handleDescriptionChange={
                                                handleDescriptionChange
                                            }
                                            transactionDate={transactionDate}
                                            setTransactionDate={
                                                setTransactionDate
                                            }
                                            selectedPaymentStatus={
                                                selectedPaymentStatus
                                            }
                                            handlePaymentStatusChange={
                                                handlePaymentStatusChange
                                            }
                                            amount={amount}
                                            handleAmountChange={
                                                handleAmountChange
                                            }
                                            errors={errors}
                                            isSubmitLoading={isSubmitLoading}
                                            handleVendorChange={
                                                handleVendorChange
                                            }
                                            vendor={vendor}
                                            handlePaymentDropdownOpen={
                                                handlePaymentDropdownOpen
                                            }
                                            paymentStatuses={paymentStatuses}
                                            isPaymentLoading={isPaymentLoading}
                                        />
                                    ) : (
                                        <ScanReceiptRow
                                            isEdit={isEdit}
                                            row={row}
                                            index={index}
                                            themeMode={themeMode}
                                            onEditClick={onEditClick}
                                            editId={editId}
                                            handleEditClick={handleEditClick}
                                            handleCollapse={handleCollapse}
                                            isCollapse={collapse === index}
                                            importPostTransaction={
                                                importPostTransaction
                                            }
                                            handleRemoveTransaction={
                                                handleRemoveTransaction
                                            }
                                            handleSourceModalOpen={
                                                handleSourceModalOpen
                                            }
                                        />
                                    )}
                                    {(isEdit ||
                                        !!row?.transaction_items?.length) && (
                                        <TableRow>
                                            <TableCell
                                                sx={{ p: 0, border: 'none' }}
                                                colSpan={8}
                                            >
                                                <Collapse
                                                    in={collapse === index}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    sx={{
                                                        bgcolor:
                                                            'background.neutral',
                                                    }}
                                                >
                                                    <DropDownTable
                                                        isEdit={isEdit}
                                                        parentRow={row}
                                                        themeMode={themeMode}
                                                        panelData={panelData}
                                                        onListItemClick={
                                                            onListItemClick
                                                        }
                                                        changeDropDownLength={
                                                            changeDropDownLength
                                                        }
                                                        handleDropDownToggle={
                                                            handleDropDownToggle
                                                        }
                                                        openDropDownIndex={
                                                            openDropDownIndex
                                                        }
                                                        isPanelDataLoading={
                                                            isPanelDataLoading
                                                        }
                                                        handleDropDownClose={
                                                            handleDropDownClose
                                                        }
                                                        handleEditClickSecondTable={
                                                            handleEditClickSecondTable
                                                        }
                                                        hanldeCloseEditSecondTable={
                                                            hanldeCloseEditSecondTable
                                                        }
                                                        editIdSecondTable={
                                                            editIdSecondTable
                                                        }
                                                        submitSecondaryTableRow={
                                                            submitSecondaryTableRow
                                                        }
                                                        secondaryRows={
                                                            row?.transaction_items
                                                        }
                                                        setSecondaryRows={
                                                            setSecondaryRows
                                                        }
                                                        addTransactionItem={
                                                            addTransactionItem
                                                        }
                                                        rowIndex={index}
                                                        secondTableTransactionDetails={
                                                            secondTableTransactionDetails
                                                        }
                                                        secondTableAmount={
                                                            secondTableAmount
                                                        }
                                                        secondTableQuantity={
                                                            secondTableQuantity
                                                        }
                                                        secondTableCategory={
                                                            secondTableCategory
                                                        }
                                                        errorsSecondTable={
                                                            errorsSecondTable
                                                        }
                                                        handleSecondDescriptionChange={
                                                            handleSecondDescriptionChange
                                                        }
                                                        handleSecondAmountChange={
                                                            handleSecondAmountChange
                                                        }
                                                        handleSecondQuantityChange={
                                                            handleSecondQuantityChange
                                                        }
                                                        handleRemoveTransactionItem={
                                                            handleRemoveTransactionItem
                                                        }
                                                        handleAddTransactionItem={
                                                            handleAddTransactionItem
                                                        }
                                                    />
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </>
                            ))}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};
