import React, { useCallback } from 'react';
import BrandYourInvoice from './BrandYourInvoice';
import Clients from './Clients';
import ListOfServices from './ListOfServices';
import PaymentPreferences from './PaymentPreferences';
import TaxDetails from './TaxDetails';

function InvoiceSetting({ generalSettings }) {
    const [syncStatus, setSyncStatus] = React.useState(() => {
        const savedStatus = localStorage?.getItem('syncStatus');
        return savedStatus ? savedStatus : 'one';
    });

    React.useEffect(() => {
        localStorage?.setItem('syncStatus', syncStatus);
    }, [syncStatus]);

    const handleSyncAll = useCallback((id) => {
        setSyncStatus(id);
    }, []);

    return (
        <>
            {syncStatus === 'one' ? (
                <BrandYourInvoice onSyncAll={handleSyncAll} />
            ) : syncStatus === 'two' ? (
                <PaymentPreferences onSyncAll={handleSyncAll} />
            ) : syncStatus === 'three' ? (
                <ListOfServices onSyncAll={handleSyncAll} />
            ) : syncStatus === 'four' ? (
                <Clients onSyncAll={handleSyncAll} />
            ) : syncStatus === 'five' ? (
                <TaxDetails
                    onSyncAll={handleSyncAll}
                    generalSettings={generalSettings}
                />
            ) : null}
        </>
    );
}

export default InvoiceSetting;
