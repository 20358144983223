import { Box } from '@mui/material';
import { Upload } from 'components/upload';

export default function FileNewFolderDialog({
    title = 'Upload Files',
    files,
    handleDrop,
    handleRemoveFile,
    isUploadLoading,
    ...other
}) {
    return (
        <Box width="100%" {...other} marginTop={4}>
            <Upload
                multiple
                accept={{
                    'image/jpeg': [],
                    'image/png': [],
                    'application/pdf': [],
                }}
                files={files}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}
                disabled={isUploadLoading}
            />
        </Box>
    );
}
