import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

function NewInvoicePopup({ open, onClose, onSyncAll, loading, data }) {
    const [name, setName] = useState('');

    useEffect(() => {
        if (data) {
            setName(data.name || '');
        }
    }, [data]);

    const handleNameChange = useCallback((event) => {
        setName(event.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        if (!name) {
            return;
        }
        onSyncAll(name);
        onClose();
    }, [name, onClose, onSyncAll]);

    const handleClose = useCallback(() => {
        setName('');
        onClose();
    }, [onClose]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            maxWidth="xs"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '8px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                id="dialog-title"
                sx={{
                    padding: '28px 24px 0 24px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    New Invoice
                </Typography>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '8px',
                    padding: '8px 24px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#555' : '#888',
                        borderRadius: '8px',
                        border: '2px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#777' : '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#2D3B4E'
                                : '#f1f1f1',
                        borderRadius: '8px',
                    },
                }}
            >
                <TextField
                    fullWidth
                    size="small"
                    value={name}
                    onChange={handleNameChange}
                    label="Invoice name"
                    variant="outlined"
                    required
                    sx={{
                        marginTop: 3,
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: '1px solid #0061DB',
                            },
                            '&.Mui-focused fieldset': {
                                border: '1px solid #0061DB',
                            },
                        },
                    }}
                />
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '20px 24px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Button
                    onClick={handleClose}
                    fullWidth
                    sx={{
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '2px solid #394150'
                                : '1px solid #E5E6EB',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#9EA3AE'
                                : '#6C727F',
                        borderRadius: '8px',
                        textTransform: 'none',
                        padding: '8px 16px',
                        '&:hover': {
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#3E5060'
                                    : '#f5f5f5',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#0056b3',
                            '@media (hover: hover)': {
                                backgroundColor: '#0056b3',
                            },
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#436ecc',
                            color: 'white',
                        },
                    }}
                >
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        'Create Invoice'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewInvoicePopup;
