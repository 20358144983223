import { Box, FormHelperText, InputLabel, Typography } from '@mui/material';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import { useCallback, useState } from 'react';

const styles = {
    label: {
        display: 'flex',
        fontSize: 13,
        fontWeight: 600,
        paddingBottom: 1,
        color: 'text.primary',
        lineHeight: '15.6px',
    },
    phoneNumber: {
        background: '#E5E6EB',
        borderRadius: 2,
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                    ? '#394150'
                    : theme.backgroundColor.secondary,
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
            },
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontWeight: 600,
            ml: 0,
            textTransform: 'initial',
        },
    },
};
export const PhoneNumber = ({
    onChange,
    name,
    value,
    label,
    disabled,
    disableDropdown,
    customLabelStyles,
}) => {
    const [error, setError] = useState(false);
    const handlePhoneNumberChange = useCallback(
        (e) => {
            const countryCode = e?.split(' ')?.[0];
            if (countryCode !== '+1') {
                setError(true);
                return;
            } else {
                setError(false);
                onChange({ target: { name: 'phoneNumber', value: e } });
            }
        },
        [onChange, setError],
    );
    return (
        <Box display="flex" flexDirection="column">
            {label && (
                <InputLabel sx={{ ...styles.label, ...customLabelStyles }}>
                    {label}
                </InputLabel>
            )}
            <MaterialUiPhoneNumber
                name={name}
                disabled={disabled}
                countryCodeEditable={false}
                disableAreaCodes={true}
                // onlyCountries={['us', 'ca']}
                disableDropdown={disableDropdown}
                defaultCountry="us"
                variant="outlined"
                sx={styles.phoneNumber}
                value={value}
                onChange={handlePhoneNumberChange}
            />
            {error && (
                <FormHelperText>
                    <Typography padding={1} paddingBottom={0} color="#1976d2">
                        Heads up! Bank connection is supported in CA, US only.
                    </Typography>
                    <Typography
                        padding={1}
                        paddingTop={0}
                        paddingBottom={0}
                        color="#1976d2"
                    >
                        But no worries—our receipt scanner is fully available no
                        matter where you're located.
                    </Typography>
                </FormHelperText>
            )}
        </Box>
    );
};
