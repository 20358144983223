import React, { StrictMode } from 'react';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import styled from '@emotion/styled';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './hooks/useAuth';
import { ThemeToggleProvider } from './hooks/useThemeToggle';
import { NonAccountantModeProvider } from './hooks/useNonAccountantMode';
import { persistor, store } from './store';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#36873D',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#B62916',
    },
}));

root.render(
    <StrictMode>
        <BrowserRouter>
            <SnackbarProvider
                maxSnack={1}
                style={{
                    fontFamily: 'Inter',
                }}
                Components={{
                    success: StyledMaterialDesignContent,
                    error: StyledMaterialDesignContent,
                }}
            >
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <AuthProvider>
                            <NonAccountantModeProvider>
                                <ThemeToggleProvider>
                                    <App />
                                </ThemeToggleProvider>
                            </NonAccountantModeProvider>
                        </AuthProvider>
                    </PersistGate>
                </Provider>
            </SnackbarProvider>
        </BrowserRouter>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
