import { DateRangePicker as DateRPicker } from 'materialui-daterange-picker';
import format from 'date-fns/format';

export const DateRangePicker = ({ open, toggle, setDateRange }) => {
    const getStartOfWeek = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - dayOfWeek);
        startOfWeek.setHours(0, 0, 0, 0);
        return startOfWeek;
    };

    const getLastMonth = () => {
        const today = new Date();
        const firstDayOfCurrentMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
        );
        const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth);
        lastDayOfLastMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);

        return {
            startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1),
            endDate: lastDayOfLastMonth,
            label: 'Last Month',
            // Format the dates using 'yyyy-MM-dd'
            formattedStartDate: format(
                new Date(today.getFullYear(), today.getMonth() - 1, 1),
                'yyyy-MM-dd',
            ),
            formattedEndDate: format(lastDayOfLastMonth, 'yyyy-MM-dd'),
        };
    };

    const getEndOfWeek = () => {
        const startOfWeek = getStartOfWeek();
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999);
        return endOfWeek;
    };

    const getLastQuarter = () => {
        const today = new Date();
        const currentMonth = today.getMonth();
        const firstMonthOfLastQuarter = currentMonth - ((currentMonth - 1) % 3);
        const lastDayOfLastQuarter = new Date(
            today.getFullYear(),
            firstMonthOfLastQuarter + 2,
            0,
        );

        return {
            startDate: new Date(
                today.getFullYear(),
                firstMonthOfLastQuarter - 1,
                1,
            ),
            endDate: lastDayOfLastQuarter,
            label: 'Last Quarter',
            // Format the dates using 'yyyy-MM-dd'
            formattedStartDate: format(
                new Date(today.getFullYear(), firstMonthOfLastQuarter - 1, 1),
                'yyyy-MM-dd',
            ),
            formattedEndDate: format(lastDayOfLastQuarter, 'yyyy-MM-dd'),
        };
    };

    const getLastYear = () => {
        const today = new Date();
        return {
            startDate: new Date(today.getFullYear() - 1, 0, 1),
            endDate: new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59, 999),
            label: 'Last Year',
            // Format the dates using 'yyyy-MM-dd'
            formattedStartDate: format(
                new Date(today.getFullYear() - 1, 0, 1),
                'yyyy-MM-dd',
            ),
            formattedEndDate: format(
                new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59, 999),
                'yyyy-MM-dd',
            ),
        };
    };

    const lastWeek = {
        startDate: getStartOfWeek(),
        endDate: getEndOfWeek(),
        label: 'Last Week',
        // Format the dates using 'yyyy-MM-dd'
        formattedStartDate: format(getStartOfWeek(), 'yyyy-MM-dd'),
        formattedEndDate: format(getEndOfWeek(), 'yyyy-MM-dd'),
    };
    return (
        <DateRPicker
            wrapperClassName={'custom-date-range-wrapper'}
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
            definedRanges={[
                lastWeek,
                getLastMonth(),
                getLastQuarter(),
                getLastYear(),
            ]}
        />
    );
};
