import { Box, useMediaQuery } from '@mui/material';
import React, { useCallback } from 'react';
import AddServiceInvoice from './AddServiceInvoice';
import PreviewInvoice from './Components/InvoicePreview/PreviewInvoice';
import PreviewPayment from './Components/InvoicePreview/PreviewPayment';
import PreviewTop from './Components/InvoicePreview/PreviewTop';
import PaymentDue from './PaymentDue';

function Invoice() {
    const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [syncStatus, setSyncStatus] = React.useState('one');
    const [tab, setTab] = React.useState(0);

    const handleSyncAll = useCallback((id) => {
        setSyncStatus(id);
    }, []);

    const handleTabChang = useCallback((id) => {
        setTab(id);
    }, []);

    return (
        <>
            {syncStatus === 'preview one' || syncStatus === 'preview two' ? (
                <PreviewTop
                    onTabChange={handleTabChang}
                    onSyncAll={handleSyncAll}
                    syncStatus={syncStatus}
                />
            ) : (
                ''
            )}
            <Box
                width="100%"
                p={3}
                sx={{ padding: isMdDown ? '15px' : '30px' }}
            >
                {syncStatus === 'one' ? (
                    <AddServiceInvoice onSyncAll={handleSyncAll} />
                ) : syncStatus === 'two' ? (
                    <PaymentDue onSyncAll={handleSyncAll} invoiceStep={true} />
                ) : syncStatus === 'preview one' ? (
                    <PreviewInvoice
                        tab={tab}
                        onSyncAll={handleSyncAll}
                        isSingle={false}
                    />
                ) : syncStatus === 'preview two' ? (
                    <PreviewPayment onSyncAll={handleSyncAll} tab={tab} />
                ) : (
                    ''
                )}
            </Box>
        </>
    );
}

export default Invoice;
