import {
    Box,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import BankReconcileGreen from 'assets/icons/bakReconcileGreen.svg';
import BankReconcile from 'assets/icons/bankReconcile.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { fetchMatchedTransactionsRequest } from 'store/actions';
import { formatCurrencyAmount } from 'utils/helpers';

function MatchedReconciliation({ accountId }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { MatchedTransactions } = useSelector((state) => state.bank);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const reconcileBalanceSummary = useSelector(
        (state) => state.bank.reconcileBalance,
    );

    useEffect(() => {
        const { token } = store.getState().auth;
        const fetchData = async () => {
            try {
                const page = 1;
                const pageSize = 5;

                dispatch(
                    fetchMatchedTransactionsRequest({
                        reconciliationId:
                            reconcileBalanceSummary?.bank_reconciliation_id,
                        page,
                        pageSize,
                        token,
                    }),
                );
            } catch (error) {
                console.error('Error fetching user company data:', error);
            }
        };

        if (reconcileBalanceSummary?.bank_reconciliation_id) fetchData();
    }, [dispatch, reconcileBalanceSummary?.bank_reconciliation_id]);

    const Container = styled(Box)(({ theme, display }) => ({
        display: display,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        padding: '20px',
        backgroundColor: theme.palette.mode === 'dark' ? '#212936' : 'white',
        borderRadius: '12px',
        marginLeft: '1px',
        marginTop: '20px',
    }));

    const Column = styled(Box)(({ theme, width }) => ({
        width: width,
        backgroundColor: theme.palette.mode === 'dark' ? '#212936' : '#FFFFFF',
        padding: '16px',
        borderRadius: '8px',
    }));

    const ItemBox = styled(Box)(({ theme }) => ({
        border: `1px solid ${
            theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'
        }`,
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    }));

    const AmountTypography = styled(Typography)(({ color }) => ({
        color: color === 'positive' ? '#0A8C4D' : '#F75555',
        fontWeight: 600,
    }));

    return (
        <Box width="100%">
            <Container sx={{ display: { xs: 'block', md: 'flex' } }}>
                <Column sx={{ width: { xs: '100%', md: '48%' } }}>
                    <Typography
                        sx={{
                            fontWeight: '700',
                            fontSize: '18px',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#F9FAFB'
                                    : '#121826',
                            marginBottom: '15px',
                        }}
                    >
                        Unreconciled bank Transactions
                    </Typography>
                    {MatchedTransactions?.results?.map((item) => (
                        <ItemBox key={item.id}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#121826',
                                    }}
                                >
                                    {item?.matched_transaction?.date}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#121826',
                                    }}
                                >
                                    {
                                        item?.matched_transaction
                                            ?.counterparty_name
                                    }
                                </Typography>
                            </Box>
                            <AmountTypography
                                color={
                                    item?.matched_transaction?.transaction_total?.startsWith(
                                        '-',
                                    )
                                        ? 'negative'
                                        : 'positive'
                                }
                            >
                                $
                                {formatCurrencyAmount(
                                    item?.matched_transaction
                                        ?.transaction_total || 0,
                                )}
                            </AmountTypography>
                        </ItemBox>
                    ))}
                </Column>

                {!isSmallScreen && (
                    <Box
                        sx={{
                            width: { xs: '100%', md: '4%' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '8px',
                            marginTop: '60px',
                            gap: 2,
                        }}
                    >
                        {MatchedTransactions?.results?.map((item, index) => (
                            <Box
                                height={80}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor:
                                            item?.amount !=
                                            item?.matched_transaction
                                                ?.transaction_total
                                                ? '#fce8e6'
                                                : '#CEEFDF',
                                        padding: '6px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <img
                                        src={
                                            item?.amount !=
                                            item?.matched_transaction
                                                ?.transaction_total
                                                ? BankReconcile
                                                : BankReconcileGreen
                                        }
                                        alt="Icon"
                                        style={{
                                            width: 24,
                                            height: 24,
                                        }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}

                <Column sx={{ width: { xs: '100%', md: '48%' } }}>
                    <Typography
                        sx={{
                            fontWeight: '700',
                            fontSize: '18px',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#F9FAFB'
                                    : '#121826',
                            marginBottom: '15px',
                        }}
                    >
                        Circler Transactions
                    </Typography>
                    {MatchedTransactions?.results?.map((item) => (
                        <ItemBox key={item.id}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#121826',
                                    }}
                                >
                                    {item.date}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#121826',
                                    }}
                                >
                                    {item?.category_primary}
                                </Typography>
                            </Box>
                            <AmountTypography
                                color={
                                    item.amount.startsWith('-')
                                        ? 'negative'
                                        : 'positive'
                                }
                            >
                                ${formatCurrencyAmount(item?.amount || 0)}
                            </AmountTypography>
                        </ItemBox>
                    ))}
                </Column>
            </Container>
        </Box>
    );
}

export default MatchedReconciliation;
