/* eslint-disable no-unused-vars */
import { Grid, Stack, Typography as MuiTypography } from '@mui/material';
import { Modal, Typography } from 'shared';
import { useChatService } from 'store/services/chat.service';
import { ReactComponent as CirclerIcon } from 'assets/icons/Icon.svg';
import { ReactComponent as Attachments } from 'assets/icons/Attachments.svg';
import { ReactComponent as UploadPreview } from 'assets/icons/UploadPreview.svg';
import { ReactComponent as CirclerIconDarkMode } from 'assets/icons/CirclerIconDarkMode.svg';
import { format } from 'date-fns';

export const SourceModal = ({
    isModalOpen,
    handleCloseModal,
    isScreenLowerThan1024,
    files,
    companyId,
    themeMode,
}) => {
    const { getFileAccess } = useChatService();
    const handleViewFile = (fileUrl) => {
        getFileAccess(fileUrl, companyId)
            .then((res) => {
                window.open(res.signedUrl);
            })
            .catch((err) => {});
    };
    return (
        <Grid container>
            <Modal
                fullWidth
                title={
                    <Typography
                        text={'Source'}
                        customStyles={{
                            fontSize: isScreenLowerThan1024 ? '24px' : '32px',
                            fontWeight: 700,
                        }}
                    />
                }
                cancelBtnText={
                    <Typography
                        text={'Close'}
                        customStyles={{
                            color: '#fff',
                            fontSize: 18,
                            fontWeight: 500,
                        }}
                    />
                }
                showImprovementText={false}
                open={isModalOpen}
                handleClose={handleCloseModal}
                customCancelBtnStyle={{
                    backgroundColor: '#0061DB !important',
                }}
            >
                <Stack direction="column" gap={3}>
                    {!!files &&
                        files.map((attachment) => (
                            <Stack
                                position="relative"
                                direction="row"
                                alignItems="center"
                                p={1}
                                gap={1}
                                border={1}
                                borderColor="#0061DB"
                                borderRadius={2}
                                onClick={() =>
                                    !!attachment?.file_url
                                        ? handleViewFile(attachment?.file_url)
                                        : null
                                }
                                sx={{
                                    borderTopRightRadius: 0,
                                    cursor: !!attachment?.file_url
                                        ? 'pointer'
                                        : 'default',
                                }}
                            >
                                <Attachments />
                                <Stack
                                    alignItems="flex-start"
                                    spacing={0}
                                    gap={0}
                                >
                                    <MuiTypography
                                        variant="subtitle2"
                                        color={
                                            themeMode === 'dark'
                                                ? '#fff'
                                                : '#202020'
                                        }
                                        textTransform="none"
                                    >
                                        {attachment?.title}
                                    </MuiTypography>
                                    <MuiTypography
                                        position="absolute"
                                        bottom={-20}
                                        right={0}
                                        variant="subtitle2"
                                        color="text.disabled"
                                        textTransform="none"
                                        fontSize={12}
                                    >
                                        {format(
                                            new Date(attachment?.uploaded_at),
                                            'MM-dd-yyyy, hh:mm',
                                        )}
                                    </MuiTypography>
                                </Stack>
                            </Stack>
                        ))}
                </Stack>
            </Modal>
        </Grid>
    );
};
