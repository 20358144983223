// sagas/bankSagas.js
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    brandInvoice,
    deleteBrandInvoice,
    createClient,
    createInvoice,
    createPaymentIntent,
    createStripeAccountLink,
    createTax,
    deleteClient,
    deleteInvoice,
    deleteService,
    deleteTax,
    fetchClients,
    fetchInvoiceById,
    fetchInvoiceLogo,
    fetchInvoices,
    fetchServices,
    fetchTaxes,
    sendEmail,
    serviceInvoice,
    updateInvoice,
    updateTax,
} from '../services/invoice.Service';
import {
    brandInvoiceRequest,
    brandInvoiceSuccess,
    brandInvoiceFailure,
    serviceInvoiceSuccess,
    serviceInvoiceFailure,
    serviceInvoiceRequest,
    createClientSuccess,
    createClientFailure,
    createClientRequest,
    createTaxSuccess,
    createTaxFailure,
    createTaxRequest,
    fetchClientsSuccess,
    fetchClientsFailure,
    fetchClientsRequest,
    createInvoiceSuccess,
    createInvoiceFailure,
    createInvoiceRequest,
    fetchServicesSuccess,
    fetchServicesFailure,
    fetchServicesRequest,
    updateTaxSuccess,
    updateTaxFailure,
    updateTaxRequest,
    deleteTaxSuccess,
    deleteTaxFailure,
    deleteTaxRequest,
    updateInvoiceSuccess,
    updateInvoiceFailure,
    updateInvoiceRequest,
    fetchInvoiceLogoSuccess,
    fetchInvoiceLogoFailure,
    fetchInvoiceLogoRequest,
    fetchInvoicesSuccess,
    fetchInvoicesFailure,
    fetchInvoicesRequest,
    deleteInvoiceSuccess,
    deleteInvoiceFailure,
    deleteInvoiceRequest,
    fetchTaxesSuccess,
    fetchTaxesFailure,
    fetchTaxesRequest,
    createStripeAccountLinkSuccess,
    createStripeAccountLinkFailure,
    createStripeAccountLinkRequest,
    createPaymentIntentSuccess,
    createPaymentIntentFailure,
    createPaymentIntentRequest,
    fetchInvoiceByIdSuccess,
    fetchInvoiceByIdFailure,
    fetchInvoiceByIdRequest,
    sendEmailSuccess,
    sendEmailFailure,
    sendEmailRequest,
    setInvoiceServices,
    setClientState,
    deleteInvoiceService,
    appendInvoiceServices,
    appendInvoiceTaxes,
    deleteClientRequest,
    appendInvoiceNewService,
    deleteBrandInvoiceRequest,
} from '../slices/invoiceSlice';
import { SET_INVOICE_ID } from 'store/actions';
import { getServicesFormatted, updateServicesWithTaxes } from 'utils/helpers';

function* brandInvoiceSaga(action) {
    const { companyId, token, color, image, id } = action.payload;
    try {
        const accounts = yield call(
            brandInvoice,
            companyId,
            token,
            color,
            image,
            id,
        );
        yield put(brandInvoiceSuccess(accounts));
    } catch (error) {
        yield put(brandInvoiceFailure(error.message));
    }
}

function* deleteBranchInvoiceSaga(action) {
    const { token, id } = action.payload;
    try {
        const accounts = yield call(deleteBrandInvoice, token, id);
        yield put(brandInvoiceSuccess(accounts));
    } catch (error) {
        yield put(brandInvoiceFailure(error.message));
    }
}

function* serviceInvoiceSaga(action) {
    const { name, details, price, company, token, quantity, serviceId, taxed } =
        action.payload;
    try {
        const response = yield call(
            serviceInvoice,
            name,
            details,
            price,
            company,
            token,
        );
        if (quantity) {
            const newService = {
                name: response?.name,
                serviceId: response?.id,
                unitPrice: parseFloat(response?.price) || 0,
                details: response?.details,
                item: 'Item',
                total: parseFloat(response?.price) * quantity,
                qty: quantity,
                taxed,
            };
            yield put(appendInvoiceNewService(newService));
        }
        yield put(serviceInvoiceSuccess(response));
        yield put(appendInvoiceServices(response));
    } catch (error) {
        yield put(serviceInvoiceFailure(error.message));
    }
}

function* createClientSaga(action) {
    const { clientData, token } = action.payload;
    try {
        const response = yield call(createClient, clientData, token);
        yield put(createClientSuccess(response));
    } catch (error) {
        yield put(createClientFailure(error.message));
    }
}

function* updateTaxSaga(action) {
    const { taxId, token, taxData } = action.payload;
    try {
        const updatedTax = yield call(updateTax, taxId, token, taxData);
        yield put(updateTaxSuccess(updatedTax));
    } catch (error) {
        yield put(updateTaxFailure(error.message));
    }
}

function* createTaxSaga(action) {
    const {
        token,
        taxData,
        generalSettings,
        existingTaxesId,
        isInvoiceToolbar,
    } = action.payload;
    try {
        const tax = yield call(createTax, token, taxData);
        yield put(createTaxSuccess(tax));
        if (!generalSettings && existingTaxesId) {
            const { payload, token, invoiceId } = existingTaxesId;
            const taxes = [...payload?.taxes, tax?.id];
            const services = payload?.invoice_services?.map((service) => ({
                ...service,
                ...(tax?.unit === '$'
                    ? { taxed: [...service?.taxed, tax?.id] }
                    : {}),
            }));
            const response = yield call(
                updateInvoice,
                { taxes: [...taxes], invoice_services: [...services] },
                token,
                invoiceId,
            );
        }
        if (isInvoiceToolbar) {
            yield put(appendInvoiceTaxes(tax));
            const newTaxes = yield select(
                (state) => state?.invoice?.singleInvoice?.taxes,
            );
            const services = yield select(
                (state) => state?.invoice?.invoiceServices,
            );
            yield put(
                setInvoiceServices(updateServicesWithTaxes(services, newTaxes)),
            );
        }
    } catch (error) {
        yield put(createTaxFailure(error.message));
    }
}

function* fetchClientsSaga(action) {
    const { token } = action.payload;
    try {
        const clients = yield call(fetchClients, token);
        yield put(fetchClientsSuccess(clients));
    } catch (error) {
        yield put(fetchClientsFailure(error.message));
    }
}

function* deleteClientSaga(action) {
    const { token, clientId } = action.payload;
    try {
        const clients = yield call(deleteClient, clientId, token);
    } catch (error) {}
}

function* createInvoiceSaga(action) {
    const { invoiceData, token, navigate } = action.payload;
    try {
        const response = yield call(createInvoice, invoiceData, token);
        yield put(createInvoiceSuccess(response));
        yield put(SET_INVOICE_ID(response?.id));
        navigate('/invoice');
    } catch (error) {
        yield put(createInvoiceFailure(error.message));
    }
}

function* viewInvoiceByIDSaga(action) {
    try {
        const { id, navigate } = action?.payload;
        yield put(SET_INVOICE_ID(id));
        navigate('/invoice');
    } catch (error) {
        // yield put(createInvoiceFailure(error.message));
    }
}

function* fetchServicesSaga(action) {
    const { token } = action.payload;
    try {
        const response = yield call(fetchServices, token);
        yield put(fetchServicesSuccess(response));
    } catch (error) {
        yield put(fetchServicesFailure(error.message));
    }
}

function* deleteTaxSaga(action) {
    const { taxId, token } = action.payload;
    try {
        yield call(deleteTax, taxId, token);
        yield put(deleteTaxSuccess(taxId));
    } catch (error) {
        yield put(deleteTaxFailure(error.message));
    }
}

function* deleteInvoiceServiceSaga(action) {
    const { serviceId, token } = action.payload;
    try {
        yield call(deleteService, serviceId, token);
        yield put(deleteInvoiceService(serviceId));
    } catch (error) {
        yield put(deleteTaxFailure(error.message));
    }
}

function* updateInvoiceSaga(action) {
    const { payload, token, invoiceId } = action.payload;
    try {
        const response = yield call(updateInvoice, payload, token, invoiceId);
        yield put(updateInvoiceSuccess(response));
    } catch (error) {
        yield put(updateInvoiceFailure(error.message));
    }
}

function* fetchInvoiceLogoSaga(action) {
    const { token } = action.payload;
    try {
        const response = yield call(fetchInvoiceLogo, token);
        yield put(fetchInvoiceLogoSuccess(response?.[0] || response));
    } catch (error) {
        yield put(fetchInvoiceLogoFailure(error.message));
    }
}

function* fetchInvoicesSaga(action) {
    const { token, setIsLoading } = action.payload;
    try {
        const response = yield call(fetchInvoices, token);
        yield put(fetchInvoicesSuccess(response));
    } catch (error) {
        yield put(fetchInvoicesFailure(error.message));
    } finally {
        if (typeof setIsLoading === 'function') {
            setIsLoading(false);
        }
    }
}

function* deleteInvoiceSaga(action) {
    try {
        const { invoiceId, token } = action.payload;
        yield call(deleteInvoice, invoiceId, token);
        yield put(deleteInvoiceSuccess(invoiceId));
    } catch (error) {
        yield put(deleteInvoiceFailure(error.message));
    }
}

function* fetchTaxesSaga(action) {
    try {
        const { token } = action.payload;
        const response = yield call(fetchTaxes, token);
        yield put(fetchTaxesSuccess(response));
    } catch (error) {
        yield put(fetchTaxesFailure(error.message));
    }
}

function* createStripeAccountLinkSaga(action) {
    const { companyId, token, openSite, setAlertOpen } = action.payload;
    try {
        const response = yield call(createStripeAccountLink, companyId, token);
        openSite(response?.url);
        yield put(createStripeAccountLinkSuccess(response));
    } catch (error) {
        setAlertOpen(true);
        yield put(
            createStripeAccountLinkFailure(
                error.response?.data?.detail || error.message,
            ),
        );
    }
}

function* createPaymentIntentSaga(action) {
    const { invoiceId, token, payment_method } = action.payload;
    try {
        const response = yield call(
            createPaymentIntent,
            invoiceId,
            token,
            payment_method,
        );
        yield put(createPaymentIntentSuccess(response));
    } catch (error) {
        yield put(createPaymentIntentFailure(error.message));
    }
}

function* fetchInvoiceByIdSaga(action) {
    const { invoiceId } = action.payload;
    try {
        const response = yield call(fetchInvoiceById, invoiceId);
        yield put(fetchInvoiceByIdSuccess(response));
        const { newServices, client } = getServicesFormatted(response);
        yield put(setInvoiceServices(newServices));
        yield put(setClientState(client));
    } catch (error) {
        yield put(fetchInvoiceByIdFailure(error.message));
    }
}

function* sendEmailSaga(action) {
    const { emailData, token } = action.payload;
    try {
        yield call(sendEmail, emailData, token);
        yield put(sendEmailSuccess());
    } catch (error) {
        yield put(sendEmailFailure(error.message));
    }
}

export function* watchBrandInvoiceSaga() {
    yield takeLatest(brandInvoiceRequest.type, brandInvoiceSaga);
    yield takeLatest(deleteBrandInvoiceRequest.type, deleteBranchInvoiceSaga);
    yield takeLatest(serviceInvoiceRequest.type, serviceInvoiceSaga);
    yield takeLatest(createClientRequest.type, createClientSaga);
    yield takeLatest(createTaxRequest.type, createTaxSaga);
    yield takeLatest(fetchClientsRequest.type, fetchClientsSaga);
    yield takeLatest(deleteClientRequest.type, deleteClientSaga);
    yield takeLatest(createInvoiceRequest.type, createInvoiceSaga);
    yield takeLatest('VIEW_INVOICE_BY_ID_REQUEST', viewInvoiceByIDSaga);
    yield takeLatest(fetchServicesRequest.type, fetchServicesSaga);
    yield takeLatest(updateTaxRequest.type, updateTaxSaga);
    yield takeLatest(deleteTaxRequest.type, deleteTaxSaga);
    yield takeLatest(deleteInvoiceService.type, deleteInvoiceServiceSaga);
    yield takeLatest(updateInvoiceRequest.type, updateInvoiceSaga);
    yield takeLatest(fetchInvoiceLogoRequest.type, fetchInvoiceLogoSaga);
    yield takeLatest(fetchInvoicesRequest.type, fetchInvoicesSaga);
    yield takeLatest(deleteInvoiceRequest.type, deleteInvoiceSaga);
    yield takeLatest(fetchTaxesRequest.type, fetchTaxesSaga);
    yield takeLatest(
        createStripeAccountLinkRequest.type,
        createStripeAccountLinkSaga,
    );
    yield takeLatest(createPaymentIntentRequest.type, createPaymentIntentSaga);
    yield takeLatest(fetchInvoiceByIdRequest.type, fetchInvoiceByIdSaga);
    yield takeLatest(sendEmailRequest.type, sendEmailSaga);
}
