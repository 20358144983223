import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Drawer } from 'components/drawer';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { inputEndingBalanceRequest } from 'store/actions';
import {
    downloadAccountStatements,
    fetchAccountStatement,
} from 'store/services/bank.Service';
import { downloadPdf } from 'utils/helpers';
const todayDate = dayjs(new Date()).format('YYYY-MM-DD');

function ContinueReconcile({ onSyncAll, account }) {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [statementBalance, setStatementBalance] = useState(() =>
        account?.latest_reconciliation?.id
            ? account?.latest_reconciliation?.ending_balance
            : '',
    );
    const [statementBeginningBalance, setStatementBeginningBalance] = useState(
        () =>
            account?.latest_reconciliation?.id
                ? account?.latest_reconciliation?.beginning_balance
                : '',
    );

    const [statementDate, setStatementDate] = useState(() => {
        if (account?.latest_reconciliation?.statement_date) {
            return dayjs(account.latest_reconciliation.statement_date);
            // if (account?.latest_reconciliation?.statement_date) {
            //     return dayjs(account.latest_reconciliation.statement_date).add(
            //         1,
            //         'month',
            //     );
        } else {
            return dayjs(todayDate);
        }
    });
    const [balanceError, setBalanceError] = useState(false);
    const [beginningBalanceError, setBeginningBalanceError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isInputLoading, setIsInputLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [type, setType] = useState('statements');
    const [accounts2, setAccounts2] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const handleDownload = useCallback(async (accountId) => {
        const { token } = store.getState().auth;
        try {
            setIsLoading(true);
            const response = await fetchAccountStatement(accountId, token);
            if (response?.length) {
                setAccounts(response);
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleDownloadStatementFiles = useCallback(async () => {
        const { token } = store.getState().auth;

        debugger
        try {
            setIsDownloadLoading(true);
            const response = await downloadAccountStatements(
                token,
                account?.id,
            );
            if (response?.downloaded_statements?.length) {
                response?.downloaded_statements?.forEach((statement) =>
                    downloadPdf(
                        statement?.statement_file,
                        `statement_${statement?.month}_${statement?.year}`,
                    ),
                );
                setSnackbarOpen({
                    type: 'success',
                    message: response?.message,
                });
            } else {
                setSnackbarOpen({
                    type:
                        response?.message !== 'No new statement downloaded.'
                            ? 'success'
                            : 'error',
                    message: response?.message,
                });
            }
        } catch (error) {
        } finally {
            setIsDownloadLoading(false);
        }
    }, [account?.id]);

    const handleDrawer = useCallback(
        (value) => {
            if (value === true) {
                setType('statements');
                handleDownload(account?.id);
            }
            setIsDrawerOpen(value);
        },
        [account?.id, handleDownload],
    );

    const handleChange = (e) => {
        let value = e.target.value;
        if (value?.trim() === '') {
            value = '';
        } else if (isNaN(value) && value !== '-') {
            setBalanceError(true);
            return;
        }
        setStatementBalance(value);
        setBalanceError(false);
    };

    const handleBeginningChange = (e) => {
        let value = e.target.value;
        if (value?.trim() === '') {
            value = '';
        } else if (isNaN(value) && value !== '-') {
            setBeginningBalanceError(true);
            return;
        }
        setStatementBeginningBalance(value);
        setBeginningBalanceError(false);
    };

    const handleUpdateStatement = useCallback(async (accountId) => {
        setType('statements2');
        const { token } = store.getState().auth;
        try {
            setIsUpdateLoading(true);
            const response = await fetchAccountStatement(accountId, token);
            if (response?.length) {
                setAccounts2(response);
            } else {
                setAccounts2([]);
            }
        } catch (error) {
        } finally {
            setIsUpdateLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!!isDrawerOpen) {
            setAccounts([]);
            setAccounts2([]);
        }
    }, [isDrawerOpen]);

    const handleContinue = () => {
        let isValid = true;
        if (!statementBalance) {
            setBalanceError(true);
            isValid = false;
        }
        if (!statementBeginningBalance && !account?.latest_reconciliation?.id) {
            setBeginningBalanceError(true);
            isValid = false;
        }
        if (!statementBeginningBalance) {
            setBeginningBalanceError(true);
            isValid = false;
        }

        if (!statementDate) {
            setDateError(true);
            isValid = false;
        }

        if (isValid) {
            dispatch(
                inputEndingBalanceRequest({
                    bankAccountId: account?.id,
                    endingBalance: statementBalance,
                    beginningBalance: statementBeginningBalance,
                    statementDate: statementDate.toISOString().split('T')[0],
                    token,
                    onSyncAll,
                    setIsInputLoading,
                    setSnackbarOpen,
                }),
            );
        }
    };

    const handleBack = useCallback(() => {
        setAccounts2([]);
        setType('statements');
    }, []);

    return (
        <>
            <Box>
                <Grid container mt={1}>
                    <Grid item xs={12} sm={4}>
                        <Typography
                            sx={{
                                color: '#6C727F',
                                fontWeight: '500',
                                fontSize: '14px',
                            }}
                        >
                            {account?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button
                            onClick={() => handleDrawer(true)}
                            sx={{
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#9EA3AE'
                                        : '#6C727F',
                                borderRadius: '8px',
                                outline: 'none',
                                textTransform: 'none',
                                borderColor: '#c9c9ca',
                            }}
                        >
                            <Typography variant="subtitle">
                                View Statements
                            </Typography>
                        </Button>
                    </Grid>
                    {/* <Button
                        onClick={() => handleDrawer(true)}
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#9EA3AE'
                                    : '#6C727F',
                            borderRadius: '8px',
                            outline: 'none',
                            textTransform: 'none',
                        }}
                    >
                        <Typography variant="subtitle">
                            View Statements
                        </Typography>
                    </Button> */}
                </Grid>
                <Typography variant="h6" fontSize={18} fontWeight={700} mt={1}>
                    Add Following Information
                </Typography>
                <Typography
                    sx={{
                        color: '#6C727F',
                        fontWeight: '500',
                        fontSize: '14px',
                    }}
                >
                    From your statement or account activity
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            md: 'row',
                        },
                        gap: 2,
                        width: '100%',
                    }}
                    mt={2}
                >
                    <Grid container spacing={2} width="100%">
                        <Grid item xs={12} md={4}>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    mb: '5px',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : '#4D5461',
                                }}
                            >
                                Beginning Balance
                            </Typography>

                            <FormControl fullWidth>
                                <TextField
                                    // disabled={
                                    //     account?.latest_reconciliation?.id
                                    // }
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={statementBeginningBalance}
                                    onChange={handleBeginningChange}
                                    error={beginningBalanceError}
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '6px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                                {beginningBalanceError && (
                                    <FormHelperText error>
                                        Please enter a valid amount
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    mb: '5px',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : '#4D5461',
                                }}
                            >
                                Ending Balance
                            </Typography>

                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={statementBalance}
                                    onChange={handleChange}
                                    error={balanceError}
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '6px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                                {balanceError && (
                                    <FormHelperText error>
                                        Please enter a valid amount
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    mb: '5px',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : '#4D5461',
                                }}
                            >
                                Statement Ending Date
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-root': {
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F4F4F6',
                                            borderRadius: '8px',
                                            border: 'none',
                                            height: '40px',
                                            minHeight: '40px',
                                            '& input': {
                                                padding: '8px 12px',
                                            },
                                        },
                                    }}
                                    size="small"
                                    value={statementDate}
                                    onChange={(newValue) => {
                                        setStatementDate(newValue);
                                        setDateError(false);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            size="small"
                                            error={dateError}
                                            helperText={
                                                dateError
                                                    ? 'Please select a date'
                                                    : ''
                                            }
                                            sx={{
                                                bgcolor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#394150'
                                                        : '#F4F4F6',
                                                borderRadius: '8px',
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? 'none'
                                                                : 'none',
                                                        borderRadius: '8px',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: 'none',
                                                    },
                                                    minHeight: '36px', // Adjust height here
                                                    height: '36px', // Ensure height consistency
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#394150'
                                                            : '#F4F4F6',
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Box>

                <Box display="flex" justifyContent="end" gap={2} mt={5}>
                    <Button
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #505C6F'
                                    : '1px solid #E5E6EB',
                            color: '#6C727F',
                            borderRadius: '8px',
                            outline: 'none',
                            textTransform: 'none',
                        }}
                        onClick={() => onSyncAll('one')}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isInputLoading}
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: '8px',
                            paddingX: '20px',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                        }}
                        onClick={handleContinue}
                    >
                        Continue
                    </LoadingButton>
                </Box>

                <Snackbar
                    open={!!snackbarOpen}
                    autoHideDuration={60000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={snackbarOpen?.type}
                        sx={{ width: '100%' }}
                    >
                        {snackbarOpen?.message || ''}
                    </Alert>
                </Snackbar>
            </Box>
            <Drawer
                isDrawerOpen={isDrawerOpen}
                handleDrawer={handleDrawer}
                accountName={account?.name}
                title="View Statements"
                isLoading={isLoading}
                type={type}
                accounts={accounts}
                onUpdate={handleUpdateStatement}
                isUpdateLoading={isUpdateLoading}
                accounts2={accounts2}
                handleBack={handleBack}
                handleDownloadStatementFiles={handleDownloadStatementFiles}
                isDownloadLoading={isDownloadLoading}
            />
        </>
    );
}

export default ContinueReconcile;
