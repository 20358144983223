import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const cellStyle = {
    width: '100px',
    textAlign: 'center',
};

function ServiceInfoTableHeader({ invoiceTolbarTax }) {
    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell
                        sx={{
                            textAlign: 'left',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#4D5461',
                            }}
                        >
                            SERVICE INFO
                        </Typography>
                    </TableCell>
                    <TableCell sx={cellStyle}>
                        <Typography
                            key="Quantity"
                            sx={{
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#6C727F',
                            }}
                        >
                            Quantity
                        </Typography>
                    </TableCell>
                    <TableCell sx={cellStyle}>
                        <Typography
                            key="Unit"
                            sx={{
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#6C727F',
                            }}
                        >
                            Unit
                        </Typography>
                    </TableCell>
                    <TableCell sx={cellStyle}>
                        <Typography
                            key="Price"
                            sx={{
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#6C727F',
                            }}
                        >
                            Price
                        </Typography>
                    </TableCell>
                    {invoiceTolbarTax?.map((tax, index) => (
                        <TableCell sx={cellStyle} key={index}>
                            <Typography
                                sx={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    color: '#6C727F',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {tax?.name}
                            </Typography>
                        </TableCell>
                    ))}
                    <TableCell sx={cellStyle}>
                        <Typography
                            key="TOTAL"
                            sx={{
                                fontWeight: '500',
                                fontSize: '14px',
                                color: '#6C727F',
                            }}
                        >
                            TOTAL
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

export default ServiceInfoTableHeader;
