import { Box, Divider, Typography } from '@mui/material';
import SetPaymentOptions from './SetPaymentOptions';

function InvoiceShow({ onViewRecurringInvoiceDetailsClick }) {
    return (
        <Box
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#212936' : 'white',
                borderRadius: '10px',
                textAlign: 'center',
            }}
        >
            <Typography
                sx={{
                    fontWeight: '800',
                    padding: '16px',
                    fontSize: '18px',
                }}
            >
                Payment
            </Typography>
            <Divider />
            <SetPaymentOptions
                onViewRecurringInvoiceDetailsClick={
                    onViewRecurringInvoiceDetailsClick
                }
            />
            <Divider
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#394150' : 'white',
                }}
            />
        </Box>
    );
}

export default InvoiceShow;
