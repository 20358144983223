/* eslint-disable import/no-anonymous-default-export */
// services/invoiceService.js
import axios from 'axios';
import { CIRCLER_API_URL } from 'utils/constants';

const BRAND_INVOICE_URL = `${CIRCLER_API_URL}/invoicing/company-invoice-settings/`;
const DELETE_BRAND_INVOICE_URL = (id) =>
    `${CIRCLER_API_URL}/invoicing/company-invoice-settings/${id}`;
const FETCH_INVOICE_SETTINGS_URL = `${CIRCLER_API_URL}/invoicing/company-invoice-settings/`;
const SERVICE_INVOICE_URL = `${CIRCLER_API_URL}/invoicing/services/`;
const CREATE_CLIENT_URL = `${CIRCLER_API_URL}/invoicing/clients/`;
const CREATE_TAX_URL = `${CIRCLER_API_URL}/invoicing/taxes/`;
const GET_CLIENTS_URL = `${CIRCLER_API_URL}/invoicing/clients/`;
const CREATE_INVOICE_URL = `${CIRCLER_API_URL}/invoicing/invoices/`;
const FETCH_SERVICES_URL = `${CIRCLER_API_URL}/invoicing/services/`;
const FETCH_INVOICES_URL = `${CIRCLER_API_URL}/invoicing/invoices/`;
const DELETE_INVOICE_URL = (id) =>
    `${CIRCLER_API_URL}/invoicing/invoices/${id}/`;
const FETCH_TAXES_URL = `${CIRCLER_API_URL}/invoicing/taxes/`;
const SEND_EMAIL_URL = `${CIRCLER_API_URL}/invoicing/send-email/`;
export const FETCH_INVOICES_DATA = `${CIRCLER_API_URL}/invoicing/invoices`;

export const brandInvoice = async (companyId, token, color, image, id) => {
    try {
        const formData = new FormData();
        if (companyId) {
            formData.append('company', companyId);
        }
        if (color) {
            formData.append('brand_color', color);
        }
        if (image) {
            formData.append('brand_logo', image);
        }

        const headers = {
            Authorization: `Bearer ${token}`,
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        };

        let response;
        if (id) {
            const patchUrl = `${BRAND_INVOICE_URL}${id}/`;
            response = await axios.patch(patchUrl, formData, { headers });
        } else {
            response = await axios.post(BRAND_INVOICE_URL, formData, {
                headers,
            });
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteBrandInvoice = async (token, id) => {
    try {
        const headers = {
            Authorization: `Bearer ${token}`,
            accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        };
        const patchUrl = DELETE_BRAND_INVOICE_URL(id);
        const response = await axios.delete(patchUrl, { headers });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchInvoiceSettings = async (token) => {
    try {
        const response = await axios.get(FETCH_INVOICE_SETTINGS_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const serviceInvoice = async (name, details, price, company, token) => {
    try {
        const response = await axios.post(
            SERVICE_INVOICE_URL,
            {
                name,
                details,
                price,
                company,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createClient = async (clientData, token) => {
    try {
        const response = await axios.post(CREATE_CLIENT_URL, clientData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchClients = async (token) => {
    try {
        const response = await axios.get(GET_CLIENTS_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createTax = async (token, taxData) => {
    try {
        const response = await axios.post(CREATE_TAX_URL, taxData, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createInvoice = async (invoiceData, token) => {
    try {
        const response = await axios.post(CREATE_INVOICE_URL, invoiceData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchServices = async (token) => {
    try {
        const response = await axios.get(FETCH_SERVICES_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateTax = async (taxId, token, taxData) => {
    try {
        const response = await axios.patch(
            `${CIRCLER_API_URL}/invoicing/taxes/${taxId}/`,
            taxData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteTax = async (taxId, token) => {
    try {
        const response = await axios.delete(
            `${CIRCLER_API_URL}/invoicing/taxes/${taxId}/`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: '*/*',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteClient = async (clientId, token) => {
    try {
        const response = await axios.delete(
            `${CIRCLER_API_URL}/invoicing/clients/${clientId}/`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: '*/*',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteService = async (serviceId, token) => {
    try {
        const response = await axios.delete(
            `${SERVICE_INVOICE_URL}${serviceId}/`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: '*/*',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateInvoice = async (payload, token, invoiceId) => {
    try {
        const response = await axios.patch(
            `${CIRCLER_API_URL}/invoicing/invoices/${invoiceId}/`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchInvoiceLogo = async (token) => {
    try {
        const response = await axios.get(FETCH_INVOICE_SETTINGS_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchInvoices = async (token) => {
    try {
        const response = await axios.get(FETCH_INVOICES_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteInvoice = async (invoiceId, token) => {
    try {
        await axios.delete(DELETE_INVOICE_URL(invoiceId), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const fetchTaxes = async (token) => {
    try {
        const response = await axios.get(FETCH_TAXES_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createStripeAccountLink = async (companyId, token) => {
    const response = await axios.post(
        `${CIRCLER_API_URL}/invoicing/stripe_account_link/create/${companyId}/`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        },
    );
    return response.data;
};

export const createPaymentIntent = async (invoiceId, token, payment_method) => {
    try {
        const response = await axios.post(
            `${CIRCLER_API_URL}/invoicing/create_payment_intent/create/${invoiceId}/${payment_method}/`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    accept: 'application/json',
                },
            },
        );
        return response.data.client_secret;
    } catch (error) {
        throw error;
    }
};

export const fetchInvoiceById = async (invoiceId) => {
    try {
        const response = await axios.get(
            `${CIRCLER_API_URL}/invoicing/invoices/${invoiceId}/`,
            {
                headers: {
                    accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendEmail = async (emailData, token) => {
    try {
        const response = await axios.post(SEND_EMAIL_URL, emailData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    brandInvoice,
    serviceInvoice,
    createClient,
    createTax,
    fetchClients,
    createInvoice,
    fetchServices,
    updateTax,
    deleteTax,
    updateInvoice,
    fetchInvoiceLogo,
    fetchInvoices,
    deleteInvoice,
    fetchTaxes,
    createStripeAccountLink,
    createPaymentIntent,
    fetchInvoiceById,
    sendEmail,
};
