// InvoiceTotals.jsx
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const InvoiceTotals = ({ isMobile }) => {
    const invoiceTotals = useSelector((state) => state.invoice.invoiceTotals);
    return (
        <Grid container>
            <Grid item xs={12} sm={isMobile ? 6 : 8} />
            <Grid item xs={12} sm={isMobile ? 6 : 4}>
                <Box
                    display="flex"
                    flexDirection="column"
                    m={4}
                    mr={0}
                    ml={0}
                    gap={2}
                >
                    <Box display="flex" justifyContent="space-between" gap={10}>
                        <Typography>Subtotal</Typography>
                        <Typography
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#F9FAFB'
                                        : '#121826',
                                fontWeight: '500',
                                fontSize: '16px',
                            }}
                        >
                            ${invoiceTotals?.subTotal || 0}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={1}>
                        {invoiceTotals?.taxArray?.map((tax, index) => (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                gap={10}
                            >
                                <Box display="flex" gap={1} alignItems="center">
                                    <Typography key={tax.id}>
                                        {tax.taxName}
                                    </Typography>
                                    {tax?.taxPercent && (
                                        <Typography
                                            key={`${tax.id}${index}`}
                                            variant="body2"
                                        >
                                            ({tax?.taxPercent}%)
                                        </Typography>
                                    )}
                                </Box>
                                <Typography
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#F9FAFB'
                                                : '#121826',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                    }}
                                >
                                    ${tax?.calculatedAmount}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Divider flexItem />
                    <Box display="flex" justifyContent="space-between" gap={10}>
                        <Typography
                            sx={{ fontWeight: '500', fontSize: '16px' }}
                        >
                            Total (USD)
                        </Typography>

                        <Typography
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#F9FAFB'
                                        : '#121826',
                                fontWeight: '800',
                                fontSize: '16px',
                            }}
                        >
                            ${invoiceTotals?.totalAmount || 0}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InvoiceTotals;
