import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import Trash from 'assets/icons/Trash.svg';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createTaxRequest,
    fetchTaxesRequest,
    setInvoiceServices,
    setSingleInvoiceTaxes,
} from 'store/actions';
import { replaceServiceTaxes, updateServicesWithTaxes } from 'utils/helpers';

const CustomPaper = (props) => {
    return (
        <Paper
            {...props}
            sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '8px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                overflow: 'hidden',
            }}
        />
    );
};

function AddTaxDetails() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const services = useSelector((state) => state.invoice.invoiceServices);
    const taxDetails = useSelector(
        (state) => state?.invoice?.singleInvoice?.taxes || [],
    );
    const allTaxes = useSelector((state) => state.invoice.allTaxes);
    const [errors, setErrors] = useState({});
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [showAddTaxButton, setShowAddTaxButton] = useState(false);
    const data = useSelector((state) => state.company.data);

    useEffect(() => {
        dispatch(fetchTaxesRequest({ token }));
    }, [dispatch, token]);

    useEffect(() => {
        if (taxDetails?.length >= 3) {
            setShowAddTaxButton(false);
        } else if (taxDetails?.[taxDetails?.length - 1]?.id) {
            setShowAddTaxButton(true);
        } else if (!taxDetails?.length) {
            setShowAddTaxButton(true);
        } else {
            setShowAddTaxButton(false);
        }
    }, [taxDetails]);

    const handleButtonClickB = useCallback(
        (index, type) => {
            const newTaxDetails = [...taxDetails];
            newTaxDetails[index] = { ...newTaxDetails[index], unit: type };
            dispatch(setSingleInvoiceTaxes(newTaxDetails));
            dispatch(
                setInvoiceServices(
                    updateServicesWithTaxes(services, newTaxDetails),
                ),
            );
        },
        [dispatch, services, taxDetails],
    );

    const validate = useCallback(() => {
        let valid = true;
        const newErrors = {};
        taxDetails.forEach((detail, index) => {
            if (!detail.name) {
                valid = false;
                newErrors[index] = {
                    ...newErrors[index],
                    name: 'Tax name is required',
                };
            }
            if (!detail.amount) {
                valid = false;
                newErrors[index] = {
                    ...newErrors[index],
                    amount: 'Rate is required',
                };
            }
            if (isNaN(detail.amount)) {
                valid = false;
                newErrors[index] = {
                    ...newErrors[index],
                    amount: 'Rate must be a number',
                };
            }
        });
        setErrors(newErrors);
        return valid;
    }, [taxDetails]);

    const handleTaxChange = useCallback(
        (index, event) => {
            let taxObject = {
                name: '',
            };
            taxObject = { name: event.target.value };
            const newTaxDetails = [...taxDetails];
            newTaxDetails[index] = {
                ...newTaxDetails[index],
                ...taxObject,
            };
            dispatch(setSingleInvoiceTaxes(newTaxDetails));
            dispatch(
                setInvoiceServices(
                    replaceServiceTaxes(services, newTaxDetails),
                ),
            );
        },
        [dispatch, services, taxDetails],
    );

    const handleTaxChangeClick = useCallback(
        (index, newValue) => {
            let taxObject = {
                id: newValue.id,
                name: newValue.name,
                amount: newValue.amount,
                unit: newValue.unit,
            };

            const newTaxDetails = [...taxDetails];
            newTaxDetails[index] = {
                ...newTaxDetails[index],
                ...taxObject,
            };
            setShowSubmitButton(false);
            dispatch(setSingleInvoiceTaxes(newTaxDetails));
            dispatch(
                setInvoiceServices(
                    updateServicesWithTaxes(services, newTaxDetails),
                ),
            );
        },
        [dispatch, services, taxDetails],
    );

    const handleAmountChange = useCallback(
        (index, event) => {
            let value = event.target.value;
            if (value?.trim() === '') {
                value = '';
            } else if (isNaN(value)) {
                return;
            } else if (value?.split('.')?.[0]?.length > 3) {
                const newErrors = {};
                newErrors[index] = {
                    ...newErrors[index],
                    amount: 'Only 3 Digits allowed before decimal point',
                };
                setErrors(newErrors);
                return;
            } else if (value?.split('.')?.[1]?.length > 2) {
                const newErrors = {};
                newErrors[index] = {
                    ...newErrors[index],
                    amount: 'Only 2 digits are allowed after decimal point',
                };
                setErrors(newErrors);
                return;
            }
            setErrors({});
            const newTaxDetails = [...taxDetails];
            newTaxDetails[index] = { ...newTaxDetails[index], amount: value };
            dispatch(setSingleInvoiceTaxes(newTaxDetails));
            dispatch(
                setInvoiceServices(
                    replaceServiceTaxes(services, newTaxDetails),
                ),
            );
        },
        [dispatch, services, taxDetails],
    );

    const handleAddTax = useCallback(() => {
        if (taxDetails?.length < 3) {
            setErrors({});
            const newTaxDetails = [
                ...taxDetails,
                { id: null, name: '', amount: '', unit: '$' },
            ];
            dispatch(setSingleInvoiceTaxes(newTaxDetails));
            setShowSubmitButton(true);
        }
    }, [dispatch, taxDetails]);

    const handleRemoveTax = useCallback(
        (index, taxId) => {
            const updatedServices = services?.map((service) => ({
                ...service,
                taxed: service?.taxed?.filter(
                    (taxedItem) => taxedItem?.id !== taxId,
                ),
            }));
            dispatch(setInvoiceServices([...updatedServices]));
            const newTaxDetails = taxDetails?.filter((_, i) => i !== index);
            dispatch(setSingleInvoiceTaxes(newTaxDetails));
            setShowSubmitButton(false);
        },
        [dispatch, services, taxDetails],
    );

    const handleSubmit = useCallback(async () => {
        if (!validate()) return;
        const tax = taxDetails?.[taxDetails?.length - 1];
        const companyId = data?.id;
        const payload = {
            name: tax?.name,
            amount: tax?.amount,
            unit: tax?.unit,
            company: companyId,
        };

        dispatch(
            createTaxRequest({
                token,
                taxData: payload,
                isInvoiceToolbar: true,
            }),
        );
        setShowSubmitButton(false);
    }, [data?.id, dispatch, taxDetails, token, validate]);

    return (
        <Accordion
            defaultExpanded
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#212936' : 'white',
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontSize: '16px', fontWeight: '500' }}
            >
                Add tax details
            </AccordionSummary>
            <AccordionDetails>
                {taxDetails.map((tax, index) => (
                    <Box key={index} mb={2}>
                        {index > 0 && <Divider sx={{ mb: 2 }} />}
                        <Grid container spacing={2}>
                            <Grid item xs={9} sm={9}>
                                <Autocomplete
                                    PaperComponent={CustomPaper}
                                    freeSolo
                                    disableClearable
                                    disabled={tax?.id}
                                    options={allTaxes?.filter(
                                        (taxInner) =>
                                            !taxDetails
                                                ?.map(
                                                    (taxOuter) => taxOuter?.id,
                                                )
                                                ?.includes(taxInner?.id),
                                    )}
                                    getOptionLabel={(option) =>
                                        option?.name || ''
                                    }
                                    renderOption={(props, option, state) =>
                                        renderOption(
                                            props,
                                            option,
                                            index,
                                            handleTaxChangeClick,
                                            {
                                                selected: state.selected,
                                                index: state.index,
                                                optionsLength: allTaxes?.length,
                                            },
                                        )
                                    }
                                    value={tax}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            size="small"
                                            disabled={tax?.id}
                                            placeholder="Tax Name" // Use placeholder instead of label
                                            variant="outlined"
                                            name="name"
                                            value={tax?.name}
                                            onChange={(event) =>
                                                handleTaxChange(index, event)
                                            }
                                            error={!!errors[index]?.name}
                                            helperText={errors[index]?.name}
                                            sx={{
                                                bgcolor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#394150'
                                                        : '#F4F4F6',
                                                borderRadius: '6px',
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? 'none'
                                                                : '1px solid #E5E6EB',
                                                        borderRadius: '8px',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <Button
                                    fullWidth
                                    sx={{
                                        border: '1px solid #FA9999',
                                        borderRadius: '8px',
                                        color: '#F75555',
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#EFEFF2'
                                                : 'transparent',
                                        textTransform: 'none',
                                        fontSize: {
                                            xs: '10px',
                                            sm: '16px',
                                        },
                                    }}
                                    onClick={() =>
                                        handleRemoveTax(index, tax?.id)
                                    }
                                >
                                    <img
                                        src={Trash}
                                        alt="Icon"
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginRight: '5px',
                                        }}
                                    />
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={7} sm={9}>
                                <TextField
                                    disabled={tax?.id}
                                    fullWidth
                                    size="small"
                                    placeholder="Tax Amount" // Use placeholder instead of label
                                    variant="outlined"
                                    name="amount"
                                    value={tax?.amount}
                                    onChange={(event) =>
                                        handleAmountChange(index, event)
                                    }
                                    error={!!errors[index]?.amount}
                                    helperText={errors[index]?.amount}
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '6px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5} sm={3}>
                                <ButtonGroup fullWidth variant="string">
                                    <Button
                                        disabled={tax?.id}
                                        onClick={() =>
                                            handleButtonClickB(index, '%')
                                        }
                                        sx={{
                                            border:
                                                tax?.unit === '%'
                                                    ? '1px solid #1976d2'
                                                    : 'none',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? (tax?.unit === '%' &&
                                                          '#0061DB26') ||
                                                      '#394150'
                                                    : (tax?.unit === '%' &&
                                                          '#0061DB26') ||
                                                      theme.backgroundColor
                                                          .secondary,
                                            color: 'text.primary',
                                            '&:hover': {
                                                backgroundColor:
                                                    tax?.unit === '%'
                                                        ? '#bbdefb'
                                                        : 'rgba(0, 0, 0, 0.04)',
                                            },
                                        }}
                                    >
                                        %
                                    </Button>
                                    <Button
                                        disabled={tax?.id}
                                        onClick={() =>
                                            handleButtonClickB(index, '$')
                                        }
                                        sx={{
                                            border:
                                                tax?.unit === '$'
                                                    ? '1px solid #1976d2'
                                                    : 'none',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? (tax?.unit === '$' &&
                                                          '#0061DB26') ||
                                                      '#394150'
                                                    : (tax?.unit === '$' &&
                                                          '#0061DB26') ||
                                                      theme.backgroundColor
                                                          .secondary,
                                            color: 'text.primary',
                                            '&:hover': {
                                                backgroundColor:
                                                    tax?.unit === '$'
                                                        ? '#bbdefb'
                                                        : 'rgba(0, 0, 0, 0.04)',
                                            },
                                        }}
                                    >
                                        $
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
                <Box display="flex" justifyContent="space-between">
                    {showAddTaxButton && (
                        <Button
                            sx={{
                                size: 'small',
                                borderRadius: '8px',
                                color: '#0061DB',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : 'transparent',
                                textTransform: 'none',
                                fontSize: {
                                    xs: '10px',
                                    sm: '16px',
                                },
                            }}
                            onClick={handleAddTax}
                        >
                            + Add Tax
                        </Button>
                    )}

                    {showSubmitButton && (
                        <Button
                            sx={{
                                backgroundColor: '#0061DB',
                                color: 'white',
                                borderRadius: '8px',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#004BB5',
                                },
                            }}
                            size="small"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default AddTaxDetails;

const renderOption = (
    props,
    option,
    outerIndex,
    handleTaxChange,
    { selected, index, optionsLength },
) => {
    return (
        <>
            <Box
                onClick={(event) => {
                    handleTaxChange(outerIndex, option);
                    props?.onClick(event);
                }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    cursor: 'pointer',
                    bgcolor: selected ? 'action.selected' : 'inherit',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    },
                }}
            >
                <Box m={2}>
                    <Typography
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            color: (theme) =>
                                theme.palette.mode === 'dark' ? 'white' : '',
                        }}
                    >
                        {option?.name}
                    </Typography>
                </Box>
                <Typography
                    m={2}
                    sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        color: (theme) =>
                            theme.palette.mode === 'dark' ? 'white' : '',
                    }}
                >
                    {option?.unit === '$' ? option?.unit : ''}
                    {option?.amount}
                    {option?.unit === '%' ? option?.unit : ''}
                </Typography>
            </Box>
            {index < optionsLength - 1 && <Divider />}
        </>
    );
};
