import {
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

function BankTransferForm({
    value,
    handleChange,
    handleBankTransferChange,
    bankTransferData,
}) {
    const { invoiceIds } = useParams();
    const isDisabled = !invoiceIds;
    return (
        <Box>
            <Box>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Account Holder Name
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="accountHolderName"
                    value={bankTransferData.accountHolderName}
                    onChange={handleBankTransferChange}
                    placeholder="Account Holder Name"
                    variant="outlined"
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    mt: 2,
                }}
            >
                <Box
                    sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '48%',
                        },
                        maxWidth: {
                            xs: '100%',
                            md: '48%',
                        },
                    }}
                >
                    <Typography
                        sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                    >
                        Account Type
                    </Typography>
                    <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={isDisabled}
                    >
                        <Select
                            labelId="custom-select-label"
                            id="custom-select"
                            name="accountType"
                            value={bankTransferData.accountType}
                            onChange={handleBankTransferChange}
                            displayEmpty
                            // label="Account Type"
                            sx={{
                                backgroundColor: '#F4F4F6',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                    {
                                        border: '1px solid #E5E6EB',
                                    },
                                '& .MuiSelect-select': {
                                    color: bankTransferData.accountType
                                        ? 'inherit'
                                        : '#9e9e9e', // Placeholder color
                                },
                            }}
                        >
                            <MenuItem value="" disabled>
                                Account Type
                            </MenuItem>
                            <MenuItem value="checking">Checking</MenuItem>
                            <MenuItem value="savings">Savings</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '48%',
                        },
                        maxWidth: {
                            xs: '100%',
                            md: '48%',
                        },
                    }}
                >
                    <Typography
                        sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                    >
                        Email
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="email"
                        value={bankTransferData.email}
                        onChange={handleBankTransferChange}
                        placeholder="Email"
                        variant="outlined"
                        disabled={isDisabled}
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'none'
                                            : 'none',
                                    borderRadius: '8px',
                                },
                                '&:hover fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Routing Number
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="routingNumber"
                    value={bankTransferData.routingNumber}
                    onChange={handleBankTransferChange}
                    placeholder="Routing Number"
                    variant="outlined"
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    mt: 2,
                }}
            >
                <Box
                    sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '48%',
                        },
                        maxWidth: {
                            xs: '100%',
                            md: '48%',
                        },
                    }}
                >
                    <Typography
                        sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                    >
                        Account Number
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="accountNumber"
                        value={bankTransferData.accountNumber}
                        onChange={handleBankTransferChange}
                        placeholder="Account Number"
                        variant="outlined"
                        disabled={isDisabled}
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'none'
                                            : 'none',
                                    borderRadius: '8px',
                                },
                                '&:hover fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '48%',
                        },
                        maxWidth: {
                            xs: '100%',
                            md: '48%',
                        },
                    }}
                >
                    <Typography
                        sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                    >
                        Confirm Account Number
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="confirmAccountNumber"
                        value={bankTransferData.confirmAccountNumber}
                        onChange={handleBankTransferChange}
                        placeholder="Confirm Account Number"
                        variant="outlined"
                        disabled={isDisabled}
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'none'
                                            : 'none',
                                    borderRadius: '8px',
                                },
                                '&:hover fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default BankTransferForm;
