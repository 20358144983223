export function fData(number) {
    if (!number) return '';

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;

    // Keep dividing the number by 1024 until it's smaller than 1024 or we run out of units
    while (number >= 1024 && unitIndex < units?.length - 1) {
        number /= 1024;
        unitIndex++;
    }

    // Format the number to one decimal place
    return `${number?.toFixed(1)} ${units?.[unitIndex]}`;
}
