import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Typography,
    Box,
    TextField,
    IconButton,
    CircularProgress,
    Avatar,
} from '@mui/material';
import DocumentsIcon from 'assets/icons/documnetsIcon.svg';

function ImportDialog({ open, onClose, handleAttachClick, loading, data }) {
    const [file, setFile] = useState(null);
    const [inputText, setInputText] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const tabsData = [{ label: 'Upload file' }, { label: 'Text input' }];
    const [selectedTab, setSelectedTab] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [filePreview, setFilePreview] = useState('');

    useEffect(() => {
        if (data) {
            if (data?.file_url === null) {
                setSelectedTab(1);
                setInputText(data.text);
            } else {
                setSelectedTab(0);
                setFilePreview(data?.file_url);
            }
        }
    }, [data]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setPreviewUrl('');
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const selectedFile = event.dataTransfer.files[0];
        setFile(selectedFile);
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setPreviewUrl('');
        }
        setIsDragging(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const changeTab = (tab, index) => {
        setSelectedTab(index);
    };

    const handleTextChange = (event) => {
        setInputText(event.target.value);
    };

    const handleAttachClicked = () => {
        if (file) {
            handleAttachClick({ file: file, type: 'file' });
            setFile(null);
            setInputText('');
            setPreviewUrl('');
        } else if (inputText) {
            handleAttachClick({ inputText, type: 'text' });
            setFile(null);
            setInputText('');
            setPreviewUrl('');
        } else {
            console.log('Please select a file or enter text.');
        }

        onClose();
    };

    const handleClose = () => {
        setFile(null);
        setInputText('');
        setPreviewUrl('');
        setFilePreview('');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="import-dialog-title"
            maxWidth="xs"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    minHeight: '300px',
                },
            }}
        >
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '300px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Box
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#121826'
                                : '#F4F4F6',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                    px={1}
                >
                    {tabsData?.map((tab, index) => (
                        <Button
                            fullWidth
                            key={index}
                            onClick={() => changeTab(tab, index)}
                            sx={{
                                textTransform: 'none',
                                fontSize: '15px',
                                fontWeight:
                                    selectedTab === index ? '600' : '500',
                                backgroundColor:
                                    selectedTab === index
                                        ? '#CEEFDF'
                                        : 'transparent',
                                borderRadius:
                                    selectedTab === index ? '3px' : 'none',
                                margin: '8px 0px',
                                color:
                                    selectedTab === index
                                        ? '#0A8C4D'
                                        : '#6C727F',
                                '&:hover': {
                                    color:
                                        selectedTab === index ? '#0A8C4D' : '',
                                },
                                variant:
                                    selectedTab === index
                                        ? 'contained'
                                        : 'text',
                            }}
                        >
                            {tab.label}
                        </Button>
                    ))}
                </Box>
                {selectedTab === 0 && (
                    <Box
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px dashed #394150'
                                    : '1px dashed #E5E6EB',
                            borderRadius: '8px',
                            p: 2,
                            mt: 2,
                            mb: 1,
                            textAlign: 'center',
                            flexGrow: 1,
                            bgcolor: isDragging ? '#e0e0e0' : 'transparent',
                        }}
                    >
                        <input
                            accept="image/jpeg,image/png,application/pdf"
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="file-upload">
                            <IconButton color="primary" component="span">
                                <img
                                    src={DocumentsIcon}
                                    alt="Icon"
                                    style={{
                                        width: 54,
                                        height: 54,
                                        marginBottom: 4,
                                    }}
                                />
                            </IconButton>
                        </label>
                        {file || filePreview ? (
                            <Box
                                mt={2}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                {previewUrl && (
                                    <Avatar
                                        src={previewUrl}
                                        alt="Preview"
                                        sx={{ width: 80, height: 80 }}
                                    />
                                )}
                                {!previewUrl && filePreview && (
                                    <Avatar
                                        src={filePreview}
                                        alt="Preview"
                                        sx={{ width: 80, height: 80 }}
                                    />
                                )}
                                <Typography variant="body2">
                                    {file?.name}
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: '#0061DB',
                                    }}
                                >
                                    Select a JPEG, PNG or PDF file to upload
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#6C727F',
                                    }}
                                >
                                    or drag and drop it here
                                </Typography>
                            </>
                        )}
                    </Box>
                )}

                {selectedTab === 1 && (
                    <Box mt={4} flexGrow={1}>
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontSize: '16px',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                                marginBottom: '7px',
                            }}
                        >
                            Import starting from
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            value={inputText}
                            onChange={handleTextChange}
                            label="Write here..."
                            variant="outlined"
                            multiline
                            rows={5}
                            sx={{
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-root': {
                                    minHeight: '150px',
                                    '& fieldset': {
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'none'
                                                : '1px solid #E5E6EB',
                                        borderRadius: '8px',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Button
                    onClick={handleClose}
                    sx={{
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        color: '#6C727F',
                        borderRadius: '8px',
                        textTransform: 'none',
                        my: 1,
                        mr: 0.5,
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleAttachClicked}
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        paddingX: '20px',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#0056b3',
                            '@media (hover: hover)': {
                                backgroundColor: '#0056b3',
                            },
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#436ecc',
                            color: 'white',
                        },
                    }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Attach'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ImportDialog;
