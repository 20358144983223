import DescriptionIcon from '@mui/icons-material/Description';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintIcon from '@mui/icons-material/Print';
import {
    Box,
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

const AppBarComponent = ({
    changeStep,
    setSelectedTabPreview,
    invoiceStep,
    pdfRef,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const singleInvoice = useSelector((state) => state?.invoice?.singleInvoice);

    const handleMenuClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const click = useCallback(() => {
        changeStep('PAYMENT');
    }, [changeStep]);

    const formatDate = useCallback((dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }, []);

    const dueDate = formatDate(new Date());

    const handleSaveAsPDF = useCallback(async () => {
        const input = pdfRef.current;
        const canvas = await html2canvas(input, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height * 1.5;
        const ratio = imgWidth / pdfWidth;
        const totalPdfHeight = imgHeight / ratio;

        let position = 0;

        if (totalPdfHeight <= pdfHeight) {
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, totalPdfHeight);
        } else {
            while (position < totalPdfHeight) {
                pdf.addImage(
                    imgData,
                    'PNG',
                    0,
                    -position,
                    pdfWidth,
                    totalPdfHeight,
                );
                position += pdfHeight;
                if (position < totalPdfHeight) {
                    pdf.addPage();
                }
            }
        }

        pdf.save('invoice.pdf');
    }, [pdfRef]);

    const handlePrint = () => {
        window.print();
    };

    const [selectedTab, setSelectedTab] = useState(0);
    const changeTab = (tab, index) => {
        setSelectedTabPreview(index);
        setSelectedTab(index);
    };
    const tabsData = [{ label: 'Web preview' }, { label: 'Mobile preview' }];

    return (
        <Box
            position="fixed"
            color="default"
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#212936' : 'white',
                flexDirection: { xs: 'column', sm: 'row' },
                boxShadow: 'none',
                borderBottom: '1px solid #e0e0e0',
                zIndex: 1000,
            }}
        >
            <Box
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#121826' : '#F4F4F6',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                gap={2}
                px={1}
            >
                {invoiceStep === 'INVOICE' ? (
                    <Typography p={1} fontWeight="bold">
                        Invoice
                    </Typography>
                ) : (
                    <Typography p={1} fontWeight="bold">
                        Invoice Payment
                    </Typography>
                )}
            </Box>

            <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ marginTop: { xs: 3, sm: 0 } }}
            >
                {invoiceStep === 'INVOICE' && (
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                        sx={{ marginLeft: 1 }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                )}
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '12px',
                            padding: '8px',
                            boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
                        },
                    }}
                >
                    <MenuItem onClick={handlePrint}>
                        <ListItemIcon>
                            <PrintIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Print page" />
                    </MenuItem>
                    {/* <MenuItem onClick={handleSaveAsPDF}>
                        <ListItemIcon>
                            <SaveAltIcon fontSize="small" />
                        </ListItemIcon>
                         <ListItemText primary="Save page as PDF" /> 
                    </MenuItem> */}
                </Menu>

                <Box
                    sx={{
                        border: '1px solid #E5E6EB',
                        borderRadius: '1000px',
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#6C727F',
                    }}
                >
                    <DescriptionIcon />
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        Invoice
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: '400',
                            fontSize: '14px',
                            color: '#6C727F',
                        }}
                    >
                        Due : {dueDate}
                    </Typography>
                </Box>
                {invoiceStep === 'INVOICE' &&
                    !['payment_processing', 'paid'].includes(
                        singleInvoice?.status,
                    ) && (
                        <Button
                            sx={{
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                color: '#6C727F',
                                borderRadius: '8px',
                                marginLeft: '10px',
                                textTransform: 'none',
                            }}
                            onClick={click}
                        >
                            Next
                        </Button>
                    )}
            </Box>
        </Box>
    );
};

export default AppBarComponent;
